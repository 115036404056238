import React, { useContext, useState, useEffect } from "react";
import axios from "../../utils/axios";
import { Context } from "../../utils/store";
import { useCookies } from "react-cookie";
import Table from "../../components/Table";
import moment from "moment";
import Paginator from "../../components/Paginator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TransactionHistory(props: any) {
  const [cookies] = useCookies();
  const [transactions, setTransactions] = React.useState<Array<any> | null>(
    null
  );
  const { state, dispatch } = useContext(Context);
  const [isTableShown, setIsTableShown] = useState(false);

  const DateIndex = 0;
  const TransactionIndex = 1;
  const AmountIndex = 2;
  const ActionIndex = 3;

  const initialTableData = [
    {
      title: "Date",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Transaction ID",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Amount",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
  ];

  const [tableData, setTableData] = React.useState(initialTableData);
  const [page, setPage] = useState(1);
  const limit = 25;
  const debounceTimeout = 1000;
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handlePaginationButtonClick = (direction, prevPage, nextPage) => {
    setPage(nextPage);
  };
  // let sortedDates = tableData
  // console.log(sortedDates)
  // React.useEffect(() => {
  //   // копируешь tableData.data в новую переменную (только наверное date, а не  data, но не суть)
  //   sortedDates[0].data?.map((oneDate)=>{
  //       // конвертируем дату в числа
  //       return new Date(oneDate).getTime()
  //   }).sort((a,b)=>{
  //      // сортируем эти числа
  //      return b-a;
  //   }).map((twoDate)=>{
  //      // и тут нужно кнвертировать все обратно в дату
  //      sortedDates[0].data?.push(moment(twoDate).format("MM/DD/YYYY"))
  //   })
  // }, [tableData])

  const getAllTransactions = async (payload: any) => {
    await axios({
      method: "post",
      url: "/transactions",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: {
        page,
        limit,
        startdate,
        enddate,
        ...payload,
      },
    }).then((response) => {
      const {
        transactions: { docs, totalRecords, totalPages },
      } = response.data;

      let newMonthsData = [...docs];

      newMonthsData.sort((a, b) => {
        var date = new Date(a.transactionDate);
        var date2 = new Date(b.transactionDate);
        return date2 - date;
      });
      // console.log(newMonthsData)
      setTransactions(newMonthsData);
      setTotalRecords(totalRecords);
      setTotalPages(totalPages);
      // if(response.data.transactions.length > 0) setTableDataHandler();
      dispatch({
        type: "transactionHistory",
        transactionHistory: newMonthsData,
      });
    });
  };

  const handlePaginationDebounce = (page) => {
    getAllTransactions({ page });
  };

  React.useEffect(() => {
    if (!state?.transactionHistory) {
      getAllTransactions();
    } else {
      setTransactions(state?.transactionHistory);
      // getAllTransactions()
    }
  }, [state]);

  React.useEffect(() => {
    getAllTransactions();
  }, [0]);

  const handleDateRange = (dates:any) => {
    const newDates = [];
    dates.map((item:any) => newDates.push(item !== null ? moment.parseZone(item).format() : null));
    const [one , two] = newDates;
    // setStartdate(startDate);
    // setEnddate(endDate);
    getAllTransactions({ startdate : one , enddate : two, page: 1 });
    setPage(1);
  };

  useEffect(() => {
    setTableDataHandler();
  }, [transactions]);

  const setTableDataHandler = () => {
    let dates = [];
    let transactionId = [];
    let amount = [];

    if (transactions) {
      transactions.forEach((oneCharitie) => {
        transactionId.push(oneCharitie.stripeTransactionId);
        // let unix_timestamp = oneCharitie.transactionDate;
        // var date = new Date(unix_timestamp);
        // const utcDate1 = new Date(Date.UTC(unix_timestamp * 1000))
        // console.log(date.toUTCString())
        // date.setUTCHours(+5);
        // console.log(date.getTime().sort((a, b) => (b.price - a.price))
        dates.push(moment(oneCharitie.transactionDate).format("MM/DD/YYYY"));

        if (oneCharitie.donationData.coverTransactionFee == true) {
          amount.push(
            "USD " +
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              })
                .formatToParts(
                  (oneCharitie.transactionAmount +
                    (oneCharitie.transactionAmount / 100) * 8) /
                    1
                )
                .map((p) =>
                  p.type != "literal" && p.type != "currency" ? p.value : ""
                )
                .join("")
          );
        } else {
          amount.push(
            "USD " +
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              })
                .formatToParts(oneCharitie.transactionAmount / 1)
                .map((p) =>
                  p.type != "literal" && p.type != "currency" ? p.value : ""
                )
                .join("")
          );
        }
      });
      let newTableData = [...tableData];
      newTableData[DateIndex].data = dates;
      newTableData[TransactionIndex].data = transactionId;
      newTableData[AmountIndex].data = amount;
      newTableData[ActionIndex].action = viewHandler;
      setTableData(newTableData);
      setIsTableShown(true);
    }
  };
  const viewHandler = (index: number) => {
    let unix_timestamp = transactions[index].transactionDate;
    var date = new Date(unix_timestamp);
    date.setUTCHours(+5);
    // console.log(date)
    dispatch({
      type: "params",
      params: {
        ...state.params,
        id: transactions[index].stripeTransactionId,
        date: moment(date).format("MM/DD/YYYY"),
      },
    });
    props.action();
  };

  return (
    <>
      <div className="flex mt-4 md:mt-8 mb-6 tran-hist-wrapp">
        <h3 className="flex-1 inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mb-4 z-50 font-Montserrat">
          Transaction History
        </h3>
        <div className="flex-1 text-right">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
              handleDateRange(update);
            }}
            className={`h-12 border-2 p-5 z-100`}
            isClearable={true}
            placeholderText="Select Start and End Date"
          />
        </div>
      </div>
      <div
        style={{ borderWidth: 1, backgroundColor: "#fff" }}
        className={`rounded-3xl`}
      >
        {/* <div>
          <div
            style={{ maxWidth: 250 }}
            className="mt-1 relative rounded-3xl  flex-grow border-none  mr-auto ml-10"
          >
            <input
              style={{ backgroundColor: "#fff" }}
              className={` focus:ring-gray-200 mt-2 mb-8 w-full focus:outline-none focus:ring-2 placeholder-grey2   focus:outline-none  block border-none  pl-5 pr-5 sm:text-sm text-xs 2xl:text-base  rounded-xl  h-12 z-20 relative  `}
              placeholder="Search Transaction ID"
            />
          </div>
        </div> */}

        {isTableShown ? (
          <Table tableData={tableData} rows={transactions?.length} />
        ) : (
          <p className="p-4 text-sm">No donation has been processed yet</p>
        )}
        <Paginator
          page={page}
          limit={limit}
          totalRecords={totalRecords}
          totalPages={totalPages}
          handleClick={handlePaginationButtonClick}
          debounceCallback={handlePaginationDebounce}
          timeout={debounceTimeout}
        />
        {/* <div className="table w-full  ">
          <div className="rounded-3xl  table-row-group ">
            <div className="table-row font-PoppinsSemiBold px-12    ">
              
              <p className="table-cell sm:text-sm text-xs 2xl:text-base text-center">
                Date
              </p>
              <p className="table-cell sm:text-sm pt-4 pb-2 text-xs 2xl:text-base text-center">
                Transaction ID
              </p>
              <p className="table-cell sm:text-sm text-xs 2xl:text-base text-center">
                Amount
              </p>

              <p className="table-cell sm:text-sm text-xs 2xl:text-base text-center">
                Action
              </p>
            </div>
            {transactions &&
              transactions?.map((el: any, index: any) => {
                let unix_timestamp = el.created
                var date = new Date(unix_timestamp * 1000);
                return (
                  <Transaction
                    amount={el.amount}
                    id={el.id}
                    date={moment(date).format("MM/DD/YYYY")}
                    name={el.metadata.name}
                    action={() => {
                      dispatch({
                        type: "params",
                        params: { ...state.params, id: el.id, date: moment(date).format("MM/DD/YYYY") },
                      });
                      props.action();
                    }}
                    key={index}
                  />
                );
              })}
          </div>
        </div> */}
      </div>
    </>
  );
}

const Transaction = (props: any) => {
  return (
    <>
      <div className="table-row justify-between   font-PoppinsMedium     ">
        {/* <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center py-4">
          {props.name}
        </p> */}
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          {props.date}
        </p>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          {props.id}
        </p>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          {props.amount / 100}
        </p>

        <p className="text-center text-black">
          <button
            onClick={props.action}
            style={{ backgroundColor: "#f5ecd7" }}
            className="table-cell my-2 sm:text-sm text-xs 2xl:text-base py-1 px-6 rounded-3xl hover:opacity-80 relative z-20 focus:outline-none  "
          >
            View
          </button>
        </p>
      </div>
      {/* <div className="mb-2"></div> */}
    </>
  );
};
