import React, { useState, useEffect, useReducer, useContext } from "react";
import icon from "../../assets/icons/support.png";
import { reducer, initialState, Context } from "../../utils/store";
import axios from "../../utils/axios";
import { useCookies } from "react-cookie";
import moment from "moment";
import axiosUtility from "../../utils/axios";
export default function TransactionsDetails(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const { state, dispatch } = useContext(Context);
  const [transaction, setTransaction] = useState<any>(null);
  const [charityName, setCharityName] = useState<any>([]);
  const [schedulesList, setSchedulesList] = useState(null);
  const [donationsDetailsShown, setDonationsDetailsShown] = useState(false);
  const transactionFees = state.firstTimeInfo.coverFee;
  const getSchedulesList = async () => {
    let islamicMonths;

    await axios
      .get("/islamicmonths")
      .then((response) => {
        islamicMonths = response.data.response;
      })
      .catch((err) => console.log(err));

    await axios({
      method: "get",
      url: "/userdonationschedule",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        let donationSchedules = [...response.data.response];

        donationSchedules.forEach((oneSchedule, oneScheduleIndex) => {
          oneSchedule.islamicDays.forEach(
            (oneIslamicDay, oneIslamicDayIndex) => {
              let monthIndex = islamicMonths.findIndex((oneMonth) => {
                if (oneMonth._id == oneIslamicDay.islamicMonthId) {
                  return true;
                }
              });
              donationSchedules[oneScheduleIndex].islamicDays[
                oneIslamicDayIndex
              ].monthName = islamicMonths[monthIndex]?.name;
            }
          );
        });

        dispatch({
          type: "donationSchedules",
          donationSchedules: donationSchedules,
        });
        setSchedulesList(donationSchedules);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSchedulesList();
  }, [donationsDetailsShown]);

  const [number, setNumber] = React.useState();

  React.useEffect(() => {
    schedulesList?.map((oneSchedule, index) => {
      if (transaction?.donationData?._id == oneSchedule._id) {
        console.log(oneSchedule, index);
        setNumber(index + 1);
      }
    });
  }, [schedulesList, transaction]);

  console.log(schedulesList);
  // console.log(transaction.donationData._id)
  React.useEffect(() => {
    const getSingleTransaction = async () => {
      const response = await axios({
        method: "get",
        url: `/transactions/${state?.params?.id}`,
        headers: {
          Authorization: `Bearer ${cookies.tokenId}`,
        },
      }).then((response) => {
        // console.log(response);
        setTransaction(response.data.transactionDetails[0]);
      });
    };
    getSingleTransaction();
    getSettings();
  }, [0]);
  console.log(transaction);

  const [charityArray, setCharityArray] = React.useState([]);

  React.useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      setCharityArray(response.data.charities);
    });
  }, [0]);

  const getSettings = () => {
    axios
      .get("/config-settings")
      .then((response) => {
        dispatch({
          type: "firstTimeInfo",
          firstTimeInfo: {
            ...state.firstTimeInfo,
            coverFee: response.data.response.TRANSACTION_FEE,
            minDonation: response.data.response.MIN_DONATION_AMOUNT,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let transDetails = null;

  transDetails = charityArray.map((charity, index) => {
    return (
      <div className=" grid gap-y-4">
        <p
          key={index}
          className="flex  text-sm 2xl:text-base -mb-3  font-PoppinsMedium text-black table-cell "
        >
          {transaction?.donationData?.charityData[index]?.contributions.length >
          0 ? (
            <>
              <span key={index}>{charity.name}</span>
            </>
          ) : null}
        </p>
        <p className="flex-auto font-PoppinsMedium self-end text-left mb-auto text-sm 2xl:text-base text-black mr-2 w-full flex">
          <span className="text-brownGrey -mt-6 2xl:-mt-7 ml-40 w-full gap-y-2  grid ">
            {transaction?.donationData?.charityData.map((cause, index) => {
              if (cause.charityId == charity._id) {
                return cause.contributions.map((oneCause, index) => {
                  return (
                    <>
                      <div key={index} className="">
                        <span>{oneCause.name}</span>
                        <span className="float-right  ml-auto text-sm 2xl:text-base text-black  font-Poppins ">
                          {/* ${oneCause.amount} */}
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(oneCause.amount)}
                        </span>
                      </div>
                      <br />
                    </>
                  );
                });
              }
            })}
          </span>
        </p>
      </div>
    );
  });
  let mobileDetails = null;

  mobileDetails = charityArray.map((charity, index) => {
    return (
      <>
        <p
          key={index}
          className="flex  mt-5 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black  "
        >
          {transaction?.donationData?.charityData[index]?.contributions.length >
          0 ? (
            <>
              <span key={index}>Charity: {charity.name}</span>
            </>
          ) : null}
        </p>
        <p className=" font-PoppinsMedium  mb-auto text-sm 2xl:text-base text-black mr-2 w-full ">
          <span className="text-brownGrey  gap-y-2  ">
            {transaction?.donationData?.charityData.map((cause, index) => {
              if (cause.charityId == charity._id) {
                return cause.contributions.map((oneCause, index) => {
                  return (
                    <>
                      <div key={index} className="">
                        <span>{oneCause.name}</span>
                        <span className="float-right  ml-auto text-sm 2xl:text-base text-black  font-Poppins ">
                          ${oneCause.amount}
                        </span>
                      </div>
                      <br />
                    </>
                  );
                });
              }
            })}
          </span>
        </p>
      </>
    );
  });
  useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      let newCharities: any[] = [];
      transaction?.donationData?.charityData.forEach((donationCharity: any) => {
        response.data.charities.forEach((oneCharity: any) => {
          if (oneCharity._id === donationCharity.charityId) {
            newCharities.push(oneCharity.name);
            setCharityName(newCharities);
          }
        });
      });

      // console.log(oneCharity)
    });
  }, [transaction]);

  console.log("names", charityName);
  // let donationBlocks = null;
  // donationBlocks = transaction?.donationData?.contributions?.map(
  //   (cause, index) => {
  //     return (
  //       <div className="flex flex-row">
  //         <p className=" 2xl:text-base  text-sm table-cell text-left px-6 py-1.5"></p>
  //         <p
  //           className=" 2xl:text-base text-sm table-cell text-left font-Poppins px-6  py-1"
  //           key={index}
  //         >
  //           {cause.name}
  //         </p>
  //         <p className=" text-sm 2xl:text-base text-black ml-auto  py-1 font-Poppins ">
  //           ${cause.amount}
  //         </p>
  //       </div>
  //     );
  //   }
  // );
  return (
    <>
      {transaction ? (
        <div
          style={{ backgroundColor: "#f6f6f6" }}
          className={`py-6 pb-8 rounded-3xl sm:px-8 w-full px-2 ${props.style}`}
        >
          <div className="grid 2xl:grid-cols-2  gap-y-5 gap-x-10">
            <div>
              <p className="text-camel mt-auto 2xl:text-base mb-1 text-sm font-semibold">
                Donation Schedule #
                <span className="text-camel">
                  {/*number*/}
                  {transaction?.donationData?.sequenceNumber}
                </span>
              </p>
              <p className="text-black mt-auto 2xl:text-base  text-sm font-PoppinsMedium">
                Date Charge
                <span className="text-brownGrey pl-2">
                {moment(transaction?.donationData.donationDate).format("MM/DD/YYYY")}
                </span>
              </p>

              <div className="flex flex-row justify-between pr-3 mb-5 mt-1 md:pr-0">
                <p className="text-black mt-auto 2xl:text-base text-sm font-PoppinsMedium">
                  Frequency
                  <span className="text-brownGrey pl-2">
                    {transaction?.donationData.frequency == "oneTime"
                      ? "One Time"
                      : "Reccuring"}
                  </span>
                </p>
              </div>
              <p className="text-camel font-PoppinsSemiBold 2xl:text-xl md:text-lg text-base my-2">
                #{transaction?.stripeTransactionId}
              </p>
              <div className="flex flex-row justify-between pr-3 md:pr-0 mb-1">
                <p className="text-darkGreen font-PoppinsSemiBold 2xl:text-xl md:text-lg text-base ">
                  Payment Successful
                </p>
                {/* <p className="text-black mt-auto 2xl:text-base text-sm font-PoppinsMedium">
                Frequency: <span className="text-brownGrey">One Time</span>
              </p> */}
              </div>

              {/* <p className="text-sm 2xl:text-base text-black">
              {state?.params.date}
            </p> */}

              <div className=" grid md:grid-cols-2 grid-cols-1 md:gap-x-5 justify-between mt-7">
                {/* <div className="pr-2 sm:pr-0">
                <p className="2xl:text-xl md:text-lg text-base text-black font-PoppinsSemiBold md:mb-5  ">
                  Islamic Days
                </p>
                <div
                  style={{ boxShadow: " 0px 3px 9px #00000012" }}
                  className="bg-white rounded-xl  p-4 px-3 my-2"
                >
                  <p className="text-camel 2xl:text-sm text-xs">Rajab</p>
                  <p className="text-black 2xl:text-base text-sm">
                    27th Laylat Al Isra’ Wal Mi’raj
                  </p>
                </div>
                <div
                  style={{ boxShadow: " 0px 3px 9px #00000012" }}
                  className="bg-white rounded-xl  p-4 px-3 my-2"
                >
                  <p className="text-camel 2xl:text-sm text-xs">Muharram</p>
                  <p className="text-black 2xl:text-base text-sm">
                    Days Of Ashura
                  </p>
                </div>
              </div> */}
                <div className="sm:pr-2 pr-0">
                  <p className="2xl:text-xl md:text-lg text-base text-black font-PoppinsSemiBold md:mb-5 mt-4 md:mt-0 ">
                    {transaction?.donationData.type &&
                    transaction?.donationData.type === "personal"
                      ? "Personal Days"
                      : transaction?.donationData.type === "islamic"
                      ? "Islamic Days"
                      : "Sunnah Days"}
                  </p>
                  <div
                    style={{
                      boxShadow: " 0px 3px 9px #00000012",
                    }}
                    className="bg-white rounded-xl w-full  pt-3 pb-4  px-3 my-2"
                  >
                    <p className="text-black 2xl:text-base text-sm">
                      {transaction?.donationData.targetObj?.manualEntryText
                        ? transaction?.donationData.name +
                          " " +
                          transaction?.donationData.targetObj?.manualEntryText
                        : transaction?.donationData.name}
                    </p>
                    <p className="text-brownGrey 2xl:text-base text-sm">
                      Date
                      <span className="pl-2">
                        {moment(transaction?.donationData.donationDate).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </p>
                  </div>
                  {/* <div
                  style={{
                    paddingBottom: 16,
                    boxShadow: " 0px 3px 9px #00000012",
                  }}
                  className="bg-white rounded-xl py-3  px-3 my-2"
                >
                  <p className="text-black 2xl:text-base text-sm">
                    Your Child’s Birthday
                  </p>
                  <p className="text-brownGrey 2xl:text-base text-sm">
                    Date: 20-11-2020
                  </p>
                </div> */}
                </div>
              </div>

              <div className="  md:hidden 2xl:w-28rem mb-5 w-full justify-self-center xl:justify-items-auto bg-white rounded-xl shadow-round px-4 md:px-10 py-5 col-span-8 xl:col-span-4">
                {/* <div className="flex justify-between text-black ">
                <p className="font-PoppinsMedium text-black 2xl:text-base text-sm px-6 text-left pb-2">
                  Charity
                    </p>
                <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-left">
                  Cause
                    </p>
                <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-right">
                  Amount
                    </p>
              </div>
              <div className='border-b-2 mb-4 opacity-75'>
              </div> */}

                <span className="flex-auto font-PoppinsMedium self-end text-left mb-auto text-sm 2xl:text-base text-black">
                  {mobileDetails}
                </span>

                <p className="flex mt-3 text-sm 2xl:text-base my-2 font-PoppinsMedium text-black ">
                  Total Donation
                  <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
                    {/* ${transaction?.donationData?.totalDonationAmt} */}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(transaction?.donationData?.totalDonationAmt)}
                  </span>
                </p>
                {transaction?.donationData?.coverTransactionFee && (
                  <p className=" text-brownGrey 2xl:text-base text-sm flex w-full text-left ">
                    Transaction Fees
                    <span className=" 2xl:text-base text-sm text-black ml-auto  text-right ">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        transaction?.donationData?.totalDonationAmt *
                          (transaction?.donationData?.coverTransactionFee
                            ? transactionFees
                            : 0)
                      )}
                    </span>
                  </p>
                )}
                <div className="border-b-2 mt-4 opacity-75"></div>
                <p className="flex mt-3  text-sm 2xl:text-base mt-6 text-left font-PoppinsMedium text-black">
                  Total Charge
                  <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      (
                        transaction?.donationData?.totalDonationAmt +
                        transaction?.donationData?.totalDonationAmt *
                          (transaction?.donationData?.coverTransactionFee
                            ? transactionFees
                            : 0)
                      ).toFixed(2)
                    )}
                  </span>
                </p>
              </div>

              <div className="hidden md:flex bg-white rounded-3xl mt-6 pt-8 pb-6">
                <div className="table w-full ">
                  <div className="flex justify-between text-black ">
                    <p className="font-PoppinsMedium text-black 2xl:text-base text-sm px-6 text-left pb-2">
                      Charity
                    </p>
                    <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-left">
                      Cause
                    </p>
                    <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-right">
                      Amount
                    </p>
                  </div>
                  <div className="border-b-2 mb-4 opacity-75"></div>

                  <p className=" text-black 2xl:text-base text-sm   px-6 pt-4 ">
                    {transDetails}
                  </p>

                  {/* <div className="table w-full ">
                    <div className=" table-row-group  ">
                      
                    </div>
                    
                  </div> */}

                  <div className="flex flex-row justify-between ">
                    <p className=" text-black 2xl:text-base text-sm table-cell text-left px-6 pt-1">
                      Total Donation
                    </p>
                    <p></p>
                    <p className=" 2xl:text-base text-sm text-black table-cell text-right px-6">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(transaction?.donationData?.totalDonationAmt)}
                    </p>
                  </div>
                  {transaction?.donationData?.coverTransactionFee && (
                    <div className="flex flex-row justify-between shadow-sm">
                      <p className=" text-brownGrey 2xl:text-base text-sm table-cell text-left px-6 pb-5 pt-2">
                        Transaction Fees
                      </p>
                      <p></p>
                      <p className=" 2xl:text-base text-sm text-black table-cell text-right px-6">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          transaction?.donationData?.totalDonationAmt *
                            (transaction?.donationData?.coverTransactionFee
                              ? transactionFees
                              : 0)
                        )}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row justify-between">
                    <p className=" text-black 2xl:text-base text-sm table-cell text-left px-6 pt-3">
                      Total Charge
                    </p>
                    <p></p>
                    <p className=" 2xl:text-base text-sm text-black table-cell text-right mt-auto px-6">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        (
                          transaction?.donationData?.totalDonationAmt +
                          transaction?.donationData?.totalDonationAmt *
                            (transaction?.donationData?.coverTransactionFee
                              ? transactionFees
                              : 0)
                        ).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row ml-6 pb-8 mt-16"></div>
              </div>
            </div>

            <div
              style={{ maxHeight: 220 }}
              className="bg-white rounded-3xl py-6 px-7"
            >
              <p className="font-PoppinsSemiBold 2xl:text-xl md:text-lg text-base text-black">
                Need Help?
              </p>
              <p className="text-sm 2xl:text-base mt-2">We’re here for you.</p>
              <div className={`relative mt-10`}>
                <div className="mt-1 relative rounded-md   flex-grow  mr-auto">
                  <a
                    style={{ backgroundColor: "#F6F6F6", maxWidth: 300 }}
                    href="mailto:salaam@charitymoments.com"
                    className={`py-5 focus:ring-gray-200 mt-2 mb-7 shadow-sm  focus:ring-2 placeholder-brownGrey focus:border-gray-200   block w-full pl-14 pr-12 sm:text-sm text-xs border-gray-300  rounded-md  z-20 relative  `}
                  >
                    <p className="text-brownGrey sm:text-sm text-xs">
                      Contact Support
                    </p>
                  </a>
                  <div className="absolute inset-y-0 left-5 z-30 flex items-center">
                    <img src={icon} className="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-camel text-sm py-6 px-2">
          Loading transaction details...
        </p>
      )}
    </>
  );
}
