import React from "react";
import FloatingLabelInput from "react-floating-label-input";

export default function Input(props: any) {
  return (
    <div className="mt-1 mb-4 relative rounded-md grid grid-cols-3 2xl:grid-cols-4 mr-auto">
      <p className="md:pr-5 text-xs md:text-base text-black 2xl:col-span-1 mt-3">
        {props.title}
      </p>
      <div className="col-span-2 xl:col-span-3">
        <input
          style={{ backgroundColor: "#fafafa" }}
          type="text"
          name="price"
          id="price"
          className="focus:ring-camel focus:outline-none focus:ring focus:border-camel focus:outline-none block w-full pl-7 pr-12 text-xs sm:text-sm border-camel rounded-md h-12"
          placeholder={props.placeholder}
          {...props}
        />
        <span className="text-errorColor text-sm">
          {props.error ? props.error : null}
        </span>
      </div>
    </div>
  );
}
export function InputDefault(props: any) {
  return (
    <div style={props.style2} className={`relative ${props.class}`}>
      <div className="mt-1 relative rounded-md  flex-grow  mr-auto">
        <label className="2xl:text-base text-sm ">{props.title}</label>

        <input
          style={{ backgroundColor: "#F1F1F1" }}
          value={props.value}
          type={props.type}
          onChange={props.onChange}
          className={` focus:ring-gray-200 mt-2 mb-7 shadow-sm focus:outline-none focus:ring-2 placeholder-grey2 focus:border-gray-200  focus:outline-none  block w-full pl-7 pr-12 sm:text-sm text-xs border-gray-300  rounded-md h-12 z-20 relative ${props.input} `}
          placeholder={props.placeholder}
        />

        {props.icon ? (
          <div className="absolute inset-y-0 right-0 z-30 flex items-center">
            <props.icon className={props.style} onClick={props.onClick} />

            {/* <label htmlFor="currency" className="sr-only">
          Currency
        </label> */}
          </div>
        ) : null}
      </div>
      <p className="text-errorColor text-sm -mt-5 mb-4">
        {props.error ? props.error : null}
      </p>
    </div>
  );
}

export function InputForm(props: any) {
  return (
    <div className="w-full mb-6">
      <div className="bg-lightGrey2 p-2 2xl:p-3 border text-sm 2xl:text-base rounded-lg">
        <FloatingLabelInput
          value={props.value}
          label={props.title}
          id={props.id}
          type={props.type}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          className="bg-lightGrey2"
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
      </div>
      <div>
        <span className="text-errorColor text-sm">
          {props.error ? props.error : null}
        </span>
      </div>
    </div>
  );
}
export function InputProfileEdit(props: any) {
  return (
    <div className="w-full mb-4 px-3 ">
      <div className="bg-lightGrey2  px-2 2xl:px-3 py-2.5 border 2xl:text-sm text-xs rounded-lg">
        <FloatingLabelInput
          value={props.value}
          label={props.title}
          id={props.id}
          type={props.type}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          className="bg-lightGrey2"
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
      </div>
      <div>
        <span className="text-errorColor 2xl:text-sm text-xs">
          {props.error ? props.error : null}
        </span>
      </div>
    </div>
  );
}
