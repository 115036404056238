import React, {useState} from 'react'
import CheckBox from './CheckBox';

export default function Table(props:any) {

{ /*
	Example of use:

		let tableData = [
			{contentType: 'checkbox', isChecked: [true, false, true, false], action: checkboxHandler},
			{title: 'User Name', contentType: 'text', data: ["Yasuf Ali", "Yasuf Ali2", "Yasuf Ali3", "Yasuf Ali4"]},
			{title: 'Email', contentType: 'text', data: ["Yasufali@gmail.com", "Yasufali2@gmail.com", "Yasufali3@gmail.com", "Yasufali4@gmail.com"]},
			{title: 'Password', contentType: 'button', buttonName: 'Reset', buttonType: 'default', action: test},
			{title: 'Proxy', contentType: 'button', buttonName: 'View', buttonType: 'default', action: test},
			{title: 'Status', contentType: 'button', buttonType: 'status', status: [true, false, false, true], action: test},
		]

		<Table tableData={tableData} rows={4}/> 
*/ }

	const tableData = props.tableData

	let columnTitles = tableData.map((obj:any, index: any)=>{
		if(obj.title){
			return (
				<tr className="text-center p-3 font-bold text-gray-400  hidden font-Poppins lg:table-cell" key={index}>
					{obj.title}
				</tr>
			)
		}
		else{
			return( <tr className="text-center p-3  font-bold text-gray-400 hidden lg:table-cell" key={index}></tr> )
		}
	})

	let tableRow = [];
	for(let i = 0; i<props.rows; i++){
		let td = [];
		for(let j = 0; j<tableData.length; j++){
			if(tableData[j].contentType=="text"){
				td.push(
					<td className="w-full font-bold lg:w-auto p-3 sm:text-sm text-xs 2xl:text-base text-gray-800 text-center block lg:table-cell font-Poppins relative lg:static">
						{tableData[j].data[i]}
			        </td>
			    )
			}
			else if(tableData[j].contentType=="button"){
				if(tableData[j].buttonType=="default"){
					td.push(
						<td className="w-full lg:w-auto p-3 text-center block lg:table-cell relative lg:static">
							<button style={{ backgroundColor: "#f5ecd7" }} onClick={()=>tableData[j].action(i)} className=" sm:text-sm text-xs 2xl:text-base py-1 px-6 rounded-3xl hover:opacity-80 relative focus:outline-none ">
								{tableData[j].buttonName}
							</button>
				        </td>
				    )
				}
				else if(tableData[j].buttonType=="status"){
					if(tableData[j].status[i]==undefined){
						td.push(
							<td className="w-full lg:w-auto p-3 text-center block lg:table-cell relative lg:static">
					        </td>
					    )
					}
					else{
						td.push(
							<td className="w-full lg:w-auto p-3 text-center block lg:table-cell relative lg:static">
								<button onClick={()=>tableData[j].action(i)} className={tableData[j].status[i] ? "py-1 w-24 hover:opacity-80 font-Poppins focus:outline-none bg-green-100 text-green-600 rounded-2xl" : "py-1 w-24 hover:opacity-80 font-Poppins focus:outline-none bg-lightRed text-red rounded-2xl"}> 
									{tableData[j].status[i] ? "Active" : "Inactive"}
								</button>
					        </td>
					    )
					}
				}
			}
			else if(tableData[j].contentType=="checkbox"){
				td.push(
				<td className="w-full font-bold lg:w-auto p-3 text-gray-800  lg:pl-10 text-center block lg:table-cell relative lg:static">
					<CheckBox setIsChecked={()=>tableData[j].action(i)} isChecked={tableData[j].isChecked[i]}/>
			    </td>
			    )
			}
		}
		tableRow.push(<>
	        <tr className=" flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap  mb-10 lg:mb-0">
	            {td}
				
	        </tr>
			<div style={{borderWidth:1}} className='lg:hidden md:flex opacity-50 -mt-7 mb-2'></div></>
	    )
	}

    return(<>
    	<table className="border-collapse w-full relative">
			
    		<thead>
			    {columnTitles}
  			</thead>
			<tbody>
        		{tableRow}
				
			</tbody>
			
    	</table>
		</>
    )
}
