import React, {useEffect} from "react";
import { useCookies } from "react-cookie";
import axios from "../../utils/axios";
import { BiTrash } from "react-icons/bi";
import CreatePaymentMethod from "./CreatePaymentMethod";
import Swal from "sweetalert2";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";


const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 5000,
});

export default function PaymentMethod() {
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [defaultCardId, setDefaultCardId] = React.useState(null);

  const [cookies, setCookie, removeCookie] = useCookies();
  //
  const [showAddCard, setShowAddCard] = React.useState({
    show: false,
    type: "create",
  });

  React.useEffect(() => {
    getPaymentMethods();
  }, []);

  //get payment methods
  const getPaymentMethods = async () => {
    const response = await axios({
      method: "POST",
      url: "/paymentmethod",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    });
    if (response.status === 200) {
      setDefaultCardId(response.data.customer.default_source)
      setPaymentMethods(response.data.response || []);
    } else {
      //TODO: either show error toast or no payment method found
    }
  };

  // remove card from user list
  const removeCard = async (cardId: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#D0A342",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log('card to be deleted', cardId)
        const response = await axios({
          method: "POST",
          url: "/deletecard",
          headers: {
            Authorization: `Bearer ${cookies.tokenId}`,
          },
          data: { cardToken: cardId },
        });
        if (response.status === 200) {
          const filteredCards = paymentMethods.filter(
            (card: { id: string }) => card.id !== cardId
          );
          setPaymentMethods(filteredCards);
          Swal.fire("Card was deleted!", "", "success");
        } else {
          Swal.fire("Something went wrong! Try again later.", "", "error");
          //TODO: either show error toast or no payment method found
        }
      }
    });
  };

  // Add payment method
  const addPaymentMethod = () => {
    setShowAddCard({ show: true, type: "create" });
  };
  return (
    <div>
      <div className="mt-3">
        <div
          onClick={addPaymentMethod}
          className="payment-card-container cursor-pointer mb-3"
        >
          <a className="mb-2 p-4 shadow-round add-card rounded-xl">
            {/* <v-icon>mdi-credit-card-outline</v-icon> */}
            <p className="font700">+ Add Payment Method</p>
          </a>
        </div>
        {showAddCard.show && (
          <CreatePaymentMethod
            addNewCard={(card: never) =>
              setPaymentMethods([...paymentMethods, card])
            }
            setCardStatus={setShowAddCard}
          />
        )}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-12 z-20 mt-10">
          {paymentMethods.map((paymentMethod, index) => (
            <PaymentMethodCard removeCard={removeCard} defaultCardId={defaultCardId} setDefaultCardId={setDefaultCardId} {...paymentMethod} key={index}/>
          ))}
        </div>
      </div>
    </div>
  );
}

// payment method card component
const PaymentMethodCard = ({
  card,
  billing_details,
  removeCard,
  id,
  defaultCardId,
  setDefaultCardId,
  customer,
}) => { 

  const [cookies, setCookie, removeCookie] = useCookies();
  const [menuToggle, setMenuToggle] = React.useState(false)
  const [menuItems, setMenuItems] = React.useState([])
  const [isEditClicked, setIsEditClicked] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [newExpYear, setNewExpYear] = React.useState(null)
  const [newExpMonth, setNewExpMonth] = React.useState(null)
  const [expYear, setExpYear] = React.useState(null)
  const [expMonth, setExpMonth] = React.useState(null)

  useEffect(()=>{
    setExpYear(card.exp_year)
    setExpMonth(card.exp_month)
  },[])
  
  useEffect(()=>{
    const items = [
      {name: "Edit", isShown: true, action: ()=>setIsEditClicked(true)}, 
      {name: "Make default", isShown: defaultCardId==id ? false : true, action: ()=>makeCardDefault()}, 
      {name: "Delete", isShown: true, action: ()=>removeCard(id)}]
    setMenuItems(items)
  },[defaultCardId])

  const makeCardDefault = async () => {
    await axios({
      method: "POST",
      url: "/paymentmethod/updatedefaultcard",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { cardId: id },
    }).then((response)=>{
      setDefaultCardId(response.data.response.default_source)
    }).catch((err)=>console.log(err))
  }

  const saveChanges = async () => {
    setLoading(true)
    await axios({
      method: "POST",
      url: "/paymentmethod/edit",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { cardId: id, expMonth: newExpMonth, expYear: newExpYear },
    }).then((response)=>{
      setExpMonth(response.data.response.exp_month)
      setExpYear(response.data.response.exp_year)
      Toast.fire({
        title: "Card has been changed!",
        icon: "success",
      })
      setIsEditClicked(false)
      setLoading(false)
    }).catch((err)=>{
      Toast.fire({
        title: "Invalid month or year",
        icon: "error",
      })
      setLoading(false)
    })
  }  


  let menuItemBlocks = null;
  if(menuItems){
    menuItemBlocks = menuItems.map((item)=>{
      if(item.isShown){
      return <p onClick={item.action} className="p-2 hover:bg-grey">{item.name}</p>
    }
    })
}
  
  return(
  <div
    className="p-4 mb-2 shadow-round rounded-xl z-50 max-w-sm"
    style={{
      backgroundImage:
        card.brand === "visa"
          ? `linear-gradient(to right, #f2d50f, #f76b1c)`
          : `linear-gradient(to right, #13f1fc, #0470dc)`,
    }}
  >
    <div className="p-1 text-left flex flex-wrap justify-between">
      <p className="text-white">{id==defaultCardId ? card.brand + " - default" : card.brand}</p>
      {/* <button
        onClick={() => removeCard(id)}
        className="text-white hover:opacity-70 cursor-pointer"
      >
        <BiTrash />
      </button> */}
      <span onClick={()=>setMenuToggle(!menuToggle)} className="col-span-1 cursor-pointer mt-1 relative">
        <BsThreeDotsVertical color='white'/>
        { menuToggle ?
          <div className="absolute right-4 top-5 bg-black w-44 h-auto bg-lightGrey">
            {menuItemBlocks}
          </div>
          : null	
        }
      </span>
    </div>
    <p className="p-1 mt-4 text-white text-2xl">●●●● ●●●● ●●●● {card.last4}</p>
    <div className="p-1 text-left mt-2">
      <span className="text-sm text-white">{billing_details?.name}</span>
    </div>
    <div className="p-1 text-left text-sm">
      {!isEditClicked ? 
      <span className="text-left text-sm text-white">
        {expMonth}/{expYear}
      </span>
      :
      <div> 
      <input className="w-12 mr-4 outline-none" placeholder={card.exp_month} type="number" value={newExpMonth} onChange={(e)=>setNewExpMonth(e.target.value)}/>
      <span className="mr-3">/</span>
      <input className="w-12 outline-none" type="number" placeholder={card.exp_year} value={newExpYear} onChange={(e)=>setNewExpYear(e.target.value)} />
      {loading ? <CgSpinner size="19" className="inline-block ml-3 animate-spin"/> : <button className="ml-3 cursour-pointer" onClick={saveChanges}>Save</button>}
      </div>
      }
    </div>
  </div>
)
}
