import React, { useState, useContext, useEffect, Fragment } from "react";
import { Context } from "../../utils/store";
import axios from "../../utils/axios";
import { AiOutlinePlus } from "react-icons/ai";
import DonationDetails from "./DonationsDetails";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import Moment from "react-moment";
import ScheduleLoader from "./../../components/ScheduleLoader";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import AnimateHeight from "react-animate-height";

export default function DonationSchedule(props: any) {
  const { state, dispatch } = useContext(Context);
  const [transactions, setTransactions] = React.useState<Array<any> | null>(
    null
  );
  const [donationsDetailsShown, setDonationsDetailsShown] = useState(false);
  const [schedulesList, setSchedulesList] = useState(null);
  const [inActiveSchedulesList, setInActiveSchedulesList] = useState(null);
  const [currentScheduleDetails, setCurrentScheduleDetails] = useState(null);
  const [currentScheduleNumber, setCurrentScheduleNumber] = useState(null);
  const [cookies, setCookie] = useCookies();
  const [showLoader, setShowLoader] = useState(true);

  const [height, setHeight] = useState(0);
  const [height2, setHeight2] = useState("auto");

  const history = useHistory();

  const toggleScheduleView = () => {
    height === 0 ? setHeight("auto") : setHeight(0);
  };

  const toggleScheduleView2 = () => {
    height2 === "auto" ? setHeight2(0) : setHeight2("auto");
  };

  const getAllTransactions = async () => {
    await axios({
      method: "post",
      url: "/transactions",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    }).then((response) => {
      setTransactions(response.data.transactions.docs);
      // if(response.data.transactions.length > 0) setTableDataHandler();
      dispatch({
        type: "transactionHistory",
        transactionHistory: response.data.transactions.docs,
      });
    });
  };

  React.useEffect(() => {
    if (!state?.transactionHistory) {
      getAllTransactions();
    } else {
      setTransactions(state?.transactionHistory);
    }
  }, [state]);

  const getSchedulesList = async () => {
    let islamicMonths;
    await axios
      .get("/islamicmonths")
      .then((response) => {
        islamicMonths = response.data.response;
      })
      .catch((err) => console.log(err));

    await axios({
      method: "get",
      url: "/userdonationschedule?activeSchedules=Yes",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        let donationSchedules = [...response.data.response];

        donationSchedules.forEach((oneSchedule, oneScheduleIndex) => {
          oneSchedule.islamicDays.forEach(
            (oneIslamicDay, oneIslamicDayIndex) => {
              let monthIndex = islamicMonths.findIndex((oneMonth) => {
                if (oneMonth._id == oneIslamicDay.islamicMonthId) {
                  return true;
                }
              });
              donationSchedules[oneScheduleIndex].islamicDays[
                oneIslamicDayIndex
              ].monthName = islamicMonths[monthIndex]?.name;
            }
          );
        });

        dispatch({
          type: "donationSchedules",
          donationSchedules: donationSchedules,
        });
        setSchedulesList(donationSchedules);
        setShowLoader(false);
        console.log(state?.donationSchedules);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });

    await axios({
      method: "get",
      url: "/userdonationschedule?activeSchedules=No",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        let donationSchedules = [...response.data.response];

        donationSchedules.forEach((oneSchedule, oneScheduleIndex) => {
          oneSchedule.islamicDays.forEach(
            (oneIslamicDay, oneIslamicDayIndex) => {
              let monthIndex = islamicMonths.findIndex((oneMonth) => {
                if (oneMonth._id == oneIslamicDay.islamicMonthId) {
                  return true;
                }
              });
              donationSchedules[oneScheduleIndex].islamicDays[
                oneIslamicDayIndex
              ].monthName = islamicMonths[monthIndex]?.name;
            }
          );
        });

        dispatch({
          type: "inActiveDonationSchedules",
          inActiveDonationSchedules: donationSchedules,
        });
        setInActiveSchedulesList(donationSchedules);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getSchedulesList();
  }, [donationsDetailsShown]);

  //console.log(schedulesList);
  useEffect(() => {
    setSchedulesList(state?.donationSchedules);
    setInActiveSchedulesList(state?.inActiveDonationSchedules);
  }, [state?.donationSchedules, state?.inActiveDonationSchedules]);

  const showDonationDetails = (scheduleDetails, scheduleNumber) => {
    setDonationsDetailsShown(true);
    setCurrentScheduleDetails(scheduleDetails);
    setCurrentScheduleNumber(scheduleNumber);
  };

  const addNewScheduleHandler = () => {
    dispatch({
      type: "firstTimeCurrentPage",
      firstTimeCurrentPage: "Frequency",
    });
    history.push("/donation-automation");
  };

  React.useEffect(() => {
    transactions?.map((oneTransaction, index) => {
      schedulesList?.map((oneSchedule, index) => {});
    });
  }, [transactions, schedulesList]);

  let schedules = null;
  let inactiveSchedules = null;

  if (schedulesList?.length > 0) {
    schedules = schedulesList?.map((schedule, index) => {
      //console.log(index, total, schedule.isActive);

      //   if (schedule.isActive) {
      //     return (
      //       <ScheduleBlock
      //         showDonationDetails={showDonationDetails}
      //         scheduleData={schedule}
      //         amount={total != 0 ? total : total}
      //         key={index}
      //         scheduleIndex={index}
      //       />
      //     );
      //   } else {
      //     return null;
      //   }

      return (
        <Fragment>
          <ScheduleBlock
            showDonationDetails={showDonationDetails}
            scheduleData={schedule}
            key={index}
            scheduleIndex={schedule.sequenceNumber}
            showLoader={showLoader}
          />
        </Fragment>
      );
    });
  }

  if (inActiveSchedulesList?.length > 0) {
    inactiveSchedules = inActiveSchedulesList?.map((schedule, index) => {
      return (
        <Fragment>
          <ScheduleBlock
            showDonationDetails={showDonationDetails}
            scheduleData={schedule}
            key={index}
            scheduleIndex={schedule.sequenceNumber}
            showLoader={showLoader}
          />
        </Fragment>
      );
    });
  }

  if (donationsDetailsShown && currentScheduleDetails) {
    return (
      <DonationDetails
        scheduleData={currentScheduleDetails}
        scheduleNumber={currentScheduleNumber}
        setDonationsDetailsShown={setDonationsDetailsShown}
      />
    );
  } else {
    return (
      <div>
        <div className="mt-4 md:mt-8 mb-6">
          <h3 className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mb-4 z-50 font-Montserrat">
            Donation Schedule
          </h3>
          <hr />
        </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3  gap-x-6 gap-y-6 2xl:pr-16 relative z-20">
          <div
            onClick={addNewScheduleHandler}
            className="bg-lightGrey p-6 h-auto rounded-xl"
          >
            <div className="grid grid-cols-1 border-dashed border-2 border-darkGrey text-center h-full rounded-xl">
              <div className="cursor-pointer place-self-center py-20">
                <span className="inline-block rounded-full border-dashed border-2 border-darkGrey p-2">
                  <AiOutlinePlus size="25" color="#C9C9C9" />
                </span>
                <p className="text-lg font-base mt-4">
                  Add New
                  <br /> Donation Schedule
                </p>
              </div>
            </div>
          </div>
          <ScheduleLoader showShadow={true} showLoader={showLoader} />
          <ScheduleLoader showShadow={true} showLoader={showLoader} />
          {schedules}
        </div> */}

        {/* Active Donation Schedules */}
        <div
          className="shadow-round3 my-3 py-4 transform translate-y-4 bg-white rounded-xl font-bold z-30 relative toggle-schedule-view"
          onClick={toggleScheduleView2}
          style={{ cursor: "pointer" }}
        >
          <span className="font-PoppinsMedium font-medium cursor-default ml-6  text-sm 2xl:text-base">
            Active Donation Schedule
          </span>
          <div className="bg-camel rounded-full mr-4">
            {height2 === "auto" ? (
              <VscChevronUp className="text-white" />
            ) : (
              <VscChevronDown className="text-white" />
            )}
          </div>
        </div>
        <AnimateHeight duration={500} height={height2}>
          <div className="bg-lightGrey rounded-lg border p-3 md:p-6 inline-block w-full z-20 relative md:pt-10 pt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3  gap-x-6 gap-y-6 2xl:pr-16 relative z-20">
              <div
                onClick={addNewScheduleHandler}
                className="bg-lightGrey p-6 h-auto rounded-xl"
              >
                <div className="grid grid-cols-1 border-dashed border-2 border-darkGrey text-center h-full rounded-xl">
                  <div className="cursor-pointer place-self-center py-20">
                    <span className="inline-block rounded-full border-dashed border-2 border-darkGrey p-2">
                      <AiOutlinePlus size="25" color="#C9C9C9" />
                    </span>
                    <p className="text-lg font-base mt-4">
                      Add New
                      <br /> Donation Schedule
                    </p>
                  </div>
                </div>
              </div>
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              {schedules}
            </div>
          </div>
        </AnimateHeight>

        {/* Inactive Donation Schedules */}
        <div
          className="shadow-round3 my-3 py-4 transform translate-y-4 bg-white rounded-xl font-bold z-30 relative toggle-schedule-view"
          onClick={toggleScheduleView}
          style={{ cursor: "pointer" }}
        >
          <span className="font-PoppinsMedium font-medium cursor-default ml-6  text-sm 2xl:text-base">
            Inactive Donation Schedule
          </span>
          <div className="bg-camel rounded-full mr-4">
            {height !== 0 ? (
              <VscChevronUp className="text-white" />
            ) : (
              <VscChevronDown className="text-white" />
            )}
          </div>
        </div>

        <AnimateHeight duration={500} height={height}>
          <div className="bg-lightGrey rounded-lg border p-3 md:p-6 inline-block w-full z-20 relative md:pt-10 pt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3  gap-x-6 gap-y-6 2xl:pr-16 relative z-20">
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              <ScheduleLoader showShadow={true} showLoader={showLoader} />
              {inactiveSchedules}
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const ScheduleBlock = (props: any) => {
  const isProcessed = props.scheduleData.isProcessed;

  const viewDonationDetailsHandler = () => {
    props.showDonationDetails(props.scheduleData, props.scheduleIndex);
  };

  return (
    <Fragment>
      <div
        style={{ display: props.showLoader ? "none" : null }}
        className="h-auto relative bg-camel shadow-lg rounded-xl pt-12 relative z-20"
      >
        <p className="w-full absolute top-3 text-center font-bold text-white">
          Schedule #
          {/* {props.scheduleIndex < 9
            ? "0" + (props.scheduleIndex + 1)
            : props.scheduleIndex + 1} */}
          {props.scheduleData.sequenceNumber}
        </p>
        <div className="bg-white h-full rounded-xl px-4 py-6">
          <p className="text-base font-base text-grey2 text-center">
            Schedule created on&nbsp;
            {
              <Moment format="MM/DD/YYYY">
                {props.scheduleData.scheduleStartDate}
              </Moment>
            }
          </p>
          <div className="w-full rounded-xl bg-lightGrey text-center mt-6 mb-6 py-2">
            <p className="font-bold text-black">Total Donation Processed</p>
            <p className="">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(
                props.scheduleData?.isProcessed
                  ? props.scheduleData?.totalProcessedAmount
                  : props.scheduleData?.totalProcessedAmount
              )}
              (USD)
            </p>
          </div>
          <p
            className="text-center font-base text-base text-bismillahGreen1 mb-4"
            style={{
              color: props.scheduleData.isActive ? null : "red",
            }}
          >
            {props.scheduleData.islamicDays.length +
              props.scheduleData.personalDays.length +
              props.scheduleData.otherDays.length}{" "}
            Donations Scheduled
          </p>
          <div className="text-center">
            <div
              onClick={viewDonationDetailsHandler}
              className="cursor-pointer inline-block bg-camelLight text-camel py-3 px-8 rounded-md"
            >
              View/Edit
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
