import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../utils/store";
import axios from "../../utils/axios";
import PlusMinusSquare from "../../components/PlusMinusSquare";
import SwitchComponent from "../../components/SwitchComponent";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { utils } from 'react-modern-calendar-datepicker';
import SkeletonLoader from "../../components/SkeletonLoader";
import { idText } from "typescript";
interface dayProps {
  isMultiple: any;
  name: any;
  group: any;
}
export default function SelectPersonalDays() {
  const { state, dispatch } = useContext(Context);

  const [personalDays, setPersonalDay] = useState<Array<any> | null>(null);
  const [customEventName, setCustomEventName] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [inMemoryName, setInMemoryName] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  
  useEffect(() => {
    if (!state.firstTimeInfo.personalDays) {
      axios
        .get("/personaldays")
        .then((response) => {
          // Adding group property where isMultiple true:
          let newPersonalDays = response.data.response.map(
            (day: dayProps, index: any) => {
              let newDay = { ...day };
              if (day.isMultiple) {
                newDay.group = day.name;
                return newDay;
              } else {
                return newDay;
              }
            }
          );
          newPersonalDays = newPersonalDays.filter(
            (oneDay: { status: any }, index: any) => {
              if (oneDay.status) {
                return true;
              }
            }
          );
          dispatch({
            type: "firstTimeInfo",
            firstTimeInfo: {
              ...state.firstTimeInfo,
              personalDays: newPersonalDays,
            },
          });
          setPersonalDay(newPersonalDays);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });
    } else {
      setPersonalDay(state.firstTimeInfo.personalDays);
    }
  }, [0]);

  useEffect(() => {
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, personalDays: personalDays },
    });
    setShowLoader(false);
  }, [personalDays]);

  useEffect(() => {
    if (!personalDays) {
      setPersonalDay(state.firstTimeInfo.personalDays);
    }
  }, [state.firstTimeInfo.personalDays]);

  const addExtraDay = (index: number) => {
    let newPersonalDays = [...personalDays];
    let newDay = { ...personalDays[index] };
    newDay._id = Date.now();
    newDay.isExtraDay = true;
    newDay.isPersonalDayActive = true;
    newPersonalDays.splice(index + 1, 0, newDay);
    setPersonalDay(newPersonalDays);
  };

  const removeExtraDay = (index: number) => {
    let newPersonalDays = [...personalDays];
    newPersonalDays.splice(index, 1);
    setPersonalDay(newPersonalDays);
  };

  const addCustomDay = () => {
    if (!customEventName == "") {
      let newPersonalDays = [...personalDays];
      newPersonalDays.push({
        _id: Date.now(),
        name: customEventName,
        icon: "https://charitymoment-staging.s3.us-east-2.amazonaws.com/calendar.png",
        isMultiple: true,
        allowManualEntry: false,
        isPersonalDayActive: true,
        date: customDate,
        custom: true,
        isExtraDay: true,
      });
      setPersonalDay(newPersonalDays);
      setCustomEventName("");
      setCustomDate(null);
    }
  };

  const removeLastCustomDay = () => {
    let newPersonalDays = [...personalDays];
    if (newPersonalDays[newPersonalDays.length - 1]?.custom) {
      newPersonalDays.pop();
      setPersonalDay(newPersonalDays);
    }
  };

  const toggleManualEntry = (index: React.ReactText) => {
    if ("date" in personalDays[index] && personalDays[index]?.date !== null) {
      let newPersonalDays = [...personalDays];
      newPersonalDays[index].isPersonalDayActive =
        !newPersonalDays[index].isPersonalDayActive;
      setPersonalDay(newPersonalDays);
      return true;
    } else {
      return false;
    }
  };

  const renderCustomInput = (
    ref:
      | string
      | React.RefObject<HTMLElement>
      | ((instance: HTMLInputElement | null) => void)
      | null
      | undefined,
    index: number,
    isCustom: boolean
  ) => {
    let date = null;
    let isDisabled = null;

    if (!isCustom) {
      let day = personalDays[index]?.date?.day
        ? personalDays[index]?.date?.day
        : null;
      let month = personalDays[index]?.date?.month
        ? personalDays[index]?.date?.month
        : null;
      let year = personalDays[index]?.date?.year
        ? personalDays[index]?.date?.year
        : null;

      day = ("0" + day).slice(-2);
      month = ("0" + month).slice(-2);

      if (day && month && year) {
        date = `${month}/${day}/${year}`;
      }
    } else {
      let day = customDate?.day ? customDate?.day : null;
      let month = customDate?.month ? customDate?.month : null;
      let year = customDate?.year ? customDate?.year : null;

      day = ("0" + day).slice(-2);
      month = ("0" + month).slice(-2);

      if (day && month && year) {
        date = `${month}/${day}/${year}`;
      }
    }
    
    return (
      <input
        ref={ref}
        value={date ? date : ""}
        placeholder="MM/DD/YY"
        style={{
          width: "12rem",
          backgroundColor: isDisabled ? "#fafafa" : "#f6f6f6",
          textAlign: "center",
          padding: "0 .7rem",
          boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)",
          borderRadius: "5px",
          color: "black",
          outline: "none",
        }}
        className={`my-custom-input-class = z-auto = h-8`} // a styling class
      />
    );
  };

  const dateHandler = (value: any, index: any, isCustom: boolean) => {
    if (!isCustom) {
      let newPersonalDays = [...personalDays];
      // newPersonalDays.push({
      //   isPersonalDayActive: true,
      // });
      newPersonalDays[index].isPersonalDayActive = true;
      newPersonalDays[index].date = value;
      setPersonalDay(newPersonalDays);
    } else {
      setCustomDate(value);
    }
  };

  const manualEntryTextHandler = (value: string, id: string) => {
    let newPersonalDays = [...personalDays];
    let index = newPersonalDays?.findIndex((day) => {
      if (day._id == id) {
        return true;
      }
    });

    newPersonalDays[index].manualEntryText = value;
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: {
        ...state.firstTimeInfo,
        personalDays: newPersonalDays,
      },
    });
  };

  let personalDaysBlocks = null;
  if (personalDays) {
    personalDaysBlocks = personalDays.map((el: any, index: any) => {
      let isMinusShown = false;

      if (el.isMultiple && el.group) {
        let matchDay = 0;
        personalDays.forEach((day) => {
          if (el.group == day?.group) {
            matchDay++;
          }
        });
        if (matchDay > 1) {
          isMinusShown = true;
        }
      } else if (el.custom) {
        isMinusShown = true;
      }
    
      const gregorianToday = utils("en").getToday(); 
      return (
        <div className="gap-y-2 mt-8 md:mt-6 md:gap-x-10 lg:gap-x-2 grid grid-cols-1 md:grid-cols-2 z-auto">
          <div className="grid grid-cols-1 gap-y-2 inline-block 2xl:flex 2xl:flex-row  flex flex-wrap content-center z-50 personal-days-row">
            <div className="inline-block flex flex-wrap content-center">
              <div className="w-full days-item-cnt">
                <img
                  className="w-6 2xl:w-8 inline-block"
                  src={el.icon}
                  alt="icon"
                />
                <p className="inline-block text-xs md:text-sm 2xl:text-base text-black inline-block ml-4 2xl:ml-12">
                  {el.name}
                </p>
                <div className="md:hidden float-right md:mr-0 md:float-none md:self-end z-20 relative">
                  <SwitchComponent
                    action={toggleManualEntry}
                    switchValue={el.isPersonalDayActive}
                    indexOfDay={index}
                    dateSelected={
                      "date" in el && el?.date !== null ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            {el.allowManualEntry ? (
              <div className="2xl:ml-auto 2xl:flex inline-block w-full 2xl:w-60">
                <input
                  style={{ boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)" }}
                  className="float-left bg-lightGrey px-4 outline-none w-full xl:w-56 lg:w-52 md:w-52 sm:text-sm border-gray-300 rounded-md h-8 z-20 relative "
                  type="text"
                  onChange={(e) =>
                    manualEntryTextHandler(e.target.value, el._id)
                  }
                  value={el?.manualEntryText}
                  placeholder={el?.placeholderText}
                />
              </div>
            ) : null}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 md:self-center">
            <div className="inline-block md:justify-self-end checkout-datepicker-cnt">
              <DatePicker
                value={null}
                onChange={(newValue) => dateHandler(newValue, index, false)}
                calendarPopperPosition={index < 2 ? "bottom" : "auto"}
                renderInput={({ ref }) => renderCustomInput(ref, index, false)} // render a custom input
                disabledDays={[gregorianToday]} // here we pass them
                shouldHighlightWeekends
              />
            </div>
            {el.isMultiple ? (
              <div className="inline-block self-end place-self-end z-20 relative">
                <PlusMinusSquare
                  action={addExtraDay}
                  indexOfDay={index}
                  op="＋"
                  isActive={true}
                />
                <PlusMinusSquare
                  action={removeExtraDay}
                  indexOfDay={index}
                  op="-"
                  isActive={isMinusShown}
                />
              </div>
            ) : (
              <div className="inline-block z-20 relative"></div>
            )}
            <div className="hidden md:block float-right mr-6 md:mr-0 md:float-none md:self-end inline-block z-20 relative md:place-self-end">
              <SwitchComponent
                action={toggleManualEntry}
                switchValue={el.isPersonalDayActive}
                indexOfDay={index}
                dateSelected={"date" in el && el?.date !== null ? true : false}
              />
            </div>
          </div>
        </div>
      );
    });
  }
  if (personalDays) {
    const gregorianToday = utils("en").getToday(); 
    return (
      <div className="md:ml-6">
        {showLoader ? (
          <div className="w-1/2">
            <SkeletonLoader showLoader={showLoader} showShadow={false} />
            <SkeletonLoader showLoader={showLoader} showShadow={false} />
          </div>
        ) : null}

        <div>{personalDaysBlocks}</div>
        <div className="mt-8 md:mt-10 z-50 relative mb-6 md:mb-10">
          <p className="text-xs md:text-sm 2xl:text-base text-black mb-3 days-item-title-text">
            Add custom personal event(s)
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 lg:gap-x-2 items-end">
            <div className="inline-block">
              <input
                value={customEventName}
                onChange={(e) => {
                  setCustomEventName(e.target.value);
                }}
                style={{ boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)" }}
                className="bg-lightGrey px-4 outline-none w-full xl:w-56 lg:w-52 md:w-52 sm:text-sm border-gray-300 rounded-md h-10"
                type="text"
                placeholder="Event name"
              />
            </div>
            <div className="grid grid-cols-3">
              <div className="col-span-1 mx-auto md:justify-self-end md:mx-0 mb-3 mt-2 md:mt-0 md:mb-0 left-none checkout-datepicker-cnt">
                <DatePicker
                  value={null}
                  onChange={(newValue) =>
                    dateHandler(newValue, personalDays.length, true)
                  }
                  calendarPopperPosition="auto"
                  renderInput={({ ref }) =>
                    renderCustomInput(ref, personalDays.length, true)
                  } // render a custom input
                  disabledDays={[gregorianToday]} // here we pass them
                  shouldHighlightWeekends
                />
              </div>
              <div className="col-span-2 md:col-span-1 inline-block flex flex-row self-center text-center z-20 ml-28 md:ml-0 relative place-self-end">
                <PlusMinusSquare
                  isActive={true}
                  action={addCustomDay}
                  op="＋"
                />
                <PlusMinusSquare
                  isActive={true}
                  action={removeLastCustomDay}
                  op="-"
                />
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
