import React, { useContext } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useHistory } from "react-router-dom";
import StickyHeader from "../components/StickyHeader";
import { Context } from "../utils/store";
export default function Leadership(props: any) {
  let history = useHistory();
  const { state } = useContext(Context);

  const isStickyHeader = history.location.pathname.indexOf("/login") == 0 ||
    history.location.pathname.indexOf("/profile") == 0 ||
    history.location.pathname.indexOf("/donation-automation") == 0 ||
    history.location.pathname.indexOf("/thankyou") == 0

  return (
    <div
      style={{ backgroundColor: "rgb(246 246 246)" }}
      className={`bg-gray-100 bg-opacity-80 ${isStickyHeader ? '' : state.viewBetaMsg ? 'alert-beta-visible' : ''}`}
    >
      {history.location.pathname.indexOf("/login") == 0 ||
      history.location.pathname.indexOf("/profile") ==
        0 ? null : history.location.pathname.indexOf("/donation-automation") == 0 ||
        history.location.pathname.indexOf("/thankyou") == 0 ? (
        <StickyHeader />
      ) : (
        <Header />
      )}
      {props.children}
      {history.location.pathname.indexOf("/donation-automation") == 0 ||
      history.location.pathname.indexOf("/profile") == 0 ||
      history.location.pathname.indexOf("/thankyou") == 0 ||
      history.location.pathname.indexOf("/login") == 0 ? null : (
        <Footer />
      )}
    </div>
  );
}
