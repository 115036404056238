import React from "react";
import { CgSpinner } from "react-icons/cg";

export default function Button(props: any) {
  let width = null;

  if (props.width) {
    width = "w-" + props.width;
  }

  return (
    <button
      onClick={props.action}
      className={
        width
          ? `mx-auto px-5 2xl:py-3 ${width} focus:outline-none ${props.disableButton ? 'bg-gray-200 text-black shadow-lg cursor-not-allowed' : 'bg-camel text-white'} rounded text-xs md:text-base font-Poppins ${props.style}`
          : `mx-auto px-5 py-1 md:py-3 2xl:py-4 w-42 xl:w-80 2xl:w-96 focus:outline-none ${props.disableButton ? 'bg-gray-200 text-black shadow-lg cursor-not-allowed' : 'bg-camel text-white'} rounded text-xs md:text-base font-Poppins ${props.style}`
      }
      disabled={props.loading || props.disableButton}
    >
      {props.loading ? (
        <CgSpinner size="25" className="absolute animate-spin" />
      ) : null}
      {props.title}
    </button>
  );
}
