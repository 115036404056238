import { useContext } from "react";
import { Context } from "../utils/store";
import "../css/styles.css";
interface LoaderProps {
  showLoader: boolean;
  showShadow: boolean;
}

const SkeletonLoader = (props: LoaderProps) => {
  return (
    <>
      {props.showLoader ? (
        <div
          className={`${
            props.showShadow ? "shadow-round" : null
          }  rounded-xl p-8 max-w-sm w-full h-48 mx-auto`}
        >
          <div className="animate-pulse flex space-x-4 content-center">
            <div className="rounded-full bg-camel opacity-40 h-12 w-12"></div>
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-camel opacity-40 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-camel opacity-40 rounded"></div>
                <div className="h-4 bg-camel opacity-40 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      ) : // <div className="w-full p-8 flex flex-wrap">
      //   <div className="w-full p-2">
      //     <div className="flex flex-col relative w-full overflow-hidden card translate-3d-none-after relative overflow-hidden translate-3d-none-after rounded">
      //       <div className="relative group text-primary-500">
      //         <div className="absolute top-0 left-0 h-full w-full"></div>
      //       </div>
      //       <div className="flex flex-col flex-grow">
      //         <div className="pl-4 pr-4 pt-4 mb-4 text-left relative flex-grow">
      //           <h3 className="text-lg font-bold text-gray-darkest mr-10">
      //             <span className="skeleton-box h-5 w-2/6 inline-block rounded mr-2"></span>
      //             <span className="skeleton-box h-5 w-2/6 inline-block rounded"></span>
      //             <span className="skeleton-box h-5 w-2/4 inline-block rounded mr-2"></span>
      //             <span className="skeleton-box h-5 w-2/5 inline-block rounded"></span>
      //             <span className="skeleton-box h-5 w-3/4 inline-block rounded"></span>
      //           </h3>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      null}
    </>
  );
};

export default SkeletonLoader;
