import React, { useContext, useEffect, useState } from "react";
import bg from "../../assets/profile_bg.png";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.svg";
import payment_gold from "../../assets/icons/payment_gold.png";
import logout from "../../assets/icons/checkout.svg";
import logout_gold from "../../assets/icons/checkout_gold.svg";
import user from "../../assets/icons/user.svg";
import user_gold from "../../assets/icons/user_gold.svg";
import payment from "../../assets/icons/payment.svg";
import donation from "../../assets/icons/donation.svg";
import donation_gold from "../../assets/icons/donation_gold.svg";
import transaction from "../../assets/icons/transaction.svg";
import transaction_gold from "../../assets/icons/transaction_gold.svg";
import TransactionDetails from "./TransactionsDetails";
import ArrowLeft from "../../assets/icons/ArrowLeft.png";
import Bell from "../../assets/icons/bell_2x.png";
import linkedin from "../../assets/icons/linkedin.png";
import twitter from "../../assets/icons/group-8805.png";
import instagram from "../../assets/icons/group-8806.png";
import facebook from "../../assets/icons/group-8807.png";
import { Context } from "../../utils/store";
import Profile from "./Profile";
import axios from "../../utils/axios";
import TransactionHistory from "./TransactionHistory";
import PaymentMethod from "./PaymentMethod";
import DonationSchedule from "./DonationSchedule";
import { MenuContext } from "react-flexible-sliding-menu";
import moment from "moment";
import { useCookies } from "react-cookie";
import StickyHeader from "../../components/StickyHeader";
import { Mixpanel } from "../../Mixpanel";


export default function FirstTime() {
  const { state, dispatch } = useContext(Context);
  let history = useHistory();
  const { toggleMenu } = useContext(MenuContext);
  const [cookies, setCookie, removeCookie] = useCookies();

  const [page, setPage] = useState('Profile');
  const [currentPage, setCurrentPage] = useState(<></>);
  const [tran, setTran] = useState(false);

  const allPages = [
    { name: 'Profile', title: 'Profile' },
    { name: 'DonationSchedule', title: 'Donation Schedule' },
    { name: 'PaymentMethod', title: 'Payment Method' },
    { name: 'TransactionHistory', title: 'Transaction History' },
    { name: 'TransactionDetails', title: 'Transaction Details' },
    { name: 'Logout', title: 'Log Out' },
  ];

  useEffect(() => {
    dispatch({ type: 'pagesList', pagesList: allPages });
    setPage(state?.profileCurrentPage);
    document.getElementsByTagName('body')[0].classList.add('sticky-menu')
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('sticky-menu')
    }
  }, []);

  useEffect(() => {
    setPage(state.profileCurrentPage);
  }, [state.profileCurrentPage]);

  const logoutHandler = async () => {
    await axios({
      method: 'post',
      url: '/logout',
      data: { email: state?.userInfo?.email },
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        Mixpanel.track('Logout');
        removeCookie('id');
        removeCookie('cookie');
        removeCookie('tokenId');
        removeCookie('refreshToken');
        dispatch({
          type: 'userInfo',
          userInfo: null,
        });
        window.location.href = '/login';
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // console.log(state);
    if (page == 'Profile') {
      Mixpanel.track('View Profile Page');
      setCurrentPage(<Profile />);
      setTran(false);
    } else if (page == 'DonationSchedule') {
      setCurrentPage(<DonationSchedule />);
      Mixpanel.track('View DonationSchedule Page');
      setTran(false);
    } else if (page == 'PaymentMethod') {
      Mixpanel.track('View PaymentMethod Page');
      setCurrentPage(<PaymentMethod />);
      setTran(false);
    } else if (page == 'TransactionHistory') {
      Mixpanel.track('View TransactionHistory Page');
      setCurrentPage(
        <TransactionHistory
          name="relative z-30"
          action={() => setPage('TransactionDetails')}
        />
      );
      setTran(true);
    } else if (page == 'TransactionDetails') {
      Mixpanel.track('View TransactionDetails Page');
      setCurrentPage(
        <TransactionDetails id={state?.params?.id} style="relative z-30" />
      );
      setTran(true);
    }
  }, [page]);

  return (
    <>
      <StickyHeader />
      <img
        className="hidden md:flex overscroll-x-none overscroll-y-none absolute w-screen z-0"
        src={bg}
      />

      <div className="lg:hidden bg-white w-max z-30 relative p-1 rounded-r-lg shadow-fullCard mb-4">
        <button
          onClick={toggleMenu}
          className="ml-4 items-center px-3 py-2 rounded text-teal-200"
        >
          <svg
            className="fill-current text-camel h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        <span className="text-gray-500 px-2 text-sm">
          {page == 'TransactionDetails'
            ? 'Transaction History'
            : allPages.find((x) => x.name === page).title}
        </span>
      </div>
      <div className="px-0 sm:px-4 xl:px-10  w-full h-screen/2 p-2 grid grid-cols-1 auto-cols-max lg:grid-cols-4  ">
        <div className="hidden lg:flex px-2 bg-white h-screen/2 md:px-4 2xl:px-12 md:mr-8 lg:mr-4 xl:mr-8 mb-8 rounded-side md:relative text-side min-h-540 fixed-menu-col">
          <div className="relative w-full h-full z-20">
            <button
              onClick={() => setPage('Profile')}
              className={
                page != 'Profile'
                  ? 'z-50 flex flex-row text-gray-500 rounded-side p-4 focus:outline-none w-full text-left mt-10 text-sm 2xl:text-side font-Poppins'
                  : 'z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-4 flex flex-row w-full text-left mt-10 text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'Profile' ? user : user_gold}
              />
              Profile
            </button>
            <button
              onClick={() => setPage('DonationSchedule')}
              className={
                page != 'DonationSchedule'
                  ? 'z-50 text-gray-500 rounded-side p-4  focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-4 flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'DonationSchedule' ? donation : donation_gold}
              />
              Donation Schedule
            </button>
            <button
              onClick={() => setPage('PaymentMethod')}
              className={
                page != 'PaymentMethod'
                  ? 'z-50 text-gray-500 rounded-lg p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-side p-4 flex flex w-full text-left text-sm 2xl:text-side'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'PaymentMethod' ? payment : payment_gold}
              />
              Payment Method
            </button>
            <button
              onClick={() => setPage('TransactionHistory')}
              className={
                tran != true
                  ? 'z-50 text-gray-500 rounded-side p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side'
                  : 'z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-4 flex text-sm 2xl:text-side w-full text-left '
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={tran != true ? transaction : transaction_gold}
              />
              Transaction History
            </button>
            <button
              onClick={logoutHandler}
              className={
                page != 'Logout'
                  ? 'z-50 text-gray-500 rounded-side p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side'
                  : 'z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-4 flex text-sm 2xl:text-side w-full text-left '
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'Logout' ? logout : logout_gold}
              />
              Log Out
            </button>

            <div className="absolute bottom-4 2xl:bottom-10  h-auto w-full">
              <div className="justify-center flex flex-wrap">
                <a
                  href="mailto:salaam@charitymoments.com"
                  className="text-brownGrey relative md:absolute bottom:24 lg:bottom-32 xl:bottom-32 2xl:bottom-32 p-2 text-sm 2xl:text-base hover:opacity-80"
                >
                  salaam@charitymoments.com
                </a>
                <div
                  style={{ width: 300 }}
                  className="relative md:absolute bottom-20 2xl:bottom-24 justify-center p-2 lg:relative xl:absolute flex flex-row "
                >
                  <p className="mt-auto text-xs 2xl:text-sm mb-auto mr-2 text-brownGrey xl:flex lg:hidden">
                    <a href="/terms-and-conditions">Terms and Conditions</a>{' '}
                    &nbsp;&nbsp;|&nbsp;&nbsp;{' '}
                    <a href="/privacy-policy">Privacy Policy</a>
                  </p>
                  <p className="mt-auto text-xs 2xl:text-sm mb-auto mr-2 text-brownGrey lg:flex lg:flex-wrap lg:justify-center  lg:-mb-16 xl:hidden">
                    <a
                      className="lg:w-full lg:flex lg:justify-center"
                      href="/terms-and-conditions"
                    >
                      Terms and Conditions
                    </a>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </p>
                  <p></p>

                  {/* <p>|</p>
              <p className="mt-auto text-xs 2xl:text-sm mb-auto ml-2">Privacy Policy</p> */}
                </div>
              </div>
              <div className="justify-center flex flex-wrap lg:mb-8 sm:mb-3 relative">
                {/* Made with ❤️ in NYC */}
                <a
                  className="hover:opacity-80"
                  href="https://www.facebook.com/gocharitymoments"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-8 mr-2" src={facebook} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://twitter.com/Charity_Moments"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-8 mr-2" src={twitter} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.linkedin.com/company/charitymoments/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-8 mr-2" src={linkedin} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.instagram.com/charitymoments/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-8" src={instagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 bg-white pb-8 md:px-12 lg:px-5 xl:px-12 mb-10 min-h-screen/2 rounded-side col-span-3 relative md:mb-8 xl:lg:col-start-2 lg:col-start-2">
          <div className="mt-4 md:mt-8 mb-6">
            {page === 'TransactionDetails' ? (
              <img
                onClick={() => setPage('TransactionHistory')}
                className="inline-block transform -translate-y-1 h-4 relative z-20 md:h-auto md:mr-4 md:ml-0  ml-3"
                src={ArrowLeft}
              />
            ) : null}
            {page == "DonationSchedule" || page == "TransactionHistory" ? null : (
              <>
                <h3 className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mb-4 z-50 font-Montserrat">
                  {allPages.find((x) => x.name === page).title}
                </h3>
                <hr />
              </>
            )}
          </div>
          {currentPage}
        </div>
      </div>
    </>
  );
}
