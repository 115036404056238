import React, { useReducer, useEffect } from "react";
import axios from "./utils/axios";
import { reducer, initialState, Context } from "./utils/store";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Layout from "./components/Layout";
import LandingPage from "./screens/LandingPage";
import Leadership from "./screens/Leadership";
import ShariahCompliance from "./screens/ShariahCompliance";
import LearningCenter from "./screens/LearningCenter";
import FAQs from "./screens/FAQs";
import DaysDates from "./screens/DaysDates";
import CharityPartners from "./screens/CharityPartners";
import LearningCenterDetails from "./screens/LearningCenterDetails";
import CharityPartnersDetails from "./screens/CharityPartnersDetails";
import FirstTime from "./screens/FirstTime";
import ThankYou from "./screens/FirstTime/ThankYou";
import MenuProvider from "react-flexible-sliding-menu";
import SideMenu from "./components/SideMenu";
import Login from "./screens/Login";
import TermsAndConditions from "./screens/TermAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import { CookiesProvider } from "react-cookie";
import MainProfile from "./screens/Profile/Main";
import { useCookies } from "react-cookie";
import DaysDatesDetails from "./screens/DaysDatesDetails";
import "./App.css";
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    console.log("should come here once", cookies);

    const checkAutoLogin = async () => {
      if (cookies.tokenId) {
        await getUserData();
      }
      // else if (cookies.autoLogin === "false") {
      //   dispatch({
      //     type: "isLoggedIn",
      //     isLoggedIn: false,
      //   });
      //   removeCookie("cookie");
      //   removeCookie("tokenId");
      // }
    };
    checkAutoLogin();
  }, [0]);

  const getUserData = async () => {
    await axios({
      method: "post",
      url: "/getuser",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        console.log("get user response in app js", response.data.response);
        dispatch({
          type: "isLoggedIn",
          isLoggedIn: true,
        });
        dispatch({ type: "userInfo", userInfo: response.data.response });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({
          type: "isLoggedIn",
          isLoggedIn: false,
        });
      });
  };

  return (
    <CookiesProvider>
      <Context.Provider value={{ state, dispatch }}>
        <MenuProvider MenuComponent={SideMenu}>
          <Router>
            <Layout>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/leadership" component={Leadership} />
              <Route
                exact
                path="/shariah-compliance"
                component={ShariahCompliance}
              />
              <Route
                exact
                path="/frequently-asked-questions"
                component={FAQs}
              />
              <Route exact path="/learning-center" component={LearningCenter} />
              <Route
                exact
                path="/learning-center/:id"
                component={LearningCenterDetails}
              />
              <Route exact path="/days-and-dates" component={DaysDates} />
              <Route
                exact
                path="/charity-partners"
                component={CharityPartners}
              />
              <Route
                exact
                path="/charity-partners/:id"
                component={CharityPartnersDetails}
              />
              <Route
                exact
                path="/days-and-dates/:title"
                component={DaysDatesDetails}
              />
              <Route
                exact
                path="/donation-automation/:charityId?"
                component={FirstTime}
              />
              <Route exact path="/thankyou" component={ThankYou} />
              <Route
                exact
                path="/login"
                render={() => (
                  <Redirect
                    to={{
                      pathname: state.isLoggedIn ? "/profile" : "/login",
                    }}
                  />
                )}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route exact path="/profile" component={MainProfile} />
            </Layout>
          </Router>
        </MenuProvider>
      </Context.Provider>
    </CookiesProvider>
  );
}

export default App;
