import React from 'react'
import leadershipImage from '../assets/landing/Leaders_3x.png'
import leadership_bg from "../assets/leadership_bg.png";
import charity_partner from '../assets/charity_partner.png';
import {Helmet} from "react-helmet";
import  { Mixpanel }  from '../Mixpanel';

export default function Leadership() {
    Mixpanel.track('View Leadership Page');
    return (
        <div className="mx-0 z-50">
        <Helmet>
            <title>Leadership | CharityMoments</title>
            <meta name="description" content="A note from the co-founders of CharityMoments." />
        </Helmet>
            {/* <img className="hidden md:flex w-screen absolute md:-top-24 2xl:-top-40 z-10" src={leadership_bg} /> */}
            <img className="hidden md:flex w-screen absolute top-0" src={charity_partner}/>
            <Block>
            	<div className="text-center">
                	<h1 className="text-2xl md:text-4xl 2xl:text-8xl pt-6 md:pt-10 md:pt-24">Leadership</h1>
                    {/* <div className="relative inline-block">
                        <div className="absolute top-1/4 mt-8 left-1/4 -ml-40">
                            <div className="bg-camel w-2 h-2 absolute -left-4 h-12"></div>
                            <span className="font-bold text-xl">Yafees Sarwar</span><br/>
                            <span className="absolute left-0 text-xl">Co-Founders</span>
                        </div>
                        <div className="absolute mr-16" style={{bottom: '10%', right: '34%'}}>
                            <div className="bg-camel w-2 h-2 absolute -left-4 h-12"></div>
                            <span className="font-bold text-xl">Yameen Sarwar</span><br/>
                            <span className="absolute left-0 text-xl">Co-Founders</span>
                        </div>
                	   <img className="w-full rounded-t-xl" src={leadershipImage}/>
                    </div> */}
                </div>
                <div className="text-center z-50">
                	<h3 className="text-2xl 2xl:text-4xl z-50 text-camel text-center font-Poppins font-normal pt-8 md:pt-24 note-small-text">A note from the Co-Founders</h3>
                    <div className="bg-white z-50 inline-block">
                    	<p className="bg-white z-50 text-base 2xl:text-xl my-8 md:my-16 text-left text-black md:px-20 lg:px-44 xl:px-72 2xl:px-96 leading-loose font-Poppins">
                    		Hey Assalaamu Alaykum,<br/><br/>
        					We’re Yafees & Yameen; Co-Founders of CharityMoments based here in New York.<br/><br/>
        					We built this technology platform, at the request of our mom, with a lot of love to help you - our brothers and sisters - celebrate the days of Allah.<br/><br/>
        					For years, on important Islamic events and cherished personal days - we as Muslims had to set reminders on our devices, research the non-profits and causes we wanted to support, take out credit cards, put in the credentials and give charity. Or we scribbled dates on post-it notes and gave cash to our local mosques and youth centers.<br/><br/>
        					This friction caused us to miss some days while delay giving on others. Giving charity on some occasions felt like a chore, rather than a delight. Something you fall behind on. Something you don’t enjoy. <br/><br/>
        					Yet, giving charity is beautiful. It’s a religious mandate that protects us from calamities, erases sins, safeguards against miserliness and helps build communities, uplifts the needy, protects our civil rights and shelters our most vulnerable members.<br/><br/>
        					And it’s an act of worship that keeps on giving in this life and the next. <br/><br/>
        					So the good news is - with CharityMoments, you can now bring back the joy in giving sadaqah without neglecting your revered Islamic and treasured personal days.<br/><br/>
        					Our truthful Prophet ﷺ said, “Charity does not in any way decrease the wealth and the servant who forgives, Allah adds to his respect, and the one who shows humility Allah elevates him in the estimation (of the people).”<br/><br/>
        					CharityMoments is our humble gift to the Ummah. We invite you to join us in celebrating and honoring the Days of Allah. <br/><br/>
        					With Du’a,<br/><br/>
        					Yafees & Yameen<br/><br/>
                    	</p>
                    </div>
                </div>
            </Block>
        </div>
    )
}

const Block = (props:any) => {
    return(
        <div className="bg-white rounded-2xl mx-4 md:mx-8 px-4">
            {props.children}
        </div>
        )
}