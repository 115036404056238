import React, { useEffect, useState, useContext } from "react";
import { FiCheck } from "react-icons/fi";
import axios from "../../utils/axios";
import { Context } from "../../utils/store";
import SkeletonLoader from "../../components/SkeletonLoader";

export default function SelectIslamicDays() {
  const { state, dispatch } = useContext(Context);
  const [monthsData, setmonthsData] = useState<Array<any> | null>(null);
  const [daysData, setDaysData] = useState<Array<any> | null>(null);
  const [groupedDays, setGroupedDays] = useState<Array<any> | null>(null);
  const [notGroupedDays, setNotGroupedDays] = useState<Array<any> | null>(null);
  const [groupedDaysIdsValues, setGroupedDaysIdsValues] =
    useState<Array<any> | null>(null);
  const [, setOtherDays] = useState<Array<any> | null>(null);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    axios
      .get("/islamicmonths")
      .then((response) => {
        let newMonthsData = [...response.data.response];
        newMonthsData.sort((a, b) => {
          return a.monthNumber - b.monthNumber;
        });
        setmonthsData(newMonthsData);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });

    if (
      !state?.firstTimeInfo?.islamicDays &&
      !state?.firstTimeInfo?.otherDays
    ) {
      let islamidDaysResponse: any = null;
      let otherDaysResponse: any = null;

      axios
        .get("/islamicdays")
        .then((response) => {
          islamidDaysResponse = response.data.response;
        })
        .then(() => {
          axios
            .get("/otherdays")
            .then((response) => {
              otherDaysResponse = response.data.response;
            })
            .then(() => {
              let newOtherDays = [...otherDaysResponse];
              newOtherDays.sort((a, b) => {
                return a.order - b.order;
              });
              newOtherDays.filter((otherDay) => {
                otherDay.isSelected = false;
              });
              let newIslamidDays: any[] = [];
              islamidDaysResponse.forEach((oneDay: any) => {
                if (oneDay.isActive) {
                  let newOneDay = { ...oneDay };
                  newOneDay.isIslamicDaySelected = false;
                  newIslamidDays.push(oneDay);
                }
              });
              dispatch({
                type: "firstTimeInfo",
                firstTimeInfo: {
                  ...state.firstTimeInfo,
                  otherDays: newOtherDays,
                  islamicDays: newIslamidDays,
                },
              });
              setDaysData(newIslamidDays);
              setOtherDays(newOtherDays);
              setShowLoader(false);
            });
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });
    } else {
      setDaysData(state?.firstTimeInfo?.islamicDays);
    }

    axios
      .get("/groupdates")
      .then((response) => {
        let groupDates = [...response.data.response];
        let newGroupDates: any[] = [];
        groupDates.forEach((oneGroup) => {
          if (oneGroup.isActive) {
            newGroupDates.push(oneGroup);
          }
        });

        // Push only active days:

        newGroupDates?.forEach((oneGroupDate, index) => {
          let newSelectedDays: any[] = [];

          oneGroupDate.selectedDays.forEach((oneSelectedDay: any) => {
            daysData?.forEach((oneIslamicDay) => {
              if (
                oneSelectedDay === oneIslamicDay._id &&
                oneIslamicDay.isActive
              ) {
                newSelectedDays.push(oneSelectedDay);
              }
            });
          });

          newGroupDates[index].selectedDays = newSelectedDays;
          if (newGroupDates[index].selectedDays.length === 0) {
            newGroupDates.splice(index, 1);
          }
        });
        setGroupedDays(newGroupDates);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  }, [daysData]);

  useEffect(() => {
    // Find grouped daysids
    let groupedDaysIds: Array<any> = [];
    if (groupedDays) {
      groupedDays.forEach((groupedDay: any) => {
        groupedDay.selectedDays.forEach((selectedDay: any) => {
          groupedDaysIds.push(selectedDay);
        });
      });
    }
    setGroupedDaysIdsValues(groupedDaysIds);
    // Find not grouped days
    let result: Array<any> = [];

    if (daysData) {
      let daysDataCopy = [...daysData];
      result = daysDataCopy.filter((el) => {
        let found = false;
        groupedDaysIds.forEach((groupedDayId) => {
          if (groupedDayId === el._id) {
            found = true;
          }
        });
        if (!found) {
          return true;
        } else {
          return false;
        }
      });
    }
    setNotGroupedDays(result);
  }, [0]);

  let monthBlocks = null;
  if (monthsData && daysData && groupedDays) {
    monthBlocks = monthsData.map((monthData, index) => {
      let daysInThisMonth: Array<Object> = [];

      daysData.forEach((day) => {
        if (day.islamicMonthId == monthData._id) {
          daysInThisMonth.push(day);
        }
      });

      groupedDays.forEach((group) => {
        if (group.islamicMonthId == monthData._id) {
          daysInThisMonth.push(group);
        }
      });

      if (monthData.name === "Ramaḍān" || monthData.name === "Dhū al-Ḥijjah") {
        return (
          <div className="lg:col-span-2" key={index}>
            <MonthBlock
              groupedDays={groupedDays}
              monthData={monthData}
              daysData={daysInThisMonth}
              monthName={monthData.name}
            />
          </div>
        );
      } else {
        return (
          <>
            {monthData.isActive === true ? (
              <div className="col-1 bg-lightGrey h-full border-0 rounded-lg mr-0 mb-4">
                <MonthBlock
                  groupedDays={groupedDays}
                  monthData={monthData}
                  daysData={daysInThisMonth}
                  monthName={monthData.name}
                />
              </div>
            ) : (
              <div className="2xl:mb-1 mb-0"></div>
            )}
          </>
        );
      }
    });
  }

  let otherDaysBlock = null;
  if (state?.firstTimeInfo?.otherDays) {
    otherDaysBlock = state.firstTimeInfo.otherDays.map((el: any) => {
      return (
        <div className="pl-2 pr-2 my-2">
          <OtherDayBlock dayData={el} />
        </div>
      );
    });
  }

  if (state.firstTimeInfo.islamicDays && state.firstTimeInfo.otherDays) {
    return (
      <div className="z-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-8 md:mt-10 lg:gap-5 z-50 ">
          <AllSelectedBlock
            groupedDaysIdsValues={groupedDaysIdsValues}
            notGroupedDays={notGroupedDays}
            name="All Islamic Events"
          />
          {/* {state?.firstTimeInfo?.donationFreq == "oneTime" ? (
            <TodayOnlyBlock />
          ) : null} */}
        </div>
        <div
          className={`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-6 md:mt-10 z-50 ${
            state?.firstTimeInfo?.donationFreq !== "oneTime" ? "mb-0" : "mb-6"
          }`}
        >
          {monthBlocks}
        </div>
        {state?.firstTimeInfo?.donationFreq !== "oneTime" ? (
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-6 mb-8 md:mb-4 z-50">
              <div className="col-span-1 md:col-span-3 xl:col-span-2 z-50">
                {/* <div style={{ backgroundImage: `linear-gradient(to right, #D4B458, #121212)`}} */}
                <div
                  style={{ background: "#D4B458" }}
                  className="rounded-lg -p-1 z-20 relative"
                >
                  <p className="px-6 py-2 text-white days-title-text">Sunnah Days</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 bg-lightGrey h-full py-3 px-2 rounded-lg">
                    {otherDaysBlock}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-10 z-50">
        <SkeletonLoader showLoader={showLoader} showShadow={false} />
        <SkeletonLoader showLoader={showLoader} showShadow={false} />
        <SkeletonLoader showLoader={showLoader} showShadow={false} />
      </div>
    );
  }
}

//  BLOCKS:

const MonthBlock = (props: any) => {
  let days = props.daysData;
  let monthData = props.monthData;
  let monthName = props.monthName;

  const longMonths = ["Ramaḍān", "Dhū al-Ḥijjah"];

  let daysBlocks = null;

  if (days) {
    daysBlocks = days.map((day: any) => {
      if (day.hasOwnProperty("selectedDays")) {
        return (
          <div
            className={
              longMonths.includes(monthName)
                ? "border-0 col-span-2 lg:col-span-1 gap-5 bg-lightGrey h-full rounded-lg pl-4 pr-2 mt-4"
                : "border-0 bg-lightGrey h-full rounded-lg px-2"
            }
          >
            <GroupedDaysBlock
              isLongMonth={longMonths.includes(monthName)}
              groupedDaysData={day}
            />
          </div>
        );
      } else if (day.showInCheckout) {
        return (
          <div
            className={
              longMonths.includes(monthName)
                ? "col-span-2 lg:col-span-1 gap-5 bg-lightGrey h-full rounded-lg pl-4 pr-2 mt-4 border-0"
                : "border-0 bg-lightGrey h-full rounded-lg pl-4 pr-2 mt-4"
            }
          >
            <DayBlock
              dayData={day}
              monthId={monthData._id}
              dayOfMonth={day?.dayOfMonth}
              name={day?.name}
            />
          </div>
        );
      }
    });
  }
  return (
    <>
      {monthData.isActive == true ? (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(to right, ${monthData.gradient.boxColor1}, ${monthData.gradient.boxColor2})`,
            }}
            className="border-0 rounded-lg -p-1 z-20 relative"
          >
            <p className="px-6 py-2 text-white days-title-text">{monthData.name}</p>
            <div
              className={
                longMonths.includes(monthName)
                  ? "grid grid-cols-1 lg:grid-cols-2 bg-lightGrey h-full border-0 rounded-lg pb-6"
                  : "grid grid-cols-1 bg-lightGrey h-full border-0 rounded-lg pb-1 md:pb-6"
              }
            >
              {daysBlocks}
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white"></div>
      )}
    </>
  );
};

const DayBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);
  let day = state?.firstTimeInfo?.islamicDays?.find(
    (obj: any) => obj?._id === props?.dayData?._id
  );

  const dayHandler = () => {
    let newIsIslamicDaySelected: any;
    if (day?.isIslamicDaySelected) {
      newIsIslamicDaySelected = false;
    } else {
      newIsIslamicDaySelected = true;
    }

    let newIslamicDays = state?.firstTimeInfo?.islamicDays?.map((obj: any) => {
      if (obj._id === props.dayData._id) {
        let newObj = { ...obj, isIslamicDaySelected: newIsIslamicDaySelected };
        return newObj;
      } else {
        return obj;
      }
    });

    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, islamicDays: newIslamicDays },
    });
  };

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <div
      onClick={dayHandler}
      className={
        day?.isIslamicDaySelected
          ? "cursor-pointer shadow-md h-auto relative bg-lightGreen flex flex-row rounded-lg p-1 py-1 min-h-16"
          : "cursor-default shadow-md h-auto relative bg-white flex flex-row rounded-lg p-1 py-1 min-h-16"
      }
    >
      <div
        className={
          day?.isIslamicDaySelected
            ? "grid justify-center cursor-pointer bg-darkGreen py-4 px-2 rounded-lg"
            : "grid justify-center cursor-default bg-lightGrey py-4 px-2 rounded-lg"
        }
      >
        <p className="cursor-pointer self-center text-center text-black cursor-pointer w-7 text-center text-sm 2xl:text-lg">
          {props.dayOfMonth}
          <span className="cursor-pointer text-center text-black text-xs">
            {nth(props.dayOfMonth)}
          </span>
        </p>
      </div>
      <p className="cursor-pointer font-semibold text-black mt-auto mb-auto pl-5 pr-10 cursor-pointer text-sm 2xl:text-lg">
        {props.name}
      </p>
      {day?.isIslamicDaySelected ? (
        <div className="absolute right-4 top-5 bg-darkGreen pt-2 w-6 h-6 md:ml-3 rounded-full">
          <FiCheck className="absolute top-1 left-1 text-white m-auto w-4 h-4" />
        </div>
      ) : null}
    </div>
  );
};

const GroupedDaysBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);

  const groupedDaysData = props.groupedDaysData;
  const [blockSelected, setBlockSelected] = useState(false);
  const allDays = state?.firstTimeInfo?.islamicDays;

  useEffect(() => {
    let activeDaysSelected = 0;
    let shownDaysSelected = 0;

    for (let i = 0; i < groupedDaysData.selectedDays.length; i++) {
      allDays?.forEach((obj: any) => {
        if (obj._id == groupedDaysData.selectedDays[i]) {
          if (obj.isIslamicDaySelected) {
            activeDaysSelected = activeDaysSelected + 1;
          }
          if (obj.isIslamicDaySelected && obj.showInCheckout) {
            shownDaysSelected = shownDaysSelected + 1;
          }
        }
      });
    }

    let allActiveDays = [];
    let allShownDays = [];
    let allNotShownDays = [];

    groupedDaysData.selectedDays.forEach((oneSelectedDayId) => {
      allDays.forEach((oneIslamicDay) => {
        if (oneIslamicDay._id == oneSelectedDayId) {
          allActiveDays.push(oneIslamicDay);
          if (oneIslamicDay.showInCheckout) {
            allShownDays.push(oneIslamicDay);
          } else if (!oneIslamicDay.showInCheckout) {
            allNotShownDays.push(oneIslamicDay);
          }
        }
      });
    });

    if (activeDaysSelected == allActiveDays.length && !blockSelected) {
      let newIslamicDays = [...allDays];
      allActiveDays.forEach((oneActiveDay) => {
        newIslamicDays.forEach((oneIslamicDay, oneIslamicDayIndex) => {
          if (oneActiveDay._id == oneIslamicDay._id) {
            newIslamicDays[oneIslamicDayIndex] = {
              ...oneIslamicDay,
              isIslamicDaySelected: true,
            };
          }
        });
      });
      dispatch({
        type: "firstTimeInfo",
        firstTimeInfo: { ...state.firstTimeInfo, islamicDays: newIslamicDays },
      });
      setBlockSelected(true);
    } else if (shownDaysSelected < allShownDays.length && blockSelected) {
      let newIslamicDays = [...allDays];
      allNotShownDays.forEach((oneNotShownDay) => {
        newIslamicDays.forEach((oneIslamicDay, oneIslamicDayIndex) => {
          if (
            oneNotShownDay._id == oneIslamicDay._id &&
            oneNotShownDay.isIslamicDaySelected
          ) {
            newIslamicDays[oneIslamicDayIndex] = {
              ...oneIslamicDay,
              isIslamicDaySelected: false,
            };
          }
        });
      });
      dispatch({
        type: "firstTimeInfo",
        firstTimeInfo: { ...state.firstTimeInfo, islamicDays: newIslamicDays },
      });
      setBlockSelected(false);
    }
  }, [props]);

  const daysHandler = () => {
    let newIslamicDays: Array<any> = [];

    let allActiveDays = [];
    let allShownDays = [];

    groupedDaysData.selectedDays.forEach((oneSelectedDayId) => {
      allDays.forEach((oneIslamicDay) => {
        if (oneIslamicDay._id == oneSelectedDayId) {
          allActiveDays.push(oneIslamicDay);
          if (oneIslamicDay.showInCheckout) {
            allShownDays.push(oneIslamicDay);
          }
        }
      });
    });

    newIslamicDays = [...allDays];

    newIslamicDays.forEach((day, index) => {
      allActiveDays.forEach((selectedDay: any) => {
        if (selectedDay._id === day._id) {
          newIslamicDays[index] = {
            ...selectedDay,
            isIslamicDaySelected: !blockSelected,
          };
        }
      });
    });

    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, islamicDays: newIslamicDays },
    });
    setBlockSelected(!blockSelected);
  };

  return (
    <div
      onClick={daysHandler}
      className={
        blockSelected
          ? `shadow-md h-16 relative bg-lightGreen flex flex-row border-0 rounded-lg p-1 ${
              props.isLongMonth ? "mt-0 mb-4" : "mt-4 mb-4"
            }`
          : `shadow-md h-16 relative bg-white flex flex-row border-0 rounded-lg p-1 ${
              props.isLongMonth ? "mt-0" : "mt-4"
            }`
      }
    >
      <p className="font-semibold text-black my-auto pl-16 pr-8 cursor-default text-sm 2xl:text-lg">
        {groupedDaysData.name}
      </p>
      {blockSelected ? (
        <div className="absolute right-4 top-5 bg-darkGreen pt-2 w-6 h-6 ml-3 rounded-full">
          <FiCheck className="absolute top-1 left-1 text-white m-auto w-4 h-4" />
        </div>
      ) : null}
    </div>
  );
};

const OtherDayBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    state?.firstTimeInfo?.otherDays.forEach((obj) => {
      if (obj._id == props.dayData._id && obj.isSelected) {
        setIsActive(true);
      }
    });
  }, []);

  const otherDayHandler = () => {
    setIsActive(!isActive);
    let newOtherDays = [...state?.firstTimeInfo?.otherDays];
    let index = newOtherDays.findIndex((obj) => {
      if (obj._id == props.dayData._id) {
        return true;
      }
    });
    newOtherDays[index].isSelected = !isActive;
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, otherDays: newOtherDays },
    });
  };
  return (
    <div
      className={
        isActive
          ? "shadow-md bg-darkGreen2 pl-1 z-0 h-16 border-0 rounded-lg mb-0 z-50"
          : "shadow-md bg-brownGrey pl-1 z-0 h-16 border-0 rounded-lg mb-0"
      }
    >
      <div
        onClick={otherDayHandler}
        className={
          isActive
            ? "h-16 relative bg-lightGreen flex flex-row border-0 rounded-lg p-1 mb-3 px-4"
            : "h-16 relative bg-white flex flex-row border-0 rounded-lg p-1 mb-3 px-4"
        }
      >
        {isActive ? (
          <div className="absolute left-4 top-5 bg-darkGreen2 pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-white m-auto w-4 h-4" />
          </div>
        ) : (
          <div className="absolute left-4 top-5 bg-darkGrey pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-black m-auto w-4 h-4" />
          </div>
        )}
        <p className="font-semibold text-black mt-auto mb-auto pl-16 pr-8 cursor-default text-sm 2xl:text-lg cursor-default">
          {props.dayData.name}
        </p>
      </div>
    </div>
  );
};

const TodayOnlyBlock = () => {
  const { state, dispatch } = useContext(Context);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(state?.firstTimeInfo?.todayOnly?.isActive);
  }, []);

  const todayOnlyHandler = () => {
    setIsActive(!isActive);
    let newTodayOnly = { ...state?.firstTimeInfo?.todayOnly };
    newTodayOnly.isActive = !isActive;
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, todayOnly: newTodayOnly },
    });
  };

  return (
    <div
      style={{ boxShadow: "0px 1px 10px -1px rgba(0,0,0,0.2)" }}
      className={
        isActive
          ? "cursor-pointer shadow-md bg-darkGreen2 pl-1 z-0 h-16 border-0 rounded-lg mb-4 z-50 z-20 relative"
          : "cursor-pointer shadow-md bg-brownGrey pl-1 z-0 h-16 border-0 rounded-lg mb-4 z-20 relative"
      }
    >
      <div
        onClick={todayOnlyHandler}
        className={
          isActive
            ? "h-16 relative bg-lightGreen flex flex-row border-0 rounded-lg p-1 mb-3"
            : "h-16 relative bg-lightGrey flex flex-row border-0 rounded-lg p-1 mb-3"
        }
      >
        {isActive ? (
          <div className="absolute left-4 top-5 bg-darkGreen2 pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-white m-auto w-4 h-4" />
          </div>
        ) : (
          <div className="absolute left-4 top-5 bg-darkGrey pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-black m-auto w-4 h-4" />
          </div>
        )}
        <p className="font-semibold text-black mt-auto mb-auto pl-16 pr-8 cursor-pointer text-sm 2xl:text-lg cursor-pointer">
          {state?.firstTimeInfo?.todayOnly?.name}
        </p>
      </div>
    </div>
  );
};

const AllSelectedBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);

  const [isAllSelected, setisAllSelected] = useState(false);
  let islamicDays = state?.firstTimeInfo?.islamicDays;

  // Check if all dates are selected
  useEffect(() => {
    if (props.notGroupedDays) {
      let howMuchIsActve = 0;
      props.notGroupedDays.forEach((el: any) => {
        if (el.isIslamicDaySelected) {
          howMuchIsActve++;
        }
      });
      if (howMuchIsActve == props.notGroupedDays.length && !isAllSelected) {
        setisAllSelected(true);
      }
    }
  }, []);

  useEffect(() => {
    let howMuchIsActve = 0;
    let howMuchIsGrouped = 0;

    let groupedDays = state?.firstTimeInfo?.groupedDays;

    let groupedDaysIds: Array<any> = [];
    if (groupedDays) {
      groupedDays.forEach((groupedDay: any) => {
        groupedDay.selectedDays.forEach((selectedDay: any) => {
          groupedDaysIds.push(selectedDay);
        });
      });
    }

    let notGroupedDays = islamicDays?.filter((el) => {
      let found = false;
      groupedDaysIds.forEach((groupedDayId) => {
        if (groupedDayId === el._id) {
          found = true;
        }
      });
      if (!found) {
        return true;
      } else {
        return false;
      }
    });

    if (groupedDays) {
      groupedDays.forEach((groupedDay: any) => {
        groupedDay.selectedDays.forEach((selectedDay: any) => {
          groupedDaysIds.push(selectedDay);
        });
      });
    }

    // howMuchIsGrouped:
    islamicDays?.forEach((obj: any) => {
      let found = false;
      notGroupedDays?.forEach((notGroupedDay: any) => {
        if (obj._id == notGroupedDay._id) {
          found = true;
        }
      });
      if (found) {
        howMuchIsGrouped = howMuchIsGrouped + 1;
      }
    });

    // howMuchIsActve:
    islamicDays?.forEach((obj: any) => {
      let found = false;
      notGroupedDays?.forEach((notGroupedDay: any) => {
        if (obj._id == notGroupedDay._id) {
          found = true;
        }
      });
      if (found && !obj.isIslamicDaySelected) {
        setisAllSelected(false);
        return false;
      } else if (found) {
        if (obj.isIslamicDaySelected) {
          howMuchIsActve = howMuchIsActve + 1;
        }
      }
    });

    if (howMuchIsActve === howMuchIsGrouped) {
      setisAllSelected(true);
    }
  }, [state.firstTimeInfo]);

  const isAllSelectedHandler = () => {
    let newIslamicDays;
    if (islamicDays) {
      newIslamicDays = islamicDays?.map((obj: any) => {
        let found = false;
        props?.groupedDaysIdsValues?.forEach((id: any) => {
          if (obj._id == id && isAllSelected) {
            found = true;
          }
        });
        if (!found) {
          let newObj = { ...obj };
          newObj.isIslamicDaySelected = !isAllSelected;
          return newObj;
        } else {
          return obj;
        }
      });
    }

    setisAllSelected(!isAllSelected);
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, islamicDays: newIslamicDays },
    });
  };

  return (
    <div
      style={{ boxShadow: "0px 1px 10px -1px rgba(0,0,0,0.2)" }}
      className={
        isAllSelected
          ? "cursor-pointer   bg-darkGreen pl-1 h-16 border-0 rounded-lg mb-4 z-20 relative"
          : "cursor-pointer  bg-brownGrey pl-1 h-16 border-0 rounded-lg mb-4 z-20 relative"
      }
    >
      <div
        onClick={isAllSelectedHandler}
        className={
          isAllSelected
            ? "h-16 relative bg-lightGreen flex flex-row border-0 rounded-lg p-1 mb-3"
            : "h-16 relative bg-lightGrey flex flex-row border-0 rounded-lg p-1 mb-3"
        }
      >
        {isAllSelected ? (
          <div className="absolute left-0 top-5 bg-darkGreen pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-white m-auto w-4 h-4" />
          </div>
        ) : (
          <div className="absolute left-0 top-5 bg-darkGrey pt-2 w-6 h-6 ml-3 rounded-full">
            <FiCheck className="absolute top-1 left-1 text-black m-auto w-4 h-4" />
          </div>
        )}
        <p className="font-semibold text-black mt-auto mb-auto pl-12 pr-8 cursor-pointer text-sm 2xl:text-lg cursor-pointer">
          {props.name}
        </p>
      </div>
    </div>
  );
};
