import React, { useState } from "react";

import { AiOutlineInfoCircle, AiFillInfoCircle } from "react-icons/ai";
import { BiXCircle } from "react-icons/bi";


const InfoCircle = (props:any) => {
	const [isHovered, setIsHovered] = useState(false)

	let icon = null;

	isHovered ? icon = <AiFillInfoCircle onClick={()=>props.toggleInfoText(!props.isInfoTextShown)} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)} className="cursor-pointer" color="#7e7e7e" size="25" />
	: icon = <AiOutlineInfoCircle onClick={()=>props.toggleInfoText(!props.isInfoTextShown)} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)} className="cursor-pointer" color="#7e7e7e" size="25" />
	
	if(props.isInfoTextShown){
		icon = <BiXCircle onClick={()=>props.toggleInfoText(!props.isInfoTextShown)} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)} className="text-camel cursor-pointer" size="25"/>
	}
	return (
		<>
		{icon}
		</>
		)
}

export default InfoCircle;