import * as React from "react";
import CreditCardInput from "react-credit-card-input";
import axios from "../../utils/axios";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { useContext } from "react";
import { Context } from "../../utils/store";
export interface CreatePaymentMethodProps {
  setCardStatus: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      type: string;
    }>
  >;
  addNewCard: Function;
}

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 5000,
});
const CreatePaymentMethod: React.SFC<CreatePaymentMethodProps> = ({
  setCardStatus,
  addNewCard,
}) => {
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardExpiry, setCardExpiry] = React.useState("");
  const [cardCVC, setCardCVC] = React.useState("");
  const [cookies, setCookie] = useCookies();
  const { state, dispatch } = useContext(Context);
  // create card token
  const creataCardToken = async () => {
    console.log(cardExpiry.substring(5, 7));
    const params = new URLSearchParams();
    params.append("card[number]", cardNumber);
    params.append("card[exp_month]", cardExpiry.substring(0, 2));
    params.append("card[exp_year]", cardExpiry.substring(5, 7));
    params.append("card[cvc]", cardCVC);
    params.append("card[number]", cardNumber);
    params.append(
      "card[name]",
      state.userInfo?.firstName + " " + state.userInfo?.lastName
    );
    params.append("card[address_line1]", state.userInfo?.address);
    params.append("card[address_city]", state.userInfo?.city);
    params.append("card[address_state]", state.userInfo?.stateValue);
    params.append("card[address_zip]", state.userInfo?.zipCode);
    params.append("card[address_country]", state.userInfo?.country);

    return new Promise<String>(async (resolve, reject) => {
      await axios({
        method: "post",
        url: "https://api.stripe.com/v1/tokens",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      })
        .then(async (response: any) => {
          console.log(response.data.id);
          // setCookie("tokenId", response.data.id);
          resolve(response.data.id);
        })
        .catch((err) => {
          console.log(err.response.data.error.message)
          Toast.fire({
            title: err.response.data.error.message,
            icon: "error",
          });
          reject(err);
        });
    });
  };
  const addCard = (token: String) => {
    return new Promise<{ data: { response: any } }>((resolve, reject) => {
      axios({
        method: "POST",
        url: "/paymentmethod/create",
        headers: {
          Authorization: `Bearer ${cookies.tokenId}`,
        },
        data: { token },
      })
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  };
  const createPaymentMethod = async () => {
    if (!cardNumber || !cardExpiry || !cardCVC)
      return Toast.fire({ title: "Please enter card details.", icon: "error" });

    try {
      const token = await creataCardToken();
      const card = await addCard(token);
      console.log("i am card", card);
      addNewCard({ card: card.data.response });
      Toast.fire({ title: "Card is added successfully !", icon: "success" });
    } catch {
      Toast.fire({ title: "Card was not added! Try again", icon: "error" });
    }
  };
  return (
    <div className="relative grid grid-cols-1 xl:grid-cols-3 gap-4">
      <CreditCardInput
        // onError={err => {
        // 	let newFormErrors = { ...formErrors };
        // 	newFormErrors.cardError = err.error ? err.error : null;
        // 	setFormErrors(newFormErrors);
        // }}

        cardNumberInputProps={{
          value: cardNumber,
          onChange: (event) => setCardNumber(event.target.value),
        }}
        cardExpiryInputProps={{
          value: cardExpiry,
          onChange: (event) => setCardExpiry(event.target.value),
        }}
        cardCVCInputProps={{
          value: cardCVC,
          onChange: (event) => setCardCVC(event.target.value),
        }}
        fieldStyle={{
          width: "100%",
          float: "right",
          padding: "1rem",
          backgroundColor: "#fafafa",
          borderRadius: 2,
          borderWidth: 1,
          zIndex: 11,
        }}
        inputStyle={{ backgroundColor: "#fafafa", fontSize: 14 }}
        dangerTextStyle={{ display: "none" }}
        containerStyle={{
          border: "1px solid #e5e7eb",
          borderRadius: "0.5rem",
        }}
      />
      <div className="col-span-1">
        <Button
          style={
            "items-center justify-center px-24 py-3 relative flex flex-1 content-center ml-0 text-xs shadow-button md:text-base text-Poppins hover:opacity-90 relative z-10"
          }
          action={createPaymentMethod}
          title="Submit"
        />
      </div>
    </div>
  );
};

export default CreatePaymentMethod;
