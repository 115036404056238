import React, { useState, useEffect, useReducer } from "react";
import { reducer, initialState, Context } from "../utils/store";
import sacred from "../assets/days_dates/sacred.png";
import muharram from "../assets/days_dates/muharram.png";
import rabi from "../assets/days_dates/rabi.png";
import rajab from "../assets/days_dates/rajab.png";
import shaban from "../assets/days_dates/shabaan.png";
import ramadan from "../assets/days_dates/ramadan.png";
import days_bg from "../assets/days_dates/days_bg.png";
import sha from "../assets/days_dates/sha.png";
import dhu from "../assets/days_dates/dhu.png";
import { useHistory } from "react-router";
import DaysDatesDetails from "./DaysDatesDetails";
import {Helmet} from "react-helmet";
import  { Mixpanel }  from '../Mixpanel';
export default function DaysDates() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showResults, setShowResults] = React.useState(false);
  let history = useHistory();
  Mixpanel.track('View Page Days&Dates');
  return (
    <div>
      <Helmet>
          <meta name="description" content="Learn about the blessed Islamic months and the sacred events that took place in them." />
          <title>Days & Dates | CharityMoments</title>
      </Helmet>
      <img
        className="hidden md:flex overflow-hidden absolute md:-top-48 2xl:-top-56 w-full z-10"
        src={days_bg}
        alt="dates"
      />

      <div className="bg-white mx-4 md:mx-8 pb-10 rounded-3xl relative px-2 sm:px-10 md:px-12 lg:px-2 2xl:px-32 z-auto">
        <h1 className="text-center text-2xl md:text-4xl 2xl:text-8xl mb-6 md:mb-9 pt-6 md:pt-24 page-title-text">
          Days & Dates
        </h1>
        <p className="text-center font-PoppinsMedium text-black text-base md:text-xl 2xl:text-2xl mb-8 md:mb-14">
          Learn more about the sacred events during the blessed months.
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 lg:gap-x-4 gap-y-10">
          <Block
            title="Muharram"
            image={muharram}
            sacred={true}
            buttonStyle={{
              backgroundImage: `linear-gradient( #ed2026, #f16663)`,
            }}
          />
          <Block
            title="Rabī‘ al-Awwal"
            image={rabi}
            buttonStyle={{
              backgroundImage: `linear-gradient( #0ba25d, #39b34a)`,
            }}
          />
          <Block
            title="Rajab"
            image={rajab}
            sacred={true}
            buttonStyle={{
              backgroundImage: `linear-gradient( #f15c2a, #f79232)`,
            }}
          />
          <Block
            title="Sha‘bān"
            image={shaban}
            buttonStyle={{
              backgroundImage: `linear-gradient( #fba73f, #ecc918)`,
            }}
          />
        </div>
        <div className="grid grid-cols-1 gap-y-10 mt-10 z-30">
          <div className="relative rounded-3xl grid grid-cols-2 mx-3 md:mx-0 h-40 md:h-80 2xl:h-96 shadow-fullCard md:mx-5 z-30">
            <div className="ml-4 md:ml-10 lg:ml-8 grid">
              <p className="text-black text-start text-base sm:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl mt-8 font-MontserratSemiBold">
                Ramaḍān
              </p>
              <button
                onClick={() => {
                  history.push(`/days-and-dates/Ramaḍān`);
                }}
                style={{
                  backgroundImage: `linear-gradient( #293c94, #23a6df)`,
                }}
                className="w-28 md:w-48 text-white px-2 md:px-4 2xl:px-10 mt-auto py-2 md:py-3 mb-5 focus:outline-none rounded-lg text-xs md:text-sm 2xl:text-xl"
              >
                View Dates
              </button>
            </div>
            <img
              className="absolute mt-6 right-4 2xl:right-12 h-28 md:h-64 2xl:h-80"
              src={ramadan}
            />
            {/* <img className='lg:mb-auto lg:ml-auto lg:mr-10 ' src={sacred} /> */}
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-10 z-30">
            <Block
              title="Shawwāl"
              image={sha}
              buttonStyle={{
                backgroundImage: `linear-gradient( #672e92, #8863b7)`,
              }}
            />

            <Block
              title="Dhū al-Ḥijjah"
              image={dhu}
              sacred={true}
              buttonStyle={{
                backgroundImage: `linear-gradient( #0b7a85, #17aebd)`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Block = (props: any) => {
  let history = useHistory();
  const navigate = () => {
    history.push(`/days-and-dates/${props.title}`);
  };
  return (
    <>
      <div className="rounded-3xl grid grid-cols-2 mx-3 md:mx-0 relative shadow-fullCard h-40 sm:h-56 md:h-80 2xl:h-96 z-30 md:mx-5 overflow-hidden">
        <div className="ml-4 md:ml-10 lg:ml-8 grid">
          <p className="text-black text-start text-base sm:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl mt-8 font-MontserratSemiBold">
            {props.title}
          </p>
          <button
            onClick={navigate}
            style={props.buttonStyle}
            className="w-28 md:w-48 text-white px-2 md:px-4 2xl:px-10 mt-auto py-2 md:py-3 mb-5 focus:outline-none rounded-lg text-xs md:text-sm 2xl:text-xl"
          >
            View Dates
          </button>
        </div>
        <img
          className={`absolute mt-4 md:mt-6 xl:mt-6 2xl:mt-10  ${
            props.title === "Rajab" ? "h-28 sm:h-48 md:h-56 2xl:h-64" : "w-24 sm:w-40 md:w-56 2xl:w-64"
          } ${props.sacred ? "right-8 2xl:right-20 sm:right-12 md:right-12" : "right-4 2xl:right-8"}`}
          src={props.image}
          alt="date"
        />
        {props.sacred == true ? (
          <img
            className="absolute 2xl:h-full 2xl:right-10 md:right-5 md:h-80 h-full sm:right-5 right-2"
            src={sacred}
          />
        ) : null}
      </div>
    </>
  );
};
