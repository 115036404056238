/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import { InputDefault } from "../components/Input";
import logo from "../assets/logo.svg";
import { BiHide, BiShow } from "react-icons/bi";
import logos from "../assets/icons/login_logos2.png";
import bg from "../assets/login_bg.png";
import axiosUtility from "../utils/axios";
import { FiCheck } from "react-icons/fi";
import { Context } from "../utils/store";
import axios from "../utils/axios";
import { useCookies } from "react-cookie";
import Hamburger from "hamburger-react";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
export default function Login() {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const [charity, setCharity] = React.useState([]);
  // console.log(charity)
  useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      let newCharities = [];
      response.data.charities.forEach((oneCharity) => {
        if (oneCharity.isActive) {
          newCharities.push(oneCharity);
        }
      });
      setCharity(newCharities);
    });
  }, [0]);  
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(null);
  const [verifyCode, setVerifyCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [changedPassword, setChangedPassword] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);

  const [isCodeInputShown, setIsCodeInputShown] = useState(false);
  const [mfaCode, setMfaCode] = useState(null);
  const [sessionValue, setSessionValue] = useState(null);
  const [userName, setUserName] = useState(null);
  const [emailValidation, setEmailValidation] = useState(true);
  const { state, dispatch } = useContext(Context);
  const [cookies, setCookie] = useCookies();

  const history = useHistory();

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const cookieHandler = (name, value) => {
    setCookie(name, value);
  };

  useEffect(() => {
    Mixpanel.track("View Login Page");
    setErrorMsg(null);
  }, [forgotPasswordForm]);

  useEffect(() => {
    setRememberMe(false);
    cookieHandler("autoLogin", false);
  }, []);

  const rememberMeHandler = () => {
    setRememberMe(!rememberMe);
    // cookieHandler("autoLogin", !rememberMe);
  };

  const resetPasswordHandler = async () => {
    setLoading(true);
    if (email == "") {
      setLoading(false);
      setErrorMsg("Enter Email");
      return false;
    }

    let isEmailExist = false;
    await axios({
      method: "POST",
      url: "/checkemail",
      data: { email },
    })
      .then((response) => {
        isEmailExist = true;
        console.log(response.data.response);
      })
      .catch((err) => {
        isEmailExist = false;
        setLoading(false);
        setErrorMsg("Email does not exist");
      });

    if (isEmailExist) {
      Mixpanel.track("Submit Forget Password");
      await axios({
        method: "post",
        url: "/forgot-password",
        data: { email },
      })
        .then((response) => {
          setLoading(false);
          setSuccessMsg(
            "An email has been sent to the supplied email address. Follow the instructions in the email to reset your password."
          );
          setErrorMsg(null);
          console.log(response.data.response);
        })
        .catch((err) => {
          console.log({ err });
          setLoading(false);
          setErrorMsg("Email does not exist");
        });
    }
  };

  const changePassword = async () => {
    setLoading(true);
    if (!verifyCode || !newPassword) {
      setErrorMsg("Please fill in all fields");
      return false;
    }
    await axios({
      method: "post",
      url: "/verify-password",
      data: {
        verifyCode,
        newPassword,
        email,
      },
    })
      .then((response) => {
        Mixpanel.track("Reset Password");
        Toast.fire({
          title: response.data.response,
          icon: "success",
        });
        setForgotPasswordForm(null);
        setSuccessMsg(null);
        setEmail("");
        setLoading(false);
      })
      .catch((err: any) => {
        let errMsg = err.response.data.response.message;
        if (err.response.data.response.code === "InvalidParameterException") {
          console.log(errMsg.indexOf(":") + 1);
          errMsg = errMsg.substring(errMsg.lastIndexOf(":") + 1);
          setErrorMsg(errMsg);
        } else {
          setErrorMsg(errMsg);
        }
        setLoading(false);
      });
  };

  const validate = () => {
    const emailRgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Email
    if (email === "" || password === "") {
      setErrorMsg("The email or password is incorrect");
      return false;
    }
    if (!emailRgx.test(email)) {
      setErrorMsg("Email is incorrect");
      return false;
    }

    return true;
  };

  const logIn = async () => {
    Mixpanel.track("Submit Login");
    setLoading(true);
    if (validate()) {
      const userData = {
        email,
        password,
      };
      await axios({
        method: "post",
        url: "/login",
        data: userData,
      })
        .then(async (response: any) => {
          setLoading(false);
          console.log("user log in: ", response.data);
          const responseCode = response.data.response.responseCode;
          const responseSession = response.data.response.session;
          const responseUserName = response.data.response.userName;

          if (responseCode === "SMSSENT") {
            setIsCodeInputShown(true);
            setSessionValue(responseSession);
            setUserName(responseUserName);
          }

          const responseMessage = response?.data?.message;

          if (responseCode !== "SMSSENT" && responseMessage === "Success") {
            Mixpanel.track("Login successful");
            Mixpanel.identify(email);
            Mixpanel.people.set({
              $first_name: responseUserName,
              $name: responseUserName,
              $email: email,
              phone: "",
              type: "customer",
            });
            cookieHandler("id", response.data.response.id);
            cookieHandler("tokenId", response.data.response.idToken);
            cookieHandler("refreshToken", response.data.response.refreshToken);
            cookieHandler("cognitoUsername", response.data.response.userName);
            dispatch({ type: "isLoggedIn", isLoggedIn: true });
            history.push("profile");
          }
          // // console.log("cut:" + response.data.response.cookie.substr(11).split(";")[0])
          // if (response.data.message === "Success") {
          //   // cookieHandler("cookie", response.data.response.cookie.substr(11).split(";")[0])
          //   cookieHandler("id", response.data.response.id);
          //   cookieHandler("tokenId", response.data.response.idToken);
          //   cookieHandler("refreshToken", response.data.response.refreshToken);

          //   dispatch({
          //     type: "isLoggedIn",
          //     isLoggedIn: true,
          //   });
          //   history.push("profile");
          else if (response.data.message === "CHANGEPASSWORD") {
            Mixpanel.track("Login successful");
            setShowChangePwd(true);
            setErrorMsg("You have to change your password");
            setUserAttributes(response.data.data);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.response?.code === "UserNotConfirmedException"
          ) {
            Mixpanel.track("Login Failed");
            setEmailValidation(false);
            setLoading(false);
          } else {
            Mixpanel.track("Login Failed");
            console.log(err);
            setLoading(false);
            setErrorMsg(err?.response?.data?.response?.message);
          }
        });
    } else {
      Mixpanel.track("Login Failed");
      setLoading(false);
    }
  };

  const verifyLogIn = async () => {
    setLoading(true);
    const data = {
      email,
      username: userName,
      sessionValue,
      mfaCode: +mfaCode,
    };
    await axios({
      method: "post",
      url: "/verifyuserlogin",
      data,
    })
      .then(async (response: any) => {
        setLoading(false);
        if (response.data.message === "Success") {
          cookieHandler("id", response.data.response.id);
          cookieHandler("tokenId", response.data.response.idToken);
          cookieHandler("refreshToken", response.data.response.refreshToken);
          dispatch({ type: "isLoggedIn", isLoggedIn: true });
          history.push("profile");
        }
        if (response.data.message === "Error") {
          Toast.fire({
            title: response.data.response.message
              ? response.data.response.message
              : "Invalid code or auth state for the user.",
            icon: "error",
          });
        }
        setIsCodeInputShown(false);
      })
      .catch((err: any) => {
        console.log(err.response);
        Toast.fire({
          title: "Invalid verification code",
          icon: "error",
        });
        setIsCodeInputShown(false);
        setLoading(false);
      });
  };

  const changeNewPwd = async () => {
    const userData = {
      email: email,
      sessionUserAttributes: userAttributes,
      newPassword: changedPassword,
    };
    console.log(userData);
    await axios({
      method: "post",
      url: "/handle-password",
      data: userData,
    })
      .then(async (response: any) => {
        console.log(response.data);
      })
      .catch((err: any) => {
        console.log(err.response);
        // setLoading(false);
        // setErrorMsg(err.response.data.response.message);
      });
  };
  const [isMobileMenuHidden, setIsMobileMenuHidden] = React.useState(true);
  const [subAboutMenu, setSubAboutMenu] = React.useState(false);
  const [subLearnMenu, setSubLearnMenu] = React.useState(false);

  const verifyEmail = async () => {
    Mixpanel.track("Confirmation email sent successfully");
    setLoading(true);
    await axios({
      method: "post",
      url: "/resend-confirmation-email",
      data: { email },
    })
      .then((response) => {
        Toast.fire({
          title: response.data.response,
          icon: "success",
        });
        setEmailValidation(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setEmailValidation(true);
        if (err?.response?.data?.msg?.message) {
          Toast.fire({
            title: err.response.data.msg.message,
            icon: "error",
          });
        }
      });
  };

  // console.log(cookies)
  return (
    <>
      <Helmet>
        <title>Log In | CharityMoments</title>
        <meta
          name="description"
          content="Access your CharityMoments account."
        />
      </Helmet>
      <img
        className="hidden 2xl:flex overscroll-x-none overscroll-y-none  absolute h-screen w-full z-10  "
        src={bg}
        alt="bgimage"
      />
      <nav className="lg:hidden flex-wrap w-full bg-teal-500 py-3 px-4 z-50 -mb-16">
        <div className="flex-1 items-center flex flex-row">
          <a className="relative z-50" href="/">
            <img className="h-12 z-50 inline-block" src={logo} alt="Logo" />
          </a>
          <div className="inline-block z-50 ml-auto">
            <Hamburger
              distance="lg"
              hideOutline={true}
              onToggle={() => setIsMobileMenuHidden(!isMobileMenuHidden)}
              size={15}
              rounded
              color="#d0a342"
            />
          </div>
        </div>

        <div className={isMobileMenuHidden ? "hidden" : "w-full block"}>
          <div className="text-sm inline-block w-full text-center">
            <a
              onClick={() => setSubAboutMenu(!subAboutMenu)}
              href="#responsive-header"
              className="inline-block mx-auto mt-4 "
            >
              About
              <svg
                className="inline-block ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            {subAboutMenu ? (
              <div className="mx-auto">
                <a href="/leadership" className="block mt-4 text-gray-500">
                  Leadership
                </a>
                <a
                  href="/shariah-compliance"
                  className="block mt-4 text-gray-500"
                >
                  Shariah Compliance
                </a>
              </div>
            ) : null}
            <a href="../#howitworks" className="block mt-4">
              How it works
            </a>
            <a
              onClick={() => {
                setSubLearnMenu(!subLearnMenu);
              }}
              href="#responsive-header"
              className="block mt-4"
            >
              Learn
              <svg
                className="inline-block ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            {subLearnMenu ? (
              <div className="mx-auto">
                <a href="/days-and-dates" className="block mt-4 text-gray-600">
                  Days & Dates
                </a>
                <a
                  href="/frequently-asked-questions"
                  className="block mt-4 text-gray-600"
                >
                  FAQs
                </a>
                <a
                  href="/charity-partners"
                  className="block mt-4 text-gray-600"
                >
                  Charity Partners
                </a>
                <a href="/learning-center" className="block mt-4 text-gray-600">
                  Learning Center
                </a>
              </div>
            ) : null}
          </div>

          <div className="text-center">
            <a
              href="/login"
              className="inline-block whitespace-nowrap text-base font-PoppinsMedium font-medium text-black hover:text-gray-500 mt-5 border border-black rounded pt-2 pb-2 px-5"
            >
              Log In
            </a>
            <br />
            <a
              href="/donation-automation"
              style={{ backgroundColor: "#d0a342" }}
              className="inline-block whitespace-nowrap font-PoppinsMedium items-center justify-center px-4 py-2 mt-5 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:opacity-90 relative z-50"
            >
              Get Started
            </a>
          </div>
        </div>
      </nav>

      <div
        // style={{backgroundImage:`url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}
        className="grid lg:grid-cols-2 md:grid-cols-1 p-5 gap-x-2 relative lg:gap-x-10 "
      >
        <div className="bg-white h-screen/2 lg:grid hidden  2xl:-mr-12 rounded-3xl 2xl:px-20 pt-14  relative px-4  2xl:pr-12 2xl:mt-4 ">
          <a className="relative z-30 " href="/">
            <img
              src={logo}
              className="relative z-20 md:h-16 -mb-12 2xl:mb-0 2xl:h-20"
            />
          </a>
          <p
            style={{ marginTop: 78, lineHeight: 1.3 }}
            className="2xl:text-5xl md:text-2xl text-xl text-black font-Montserrat"
          >
            Donate. <span className="text-camel">Automate.</span>
            <br /> On Your Most Important Days.
          </p>
          <p
            style={{ marginTop: 25, lineHeight: 2 }}
            className="2xl:text-base text-sm  text-grey2"
          >
            "And spend [in the way of Allah] from what We have provided you
            before death approaches one of you and he says, "My Lord, if only
            You would delay me for a brief term so I would give charity and be
            among the righteous." But never will Allah delay a soul when its
            time has come. And Allah is Acquainted with what you do."
          </p>
          <br />
          <p className="text-camel 2xl:text-base text-sm">
            The Noble Qur'an | Surah 63: 10-11
          </p>
          <p className="2xl:text-sm text-xs xl:mt-20 2xl:mt-36 mb-8 text-grey2">
            Trusted by some of America's most popular charities.
          </p>
          <div className="flex flex-row">
            {charity?.map((image, index) => {
              return (
                <div className="md:w-28 h-auto mr-4  my-auto" key={index}>
                  <img className="-mt-5 mb-3" src={image.logo} />
                </div>
              );
            })}
          </div>
          {/* <img className="mb-8 px-8 -ml-8" src={logos} /> */}
        </div>
        <div className="w-full h-screen/2 2xl:pl-20 2xl:-mt-40">
          <p
            style={{ lineHeight: 1.4 }}
            className="text-black font-Montserrat 2xl:text-5xl mt-24 2xl:mt-72 md:text-2xl text-xl"
          >
            Welcome back.
            <br />
            <span className="text-camel">Login to my account.</span>
          </p>
          <p
            style={{ display: !emailValidation ? "none" : null }}
            className="text-black flex flex-row 2xl:text-xl md:text-base text-sm mt-6 mb-16 font-PoppinsMedium"
          >
            <span className="w-10 h-0.5 bg-camel mt-auto mb-auto mr-2  "></span>
            New here?
            <a
              href="/donation-automation"
              className="text-camel ml-2 hover:opacity-80 z-20"
              disable="disable"
            >
              Get started.
            </a>
          </p>
          <p
            style={{ display: !emailValidation ? null : "none" }}
            className="text-black flex 2xl:text-xl md:text-base text-sm mt-6 mb-16 font-PoppinsMedium"
          >
            Your email is not verified. Please
            <a
              onClick={verifyEmail}
              href="javascript:;"
              className="text-camel mx-1 hover:opacity-80 z-20"
              style={{
                color: loading ? "#b4b4b4" : "",
                pointerEvents: loading ? "none" : "",
              }}
            >
              click here
            </a>
            to reverify the email.
          </p>
          <div className=" lg:max-w-535">
            {forgotPasswordForm ? (
              <div>
                <h2 className="text-lg text-center">Reset Password</h2>
                {successMsg ? (
                  <div>
                    <p className="text-xl text-center mt-2">{successMsg}</p>
                    <InputDefault
                      type="text"
                      onChange={(event: any) =>
                        setVerifyCode(event.target.value)
                      }
                      title="Code"
                      placeholder="Enter your code..."
                    />
                    <InputDefault
                      type="password"
                      onChange={(event: any) =>
                        setNewPassword(event.target.value)
                      }
                      title="New Password"
                      placeholder="Enter your New Password..."
                    />
                  </div>
                ) : (
                  <InputDefault
                    type="text"
                    onChange={(event: any) => setEmail(event.target.value?.toLowerCase())}
                    value={email}
                    title="Email"
                    placeholder="Enter your email here..."
                  />
                )}
              </div>
            ) : (
              <div>
                <InputDefault
                  type="text"
                  onChange={(event: any) => setEmail(event.target.value?.toLowerCase())}
                  title="Email"
                  placeholder="Enter your email here..."
                />
                <InputDefault
                  type={passwordShown ? "text" : "password"}
                  onChange={(event: any) => setPassword(event.target.value)}
                  title="Password"
                  icon={passwordShown ? BiShow : BiHide}
                  onClick={togglePasswordVisibility}
                  style="text-gray-400 w-9 h-5 mt-8 mx-2"
                  placeholder="Enter your password here..."
                />
                {isCodeInputShown ? (
                  <InputDefault
                    onChange={(event: any) => setMfaCode(event.target.value)}
                    type="text"
                    title="SMS verification code"
                    placeholder="Enter your code"
                  />
                ) : null}
              </div>
            )}
            <div className="rounded-md flex-grow mr-auto z-20 h-full relative">
              {errorMsg ? (
                <span className=" text-errorColor">{errorMsg}</span>
              ) : null}
              {showChangePwd && (
                <InputDefault
                  type="text"
                  onChange={(event: any) =>
                    setChangedPassword(event.target.value)
                  }
                  title="New password"
                  placeholder="Enter your new password here..."
                />
              )}
              <div className="flex flex-row w-full justify-between mb-12 pt-3 z-20 relative">
                <div
                  className="inline-flex items-center hover:opacity-80"
                  onClick={rememberMeHandler}
                  style={{ cursor: "pointer" }}
                >
                  {forgotPasswordForm ? null : (
                    <>
                      <input
                        className={`form-checkbox h-5 w-5 ${
                          rememberMe
                            ? "bg-grey-500 focus:outline-none"
                            : "bg-white"
                        } rounded rounded-md border-2 border-grey-500 z-20 relative focus:outline-none`}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                      {rememberMe ? (
                        <FiCheck
                          style={{ marginLeft: -18, marginRight: 2 }}
                          className="text-black z-20"
                        />
                      ) : null}
                      <span className="ml-2 text-gray-700 2xl:pt-0 pt-0.5 2xl:text-base text-sm">
                        Remember me
                      </span>
                    </>
                  )}
                </div>
                {forgotPasswordForm ? (
                  <a
                    href="#"
                    onClick={() => setForgotPasswordForm(false)}
                    className="text-skyBlue hover:opacity-80 text-sm 2xl:text-base z-20 "
                  >
                    Log in
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={() => setForgotPasswordForm(true)}
                    className="text-skyBlue hover:opacity-80 text-sm 2xl:text-base z-20 mt-1"
                  >
                    Forgot password?
                  </a>
                )}
              </div>
              {forgotPasswordForm ? (
                <Button
                  loading={loading}
                  action={successMsg ? changePassword : resetPasswordHandler}
                  style={`${
                    isCodeInputShown ? "hidden" : "block"
                  } "shadow-button py-3 z-20 relative hover:opacity-80 mb-12"`}
                  width="full"
                  title={successMsg ? "Change Password" : "Reset Password"}
                />
              ) : (
                <Button
                  loading={loading}
                  action={showChangePwd ? changeNewPwd : logIn}
                  style={`${
                    isCodeInputShown ? "hidden" : "block"
                  } "shadow-button py-3 z-20 relative hover:opacity-80 mb-12"`}
                  width="full"
                  title={showChangePwd ? "Setup new password" : "Log In"}
                />
              )}
              {isCodeInputShown ? (
                <Button
                  action={isCodeInputShown ? verifyLogIn : logIn}
                  style="shadow-button py-3 z-20 relative hover:opacity-80 mb-12"
                  width="full"
                  title={isCodeInputShown ? "Verify Code & Login" : "Log In"}
                />
              ) : null}
              <p className="text-center 2xl:text-base text-sm z-20 mt-8 xl:mt-28 2xl:mt-56">
                <a
                  href="/privacy-policy"
                  className="hover:opacity-80 mr-3"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy policy
                </a>
                |
                <a
                  href="/terms-and-conditions"
                  className="hover:opacity-80 ml-3"
                  style={{ textDecoration: "underline" }}
                >
                  Terms and Conditions
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
