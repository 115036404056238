import React, { useState, useEffect, useReducer } from "react";
import { reducer, initialState, Context } from "../utils/store";
import black from "../assets/landing/black.svg";
import gold_snow from "../assets/landing/snow_gold.svg";
import LearningCenterDetails from "./LearningCenterDetails";
import { AiOutlineSearch } from "react-icons/ai";
import axiosUtility from "../utils/axios";
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import  { Mixpanel }  from '../Mixpanel';
export default function LearningCenter(props: any) {
  const [articles, setArticles] = useState<Array<any> | null>(null);
  const [firstArticle, setFirstArticle] = useState<any>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showResults, setShowResults] = React.useState(false);
  let history = useHistory();
  useEffect(() => {
    Mixpanel.track('View Learning Center Page');
    axiosUtility
      .get(`/learning-center`)
      .then((response) => {
        let responseArticles = [...response.data.articles]
        let publishedArticles = responseArticles.filter(article=>article.isPublished)
        setFirstArticle(publishedArticles[0]);
        setArticles(publishedArticles.slice(1));
      })
      .catch((err) => console.log(err));
  }, [0]);
  const navigate = (article: { _id: any }) => {
    history.push(`/learning-center/${article._id}`);
  };
  console.log(state);
  return (
    <>
      <Helmet>
          <title>Learning Center | CharityMoments</title>
          <meta name="description" content="Read our blogs educating the Muslims on the importance of celebrating and honoring auspicious Islamic events and important personal days." />
      </Helmet>
      <div className="bg-white mx-4 md:mx-8 rounded-3xl px-4 xl:px-10 2xl:px-72 relative">
        <img src={gold_snow} className="absolute w-12 left-6" />
        <img src={gold_snow} className="absolute w-14 left-24 bottom-80" />
        <img src={black} className="absolute w-3 right-40 top-80" />
        <img src={gold_snow} className="absolute w-6 right-24 top-96" />
        <img src={gold_snow} className="absolute w-5 right-16 top-80" />
        <h1 className="text-center text-2xl pt-6 md:pt-24 md:text-4xl 2xl:text-8xl mb-8 md:mb-9 page-title-text">
          Learning Center
        </h1>
        <p className="text-center text-black 2xl:text-2xl font-PoppinsMedium mb-8 md:mb-14">
          Your online repository of beneficial, scholarly teachings.
        </p>
        <div
          style={{ maxWidth: 700 }}
          className="mt-1 relative rounded-md shadow-sm flex-grow ml-auto mr-auto"
        >
          <input
            style={{ backgroundColor: "#fafafa" }}
            type="text"
            name="price"
            id="price"
            className=" focus:ring-gray-200   focus:outline-none focus:ring focus:border-gray-200  focus:outline-none  block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-12"
            placeholder="Search"
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <AiOutlineSearch className="text-camel w-10 h-6 mx-3" />
            <label htmlFor="currency" className="sr-only">
              Currency
            </label>
          </div>
        </div>

        {firstArticle ? (
          <>
            <div className="flex items-center grid lg:grid-cols-2 mt-10 md:mt-20 xl:mx-2 sm:grid sm:grid-cols-1 mb-4 md:mb-16">
              <img
                src={firstArticle.featuredImage}
                className="mx-auto w-auto ml-auto rounded-3xl sm:mr-auto sm:ml-auto shadow-camel"
                alt="articleimage"
              />
              <div className="mx-auto sm:ml-10">
                <h1 className="mb-5 text-xl xl:text-3xl 2xl:text-5xl md:mt-5 font-Poppins md:font-Montserrat pt-5 lg:pt-0">
                  {firstArticle.title}
                </h1>
                <p className="text-sm 2xl:text-base">
                  {firstArticle.excerpt}
                  <br />
                  <button
                    onClick={() => {
                      navigate(firstArticle);
                    }}
                    className="text-camel font-bold "
                  >
                    Read more
                  </button>
                </p>
              </div>
            </div>
          </>
        ) : null}
        <div className="w-auto xl:bg-white rounded-3xl grid grig-cols-1 sm:grid-cols-2 md:grid-cols-2 sm:gap-x-5   lg:grid-cols-3 md:pb-10">
          {articles
            ? articles.map((article, index) => (
                <div className="xl:px-2 py-4  mx-auto ">
                  <div className="max-w-screen h-full relative rounded-3xl pb-6 overflow-hidden shadow-round ">
                    <div
                      class="bg-cover bg-center h-64 rounded-3xl"
                      style={{
                        backgroundImage: `url(${article.featuredImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="xl:px-6 py-4  px-3">
                      <div className="font-bold text-xl mb-2">
                        {article.title}
                      </div>
                      <p className="text-gray-700 text-sm">{article.excerpt}</p>
                    </div>
                    <div className="px-6 pt-0 pb-2">
                      <span className="text-sm text-camel mb-4">
                        <button
                          onClick={() => {
                            navigate(article);
                          }}
                          className="absolute bottom-4 left-0 xl:left-6 left-3"
                        >
                          Read more
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
}
