export default function PlusMinusSquare(props:any) {

	const handler = () => {
		if(props?.isActive){
			props.action(props.indexOfDay)
		}
	}

	if(props.op==="＋"){
		return(
		    <div onClick={handler} className="inline-block bg-lightGrey px-2 mr-2 cursor-pointer">
		    	<span className="text-darkGreen cursor-default text-2xl cursor-pointer">＋</span>
		 	</div>
	    )
	}

	else{
	    return(
	    	    <div onClick={handler} className={props?.isActive ? "inline-block bg-lightGrey px-3" : "inline-block bg-lightGrey2 px-3 opacity-0"}>
		    	<span className="text-red cursor-default text-2xl cursor-pointer">{props.op}</span>
		 	</div>
		)
	}
}