import React, { useContext } from "react";
// import '../css/styles.css'
import logo from "../assets/logo.svg";
import linkedin from "../assets/icons/linkedin.png";
import twitter from "../assets/icons/group-8805.png";
import instagram from "../assets/icons/group-8806.png";
import facebook from "../assets/icons/group-8807.png";
import amanah from "../assets/landing/amanah.png";
import cards from "../assets/landing/cards.png";
import footer_bg from "../assets/landing/footer_bg.png";
import { Context } from "../utils/store";

export default function Footer() {
  const { state, dispatch } = useContext(Context);
  return (
    <>
      <img
        className="overscroll-x-none hidden md:flex absolute bottom w-screen overflow-hidden z-20 mt-10"
        src={footer_bg}
      />
      <div className="px-4 mt-3 md:mx-4 2xl:mt-8 relative">
        {/* <img src={black} className='absolute w-4 left-16 top-20'/>
             <img src={gold} className='absolute w-7 left-7 bottom-28'/>
             <img src={gold} className='absolute w-4 right-36 bottom-44'/>
             <img src={snow_black} className='absolute  right-8 top-14'/> */}
        {/* <div className='justify-center w-auto bg-white rounded-3xl p-10 flex flex-row mb-5 px-32 auto-rows-max'>
           
                <div className='flex-auto md:flex-1 px-4 Leadership-Shariah-Compliance'><span className='footerTitle'>About</span><br />Leadership<br />Shariah Compliance<img className='w-40 h-auto mt-5' src={amanah}/></div>
                <div className='flex-auto md:flex-1 px-4 Leadership-Shariah-Compliance'><span className='footerTitle'>Learn</span><br />Days & Dates<br />FAQs<br />Charity Partners<br />Learning Center</div>
                <div className='flex-auto md:flex-1 px-4 Leadership-Shariah-Compliance'><span className='footerTitle'>Contact</span><br /><a href="#" style={{color:'#d0a342', textDecoration:'underline'}}>salaam@charitymoments.com</a><br />260 Hillside Avenue<br />Valley Stream, NY 11580<br />United States of America</div>
                <div className='flex-auto md:flex-1 px-4 Leadership-Shariah-Compliance'><span className='footerTitle'>My Account</span><br /><a href="#" style={{color:'#d0a342', textDecoration:'underline'}}>Login</a><img className='w-80 h-auto mt-5 mb-10' src={cards}/></div>
            </div> */}
        <div className="w-auto md:py-16 lg:py-20  2xl:py-28 bg-white rounded-3xl">
          <div className="mx-4 md:mx-24 lg:mx-32 2xl:mx-80 gap-x-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-10 2xl:pt-2 z-30">
            <div className="px-2 mt-4 md:mt-0 z-30 xl:col-span-2">
              <span className="text-base 2xl:text-xl leading-10 font-PoppinsMedium font-light">
                About
              </span>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey leading-8 2xl:leading-10 font-Poppins"
                href="/leadership"
              >
                Leadership
              </a>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey  2xl:leading-10 font-Poppins"
                href="/shariah-compliance"
              >
                Shariah Compliance
              </a>
              {/* <img className="w-40 h-auto mt-2 2xl:mt-2" src={amanah} /> */}
            </div>
            <div className="px-2 mt-2 pt-3 md:pt-0 md:mt-0 md:px-4 z-30 xl:col-span-2">
              <span className="text-md  2xl:text-xl mb-10 2xl:pb-10 leading-10 font-PoppinsMedium">
                Learn
              </span>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey font-Poppins leading-8 2xl:leading-10"
                href="/days-and-dates"
              >
                Days & Dates
              </a>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey leading-8 font-Poppins 2xl:leading-10"
                href="/frequently-asked-questions"
              >
                FAQs
              </a>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey leading-8 font-Poppins 2xl:leading-10"
                href="/charity-partners"
              >
                Charity Partners
              </a>
              <br />
              <a
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey leading-8 font-Poppins 2xl:leading-10"
                href="/learning-center"
              >
                Learning Center
              </a>
            </div>
            <div className="mt-2 pt-3 md:pt-8 lg:pt-16 xl:pt-0 md:mt-0 px-2 xl:px-8 z-30 xl:col-span-3">
              <span className="text-base 2xl:text-xl mb-10 pb-10  leading-10 font-PoppinsMedium">
                Stay in Touch
              </span>
              <br />
              <a
                href="mailto:salaam@charitymoments.com"
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey leading-10"
                style={{ color: "#d0a342", textDecoration: "underline" }}
              >
                salaam@charitymoments.com
              </a>
              <br />
              <div className="justify-start flex flex-wrap content-center relative">
                {/* Made with ❤️ in NYC */}
                <a
                  className="hover:opacity-80"
                  href="https://www.facebook.com/gocharitymoments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={facebook} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://twitter.com/Charity_Moments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={twitter} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.linkedin.com/company/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={linkedin} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.instagram.com/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8" src={instagram} />
                </a>
              </div>
            </div>
            <div className="content-center pt-5 md:pt-8 lg:pt-16 xl:pt-0 px-2 mt-2 md:mt-0 pl-4 xl:pl-8 z-30 xl:col-span-3">
              <span className="text-base 2xl:text-xl mb-10 pb-10 leading-10 font-PoppinsMedium">
                My Account
              </span>
              <br />
              <a
                href={state.isLoggedIn ? "/profile" : "/login"}
                className="hover:opacity-50 text-sm 2xl:text-base text-brownGrey pt-10 leading-10"
                style={{ color: "#d0a342", textDecoration: "underline" }}
              >
                {state.isLoggedIn ? "Access Your Account" : "Log In"}
              </a>
              <img className="w-full mt-5 mb-8" src={cards} />
            </div>
          </div>
        </div>
        <div className="w-auto xl:px-52 grid grid-cols-1 md:grid-cols-3 2xl:px-80">
          <div className="justify-center md:justify-start mt-5 col-span-2 flex flex-wrap content-center mb-5 relative">
            <p className="mt-auto mb-auto px-5 text-sm text-gray-400 font-Poppins">
              ©
            </p>
            <img className="box-content w-auto h-8 2xl:h-10" src={logo} />
            <p className="mt-auto mb-auto px-5 text-xs 2xl:text-sm text-gray-400 font-Poppins">
              {new Date().getFullYear()}
            </p>
            <p className="mt-auto mb-auto mr-5 text-xs 2xl:text-sm text-gray-400 relative z-50  font-Poppins">
              <a className="hover:opacity-75" href="/terms-and-conditions">
                Terms and Conditions
              </a>
            </p>
            <p className="mt-auto mb-auto text-xs 2xl:text-sm text-gray-400 relative z-50 font-Poppins">
              <a className="hover:opacity-75" href="/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="justify-center md:justify-end flex flex-wrap content-center relative">
            <span className="mt-auto mb-4 md:mb-auto text-xs 2xl:text-sm text-gray-400 font-Poppins">
              From NYC with ❤️
            </span>
          </div>
        </div>
        {/* <div className='px-40 pb-5 mt-5'>
                <div className='flex flex-row'>
                    <img className='box-content w-48' src={logo} />
                    <p className='mt-auto mb-auto px-5 text-gray-400'>2020</p>
                    <p className='mt-auto mb-auto px-5 font-bold text-gray-400'>Terms of Service</p>
                    <p className='mt-auto mb-auto font-bold text-gray-400'>Privacy Policy</p>
                </div>
                <div></div>
            </div> */}
      </div>
    </>
  );
}
