import React from "react";

export const initialState = {
  isLoggedIn: false,
  userInfo: null,
  signupInfo: null,
  firstTimeInfo: {
    donationFreq: "recurring",
    islamicDays: null,
    isAllIslamicDaysSelected: false,
    personalDays: null,
    minDonation: null,
    todayOnly: {
      name: "Today Only",
      isActive: true,
    },
    charities: null,
    causesContributions: null,
    donationTotal: 0,
    months: null,
    otherDays: null,
    coverFee: 0,
    addedNewCreditCards: [],
    selectedCardId: null,
  },
  pagesList: null,
  firstTimeCurrentPage: "Frequency",
  profileCurrentPage: "Profile",
  donationSchedules: null,
  inActiveDonationSchedules: null,
  transactionHistory: null,
  params: null,
  showLoader: false,
  learningcenter: {
    content: "",
  },
  viewBetaMsg: true,
};

export const reducer = (state: any, action: any) => {
  const {
    type,
    isLoggedIn,
    signupInfo,
    userInfo,
    firstTimeInfo,
    pagesList,
    profileCurrentPage,
    firstTimeCurrentPage,
    donationSchedules,
    inActiveDonationSchedules,
    transactionHistory,
    params,
    learningcenter,
    showLoader,
    viewBetaMsg,
  } = action;

  switch (type) {
    case "isLoggedIn":
      return { ...state, isLoggedIn };
    case "signupInfo":
      return { ...state, signupInfo };
    case "userInfo":
      return { ...state, userInfo };
    case "firstTimeInfo":
      return { ...state, firstTimeInfo };
    case "pagesList":
      return { ...state, pagesList };
    case "profileCurrentPage":
      return { ...state, profileCurrentPage };
    case "firstTimeCurrentPage":
      return { ...state, firstTimeCurrentPage };
    case "donationSchedules":
      return { ...state, donationSchedules };
    case "inActiveDonationSchedules":
      return { ...state, inActiveDonationSchedules };
    case "params": {
      return { ...state, params };
    }
    case "learningcenter": {
      return { ...state, learningcenter };
    }
    case "showLoader":
      return { ...state, showLoader };
    case "transactionHistory":
      return { ...state, transactionHistory };
    case "setViewBetaMsg":
      return {
        ...state,
        viewBetaMsg: typeof viewBetaMsg !== "undefined" ? viewBetaMsg : true,
      };
    default:
      return state;
  }
};

export const Context = React.createContext<any | null>(null);
