import React, { useEffect, useState } from "react";
import Header from "../components/header";
import "../css/styles.css";
import slide1 from "../assets/landing/slide1.png";
import landing_bg from "../assets/landing/landing_bg.png";
import why_cm from "../assets/landing/why_cm.png";
import lc_cm from "../assets/landing/lc_cm.png";
import logos from "../assets/landing/logos1.png";
import mobile_logos from "../assets/landing/mobile_logos.png";
import mobile_partner2 from "../assets/landing/mobile_partner2.png";
import mobile_partner3 from "../assets/landing/mobile_partner3.png";
import next from "../assets/landing/next.png";
import h_img1 from "../assets/landing/Howitworks1.png";
import h_img2 from "../assets/landing/Howitworks2.png";
import h_img3 from "../assets/landing/Howitworks3.png";
import donation from "../assets/landing/donation.svg";
import trusted from "../assets/landing/trusted.svg";
import Users from "../assets/landing/users.svg";
import Days from "../assets/landing/days.svg";
import money_raised from "../assets/landing/money_raised.png";
import become from "../assets/landing/become_a_charity_partner.png";
import start from "../assets/landing/start.png";
import islamic from "../assets/landing/islamic.svg";
import header_star from "../assets/landing/header_star.png";
import side_line from "../assets/landing/side_line.svg";
import axios from "axios";
import snow_black from "../assets/landing/snow_black.svg";
import black from "../assets/landing/black.svg";
import gold from "../assets/landing/gold.svg";
import gold_snow from "../assets/landing/snow_gold.svg";
import learninccenter_bg from "../assets/landing/learninccenter_bg.png";
import { useReducer } from "react";
import { reducer, initialState } from "../utils/store";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosUtility from "../utils/axios";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
export default function LandingPage() {
  const history = useHistory();
  const [articles, setArticles] = useState<Array<any> | null>(null);
  const [facts, setFacts] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState);
  // console.log("state", state);
  const [charity, setCharity] = React.useState([]);
  console.log(charity);
  useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      let newCharities = [];
      response.data.charities.forEach((oneCharity) => {
        if (oneCharity.isActive) {
          newCharities.push(oneCharity);
        }
      });
      setCharity(newCharities);
    });
  }, [0]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    initialSlide: 0,
    zIndex: 1000,
    swipeToSlide: true,
    dotsClass: "cm-paging",
    slidesToShow: articles ? articles.length >= 4 ? 3 : articles.length : 1,
    slidesToScroll: articles ? articles.length >= 4 ? 3 : articles.length : 1,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: articles ? articles.length >= 4 ? 2 : articles.length : 1,
          slidesToScroll: articles ? articles.length >= 4 ? 2 : articles.length : 1,
          centerMode: true,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          lazyRender: true,
          infinite: true,
          initialSlide: 0 ,
        },
      },
    ],
  };
  useEffect(() => {
    Mixpanel.track("View Home Page");
    axiosUtility
      .get(`/learning-center`)
      .then(async (response: any) => {
        // console.log(response);
        if (response && response.data && response.data.articles) {
          const filteredActivities = response.data.articles.filter((o: any) => o.isPublished);
          if (filteredActivities && filteredActivities.length) {
            if (filteredActivities.length > 4) {
              setArticles(filteredActivities.slice(0, 4));
            } else {
              setArticles(filteredActivities);
            }
          } else {
            setArticles(null);
          }
        } else {
          setArticles(null);
        }
        const facts = await getFunFacts();
        if (facts) setFacts(facts.data.response);
      })
      .catch((err) => console.log(err));
  }, [0]);

  const getFunFacts = async () => {
    return await axiosUtility.get(`/allfunfacts`);
  };

  function kFormatter(num: number) {
    return Math.abs(num) > 999
      ? new Intl.NumberFormat("en-IN").format(
          Math.sign(num) * (Math.abs(num) / 1000).toFixed(0)
        ) + "k"
      : new Intl.NumberFormat("en-IN").format(Math.sign(num) * Math.abs(num));
  }

  return (
    <div className="overscroll-x-none landing-page">
      <Helmet>
        <meta
          name="description"
          content="Islamic world's first comprehensive donation automation platform covering all major Islamic and personal events. Create schedules and donate to your favorite charities today."
        />
        <title>
          CharityMoments | Donate. Automate. On Your Most Important Days. 
        </title>
      </Helmet>
      <img
        className="hidden md:flex overscroll-x-none absolute -top-16 w-full z-10"
        src={landing_bg}
      />
      <div className="bg-white xl:h-screen/2 flex flex-wrap content-center px-4 md:px-10 mx-4 md:mx-8 rounded-3xl pt-6 md:p-10 grid grid-cols-1 auto-cols-max xl:grid-cols-2 shadow-sm top-landing-content">
        <div className="px-2 md:px-8 md:mt-20 2xl:mt-48 md:mb-10 lg:mx-auto lg:my-10 2xl:mb-56 md:text-3xl lg:text-5xl z-20">
          <h1 className="z-10 hidden 2xl:flex md:text-3xl lg:text-4xl 2xl:text-5xl font-bold md:mt-10 mb-4 md:pl-4 2xl:pl-12 2xl:leading-normal">
            Honor
            <div className="slidingVertical">
              <span>Prophet’s ﷺ birthday.</span>
              <span>Eid al-Adha.</span>
              <span>Ramadan.</span>
              <span>Eid al-Fitr.</span>
              <span>Islamic New Year.</span>
              <span>Day of Arafah.</span>
              <span>Day of Ashura.</span>
              <span>Your Parent’s Birthday.</span>
              <span>Your Marriage Anniversary.</span>
              <span>The Days of Allah.</span>
            </div>
          </h1>
          <h1 className="2xl:hidden lg:ml-5 md:ml-3 flex font-Montserrat text-xl md:text-2xl lg:text-3xl font-bold mb-4 sentence">
            Celebrate The Days of Allah
          </h1>
          <h3 className="text-left font-Montserrat text-base 2xl:text-3xl lg:text-2xl font-bold mb-2 md:text-lg md:pl-4 2xl:pl-12 leading-normal">
            “The Believer’s Shade On The Day Of Resurrection Will Be His
            Charity.”
          </h3>
          <h3
            className="text-left font-Montserrat font-bold mb-4 text-base 2xl:text-3xl lg:text-2xl md:text-lg md:pl-4 2xl:pl-12"
            style={{ color: "#d0a342" }}
          >
            -Prophet Muhammad ﷺ
          </h3>
          <h4
            className="mb-12 font-medium text-xs md:text-base 2xl:text-xl lg:text-lg lg:text-sm md:pl-4 2xl:pl-12 font-Poppins"
            style={{ color: "#7e7e7e" }}
          >
            Donate | Automate | On your most important days.
          </h4>
          <div className="md:pl-4 2xl:pl-12 z-50">
            <a
              href="/donation-automation"
              style={{ backgroundColor: "#d0a342" }}
              className="z-50 py-3 px-6 hover:opacity-90 rounded text-white  text-sm md:text-base lg:text-lg shadow-camel"
            >
              Schedule Your Donation
            </a>
          </div>
        </div>
        <div className="content-center">
          <img className="flex md:relative 2xl:mt-16 h-auto" src={slide1} />
        </div>
      </div>
      <div className="mx-4 lg:mx-40 2xl:mx-40 mb-12 md:mb-32 2xl:mb-32">
        <h1 className="text-center text-xl xl:text-3xl font-bold m-10 md:mt-20 2xl:mt-32 2xl:mb-20 2xl:text-5xl">
          <img className="mb-4 ml-auto mr-auto h-auto" src={header_star} />
          Our Charity Partners
        </h1>
        <div className="flex flex-row justify-center 2xl:gap-x-20 md:gap-x-10 gap-x-5">
          {charity?.map((image, index) => {
            return (
              <div
                className="xl:w-56 lg:w-40 md:w-32 w-28 h-auto   my-auto"
                key={index}
              >
                <img className="2xl:-mt-5 lg:-mb-5" src={image.logo} />
              </div>
            );
          })}
        </div>

        <div id="howitworks"></div>
        {/* <img
          className="hidden md:flex mb-14 ml-auto mr-auto w-full h-auto"
          src={logos}
        />
        <img
          className="flex md:hidden mb-14 px-4 ml-auto mr-auto w-full h-auto"
          src={mobile_logos}
        /> */}
      </div>

      <img
        className="hidden md:flex overscroll-x-none absolute w-full z-10"
        src={why_cm}
      />
      <div className="bg-white rounded-3xl p-10 relative 2xl:pb-12">
        <h2 className="mx-auto text-center text-xl xl:text-3xl font-Montserrat mt-4 mb-10 md:mt-8 md:py-4 2xl:text-5xl">
          <img className="mb-4 ml-auto mr-auto h-auto" src={header_star} />
          How It Works
        </h2>
        <div className="px-4 xl:mx-56 lg:mx-36 2xl:mx-72 pb-10 2xl:pb-10">
          {/* <img className="hidden lg:flex absolute -mt-14 2xl:-mt-24 max-w-screen-sm lg:max-w-2xl 2xl:max-w-screen-lg ml-auto mr-auto" src={howitworks} /> */}
          <div className="grid grid-cols-1 lg:grid-cols-8 2xl:py-4">
            <div className="col-span-2">
              <h1 className="text-camel text-6xl xl:text-9xl font-PoppinsBold text-center lg:text-left">
                1
              </h1>
              <p className="md:mt-6 2xl:mt-2 text-black font-PoppinsSemiBold text-base lg:text-sm 2xl:text-xl py-4 2xl:py-8 text-center lg:text-left">
                Select Days
              </p>
              <p className="text-xs 2xl:text-base font-Poppins text-brownGrey text-center lg:text-left">
                Select Islamic and personal <br />
                events that are important to you.
              </p>
            </div>
            <div>
              <img
                className="hidden xl:flex absolute xl:w-40 2xl:w-56 lg:mt-32 2xl:mt-32 xl:-ml-20 2xl:-ml-28"
                src={next}
              />
            </div>
            <div className="mt-10 lg:mt-0 col-span-2">
              <h1 className="text-camel text-6xl xl:text-9xl font-PoppinsBold opacity-70 text-center lg:text-left">
                2
              </h1>
              <p className="md:mt-6 2xl:mt-2  text-black font-PoppinsSemiBold text-base lg:text-sm 2xl:text-xl py-4 2xl:py-8 text-center lg:text-left">
                Choose Charities
              </p>
              <p className="text-xs 2xl:text-base font-Poppins text-brownGrey text-center 2xl:w-64 lg:text-left">
                Choose from a list of trusted and reputable charity partners.
              </p>
            </div>
            <div>
              <img
                className="hidden xl:flex absolute xl:w-40 2xl:w-56 lg:mt-32 2xl:mt-32 lg:-ml-12 2xl:-ml-16"
                src={next}
              />
            </div>
            <div className="mt-10 lg:mt-0 xl:ml-10 col-span-2">
              <h1 className="text-camel text-6xl xl:text-9xl font-PoppinsBold opacity-60 text-center lg:text-left">
                3
              </h1>
              <p className="md:mt-6 2xl:mt-2 text-black font-PoppinsSemiBold text-base lg:text-sm 2xl:text-xl py-4 2xl:py-8 text-center lg:text-left">
                Donate To Causes
              </p>
              <p className="text-xs 2xl:text-base font-Poppins text-brownGrey text-center 2xl:w-56 lg:text-left">
                Donate to the initiatives you care about.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-2 xl:mx-56 2xl:mx-80 grid grid-cols-1 lg:grid-cols-2 2xl:py-4">
          <div className="py-4 justify-center 2xl:pr-20">
            <h2 className="mt-5 lg:mt-20 2xl:mt-24 2xl:pt-2 text-xl xl:text-2xl 2xl:text-4xl mb-5 font-PoppinsSemiBold">
              <img className="absolute 2xl:pt-1.5 -ml-3" src={side_line} />
              Select Your Days
            </h2>
            <p className="text-xs md:text-sm 2xl:text-xl mb-4 2xl:mr-4 font-Poppins font-extralight">
              Whether it’s important&nbsp;
              <span className="text-camel">
                Islamic events or personal days,&nbsp;
              </span>
              choose from a wide range of occasions that you want to honor and
              celebrate.
            </p>
          </div>
          <div className="justify-center">
            <img
              className="lg:absolute lg:mx-2 lg:max-w-lg 2xl:max-w-3xl"
              src={h_img1}
            />
          </div>
        </div>

        <div className="mx-2 xl:mx-56 2xl:mx-80 grid grid-cols-1 lg:grid-cols-2 2xl:py-4 mt-8 2xl:mt-28">
          <div className="py-4 justify-center 2xl:pr-20">
            <h2 className="lg:mt-20 2xl:mt-24 2xl:pt-2 text-xl xl:text-2xl 2xl:text-4xl mb-5 font-PoppinsSemiBold">
              <img className="absolute 2xl:pt-1.5 -ml-3" src={side_line} />
              Choose Charities You Want To Support
            </h2>
            <p className="text-xs md:text-sm 2xl:text-xl mb-4 2xl:mr-4 font-Poppins text-brownGrey">
              Select from a carefully vetted list of reputable and trusted
              non-profits strengthening the global community.&nbsp;
              <span className="text-camel">We work with the best&nbsp;</span>
              so you can&nbsp;
              <span className="text-camel">donate with peace of mind.</span>
            </p>
          </div>
          <div className="justify-center">
            <img
              className="lg:absolute lg:mx-2 lg:max-w-lg 2xl:max-w-3xl"
              src={h_img2}
            />
          </div>
        </div>
        <div className="mx-2 xl:mx-56 2xl:mx-80 grid grid-cols-1 lg:grid-cols-2 2xl:py-4 mt-8 2xl:mt-16 md:mb-10">
          <div className="py-4 justify-center 2xl:pr-20">
            <h2 className="lg:mt-20 2xl:mt-24 2xl:pt-2 text-xl xl:text-2xl 2xl:text-4xl mb-5 font-PoppinsSemiBold">
              <img className="absolute 2xl:pt-1.5 -ml-3" src={side_line} />
              Donate To Causes You Care About
            </h2>
            <p className="text-xs md:text-sm 2xl:text-xl mb-4 2xl:mr-4 font-Poppins text-brownGrey">
              <span className="text-camel">Automate your donations&nbsp;</span>to the
              worthy initiatives our partners are involved in - from poverty
              alleviation to increasing literacy, civil rights to emergency aid
              - take your pick.
            </p>
          </div>
          <div className="justify-center">
            <img
              className="lg:absolute lg:mx-2 lg:max-w-lg 2xl:max-w-3xl"
              src={h_img3}
            />
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          className="hidden md:flex overscroll-x-none absolute top-32 w-full z-20"
          src={lc_cm}
        />
        <h1 className="text-center text-xl xl:text-3xl font-bold mt-10 md:mt-20 2xl:mt-24 2xl:text-5xl pb-4 2xl:pb-8 -mb-2 px-10 md:px-12">
          <img className="mb-4 ml-auto mr-auto h-auto" src={header_star} />
          Why CharityMoments
        </h1>
        <div className="w-auto md:p-10 sm:mx-12 md:mx-40 lg:mx-24 xl:mx-40 2xl:mx-60  justify-center grid grid-cols-1 gap-2 lg:grid-cols-3 z-50 charity-m-card-main">
          <div className="relative  flex-grow-0 px-2  mb-2 md:mb-5 bg-white rounded-3xl px-9 py-5 mt-5 2xl:py-10 shadow-fullCard mx-10 md:mx-2 2xl:h-80 charity-m-card">
            <img src={islamic} className="w-16 mb-4 2xl:mb-5" />
            <h3 className="mb-4 text-lg 2xl:text-xl 2xl:mb-6 font-PoppinsSemiBold">
              Islamic & Personal Days
            </h3>
            <p className="text-xs 2xl:text-base 2xl:mb-6 font-Poppins">
              Choose from a wide range of important Islamic and personal events
              to honor and celebrate throughout the year.
            </p>
          </div>
          <div className="relative px-2 mb-2 md:mb-5 bg-white rounded-3xl px-9 py-5  mt-5 2xl:py-10 shadow-fullCard mx-10 md:mx-2 2xl:h-80 charity-m-card">
            <img src={trusted} className="w-16 mb-4 2xl:mb-5" />
            <h3 className="mb-4 text-lg 2xl:text-xl 2xl:mb-6 font-PoppinsSemiBold">
              Trusted Charity Partners
            </h3>
            <p className="text-xs 2xl:text-base font-Poppins">
              Support your favorite charities and causes - vetted and chosen to
              drive maximum impact.
            </p>
          </div>
          <div className="relative px-2 mb-2 md:mb-5 bg-white rounded-3xl px-9 py-5  mt-5 2xl:py-10 shadow-fullCard mx-10 md:mx-2 2xl:h-80 charity-m-card">
            <img src={donation} className="w-16 mb-4 2xl:mb-5" />
            <h3 className="mb-4 text-lg 2xl:text-xl 2xl:mb-6 font-PoppinsSemiBold">
              Donation Automation
            </h3>
            <p className="text-xs 2xl:text-base font-Poppins">
              Never miss any occasions with our robust donation automation
              platform.
            </p>
          </div>
        </div>

        <div className="w-auto p-4 md:p-10 md:mx-40  xl:mx-72 whitespace-nowrap 2xl:mx-96 justify-center grid grid-cols-1 gap-12 lg:grid-cols-3 z-50 mb-8 charity-counter">
          {/* <div className="justify-center w-auto mb-2 ml-auto mr-auto relative">
          <p className="font-TrashHand text-camel text-6xl xl:text-7xl 2xl:text-9xl text-center">
            2000k
          </p>
          <p className="font-Montserrat text-base xl:text-xl 2xl:text-2xl flex flex-wrap content-center pt-6">
            
            <img className="h-4 md:h-6 md:ml-2 mr-2" src={money_raised} />
            Money Raised
          </p>
        </div> */}
          <div className="justify-center w-full mb-2 mx-auto relative">
            <p className="text-camel text-6xl xl:text-7xl 2xl:text-9xl text-center">
              <span className="font-Milloyste text-camel">$ </span>
              <span className="font-TrashHand">
                {kFormatter(facts?.totalDonated ? facts?.totalDonated : 0)}
              </span>
            </p>
            <p className="font-Montserrat text-base xl:text-xl 2xl:text-2xl flex pt-3 justify-center  mx-auto">
              <img className="h-4 md:h-5 mt-1 mr-2" src={money_raised} /> Money
              Raised
            </p>
          </div>
          <div className="justify-center w-auto mb-2 mr-auto ml-auto relative">
            <p className="font-TrashHand text-camel text-6xl xl:text-7xl 2xl:text-9xl text-center">
              {facts?.allUser
                ? new Intl.NumberFormat("en-IN").format(facts.allUser)
                : 0}
            </p>
            <p className="font-Montserrat text-base xl:text-xl 2xl:text-2xl flex pt-6">
              <img className="h-4 md:h-5 mt-1 mr-2" src={Users} /> Users
            </p>
          </div>
          <div className="justify-center w-auto mb-2 mr-auto ml-auto relative whitespace-nowrap">
            <p className="font-TrashHand text-camel text-6xl xl:text-7xl 2xl:text-9xl text-center">
              {kFormatter(facts?.daysCelebrated ? facts?.daysCelebrated : 0)}
            </p>
            <p className="font-Montserrat text-base xl:text-xl 2xl:text-2xl flex flex-nowrap content-center pt-6">
              <img className="h-4 md:h-7 mr-2" src={Days} /> Days Celebrated
            </p>
          </div>
        </div>
      </div>

      {articles && 
        <div className="justify-center w-auto bg-white rounded-3xl py-10 mb-10 relative 2xl:py-20 z-auto">
          <div className="relative z-30">
            <h1 className="text-center text-xl xl:text-3xl font-bold md:mb-10 2xl:mb-12 2xl:text-5xl md:mt-4 z-30">
              <img className="mb-4 ml-auto mr-auto h-auto" src={header_star} />
              Learning Center
            </h1>
            <div style={{ maxWidth: "100%" }} className="z-50 overscroll-x-none ">
              <Slider {...settings}>
                {articles &&
                  articles.map((article, index) => (
                    <div
                      key={index}
                      className="learningCenter overscroll-x-none   w-auto pr-2 2xl:pr-10 my-10 rounded-3xl h-48 mr-16 2xl:h-56 shadow-xl flex flex-1 shadow-round z-50 learn-center-card"
                    >
                      <div className="w-28 lg:w-40 2xl:w-56 relative rounded-xl">
                        <img
                          src={article.featuredImage}
                          alt=""
                          className="absolute inset-0 w-auto h-48 2xl:h-56 object-cover rounded-l-xl"
                        />
                        <p className="p-1 px-4 bg-camel text-sm text-white absolute rounded-tl-xl rounded-br-xl top-0 ">
                          {new Date(article.publishedAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </p>
                      </div>
                      <div className="relative flex my-2 2xl:my-2 flex-wrap md:w-full h-40 overflow-y-hidden  2xl:h-48 learn-center-content">
                        <p
                          style={{
                            fontWeight: 600,
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 4,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="flex-auto overflow-hidden text-black ml-32 lg:ml-48 2xl:ml-60 my-2 text-xs xl:text-sm 2xl:text-lg font-semibold"
                        >
                          {article?.title}
                        </p>
                        <p className="text-gray-700  ml-32 lg:ml-48 2xl:ml-60 text-xs xl:text-xs 2xl:text-base font-Poppins overflow-ellipsis overflow-hidden text-brownGrey">
                          <span
                            style={{
                              maxWidth: "100%",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {article?.excerpt}
                          </span>
                          {/* <span className="xl:hidden">
                            {article?.excerpt?.slice(0, 30).concat("...")}
                          </span> */}
                          <button
                            onClick={() => {
                              history.push(`/learning-center/${article._id}`);
                            }}
                            type="button"
                            className="group hover:opacity-75  focus:outline-none text-black inline-flex items-center text-base font-medium hover:text-gray-900 "
                          >
                            <span className="text-camel text-xs overflow-visible 2xl:text-base">
                              Read More
                            </span>
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      }
      <div className="w-auto grid relative grid-cols-1 sm:mx-24 md:mx-48 lg:mx-32 xl:mx-56 2xl:mx-80 md:gap-2 2xl:gap-2 lg:grid-cols-2 2xl:pt-2 learn-center-btm-card">
        <div className="justify-center w-auto bg-white ml-4 md:ml-4 mr-4 rounded-3xl mb-5 relative 2xl:h-96 shadow-fullCard sm:h-80 h-60 max-w-xl">
          <img
            className="absolute mt-20 sm:mt-32 lg:mt-24 lg:pt-12 xl:pt-2 xl:mt-32 2xl:mt-40 2xl:pt-3 pt-1.5 ml-5 z-40"
            src={side_line}
          />
          <img src={start} className="ml-auto h-60 sm:h-auto" />
          <img src={gold_snow} className="absolute left-32 -top-2 w-8" />
          <img src={black} className="absolute left-3 top-20" />
          <h2 className="absolute top-20 sm:top-32  lg:mt-2 xl:mt-0  2xl:top-40 text-base sm:text-lg md:text-xl xl:text-2xl 2xl:text-3xl pl-10 2xl:pl-10 font-PoppinsSemiBold leading-normal">
            Start Celebrating The <br />
            Days Of Allah
          </h2>

          <a
            href="/donation-automation"
            style={{ backgroundColor: "#d0a342" }}
            className="hover:opacity-90 shadow-camel w-11/12 py-2 2xl:py-3 mx-4 text-sm md:text-base xl:text-xl 2xl:text-xl text-center rounded absolute bottom-4 md:bottom-10 text-white font-Lato z-40"
          >
            Get Started
          </a>
        </div>
        <div className="justify-center w-auto bg-white ml-4 md:ml-4 mr-4 rounded-3xl mb-5 relative 2xl:h-96 shadow-fullCard sm:h-80 h-60 max-w-xl">
          <img
            className="absolute mt-20 sm:mt-32 lg:mt-24 lg:pt-12 xl:pt-2 xl:mt-32 2xl:mt-40 2xl:pt-3 pt-1.5 ml-5 z-40"
            src={side_line}
          />
          <img src={become} className="ml-auto h-60 sm:h-auto" />
          <img className="absolute top-12 -left-2 w-4" src={gold_snow} />
          <img
            className="absolute -top-1 left-60 w-5 transform rotate-45"
            src={snow_black}
          />
          <h2 className="absolute top-20 sm:top-32  lg:mt-2 xl:mt-0  2xl:top-40 text-base sm:text-lg md:text-xl xl:text-2xl 2xl:text-3xl pl-10 2xl:pl-10 font-PoppinsSemiBold leading-normal">
            Become A Charity
            <br /> Partner
          </h2>
          <a
            href="/charitysignup.html"
            style={{ backgroundColor: "#d0a342" }}
            className="hover:opacity-90 shadow-camel w-11/12 text-sm md:text-base xl:text-xl 2xl:text-xl py-2 2xl:py-3 mx-4 text-center rounded absolute bottom-4 md:bottom-10 text-white font-Lato z-40"
          >
            Apply Today
          </a>
        </div>
      </div>
    </div>
  );
}
