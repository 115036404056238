import React from "react";
import Button from "../components/Button";
import rachidOucharia from "../assets/landing/rachid-oucharia_3x.png";
import muftiFarazAdamImage from "../assets/landing/mufti-faraz-adam_3x.png";
import amanahAdvisors from "../assets/shariah/0@3x.png";
import shariah_bg from "../assets/shariah_bg.png";
import {Helmet} from "react-helmet";
import soon from '../assets/shariah/coming_soon.jpg'
import  { Mixpanel }  from '../Mixpanel';
export default function ShariahCompliance() {
  Mixpanel.track('View Shariah Compliance Page');
  return (
    <div className="">
      <Helmet>
          <title>Shariah Compliance | CharityMoments</title>
          <meta name="description" content="Our platform is shariah compliant and ethical. Find out the details about our certification." />
      </Helmet>
      <img
        className="hidden xl:flex h-screen absolute md:-top-24 2xl:top-20 z-10"
        src={shariah_bg}
        alt="shariah image"
      />
      <Block>
        <div className="mb-8 pb-4 md:pb-32 2xl:px-28">
          <h1 className="text-2xl md:text-4xl 2xl:text-8xl text-center pt-6 md:pt-24">
          Shariah Compliance
          </h1>
          <div className="2xl:max-h-full flex flex-wrap content-center grid xl:grid-cols-3 grid-cols-1 gap-12 justify-items-center pt-8 md:pt-24 flex-reverse">
            <div className="justify-center w-auto md:col-span-2">
              {/* <h2 className="mb-4 text-xl md:text-2xl 2xl:text-4xl font-PoppinsMedium">
                Methodology & Findings
              </h2> */}
              <p className="text-base 2xl:text-xl font-Poppins leading-8 2xl:mr-10">
              We’re in the process of getting our shariah compliance certification insha’Allah. <br/><br/>

Note: We consulted numerous independent scholars from the United States, Canada, Egypt, Morocco and the UK and they have not raised any concerns in using this platform. <br/><br/>

We will update this page once we have formally obtained the certification.
                {/* The Islamic Finance industry has experienced massive growth from
                across the globe. The industry is projected to be at $3.4
                trillion US dollars by 2018. At Igloo Crowd, we understand the
                need for an alternative investment for muslims in the UK, which
                is transparent, easy to understand and certified as Shariah
                Compliant. <br />
                <br />
                Our promise to be “shariah compliant” is not just empty words.
                Our terms and conditions, investment model and general business
                structure has been reviewed thoroughly by the Islamic Shariah
                Council, who have issued a certificate confirming that we are
                Shariah Compliant. We aim to provide Muslims over the world a
                simple, transparent and ethical way to invest in the UK property
                market, without compromising their faith. */}
                <br />
                <br />
              </p>
            </div>
            <div className="content-center">
              <img className="flex md:relative h-auto rounded-3xl" src={soon} />
            </div>
          </div>
        </div>
      </Block>
      {/* <Block>
        <div className="grid xl:grid-cols-3 grid-cols-1 gap-12 justify-items-center py-4 md:py-32 2xl:px-28 mb-8">
          <div className="md:col-span-2">
            <h2 className="text-xl md:text-2xl 2xl:text-4xl font-PoppinsMedium leading-5 pt-10 md:pt-0">
              Mufti Faraz Adam Hanif
            </h2>
            <h2 className="text-brownGrey md:text-xl 2xl:text-3xl pb-8 font-Poppins pt-6">
              Shari’ah Advisor
            </h2>
            <p className="text-base 2xl:text-xl font-Poppins 2xl:mr-20 text-brownGrey font-light">
              Serves on many Shariah boards and Shariah advisory positions for
              Islamic financial institutions across the world in the US, UK,
              Switzerland, Bahrain, Singapore, Dubai, and other countries.
              <br />
              <br />
              Attained various finance-industry qualifications such as the IFQ,
              CIFE and is a Certified Shariah Advisor and Auditor (CSAA).
              <br />
              <br />
              Mufti Faraz completed a six-year Alimiyyah program in the UK after
              which he went on to complete the Iftaa course in South Africa. He
              holds a Master’s Degree in Islamic Finance, Banking and Management
              at Newman University, UK in 2017.
              <br />
              <br />
            </p>
          </div>
          <div className="justify-center w-auto">
            <img className="inline h-auto" src={muftiFarazAdamImage} />
          </div>
        </div>
      </Block>
      <Block>
        <div className="grid xl:grid-cols-2 grid-cols-1 pt-12 pb-20 md:py-48 2xl:px-28">
          <div className="inline-block md:text-left">
            <h2 className="hidden xl:flex mb-5 md:text-2xl 2xl:text-4xl font-PoppinsMedium leading-snug">
              Our Commitment To
              <br /> Your Values
            </h2>
            <h2 className="xl:hidden flex mb-5 text-xl md:text-2xl 2xl:text-4xl font-PoppinsMedium leading-snug">
              Our Commitment To Your Values
            </h2>
            <div className="inline-block">
              <p className="mb-5 text-left text-base py-8 2xl:text-xl">
                We went the extra mile to ensure that a donation automation
                technology platform is Shariah compliant and ethical.
              </p>
            </div>
            <Button
              style="shadow-camel justify-start text-sm mx-auto md:m-0 hover:opacity-90 font-Poppins py-3"
              title="View Shariah Compliance Certificate"
            ></Button>
          </div>
          <div className="xl:justify-end w-auto mt-10 xl:mt-0">
            <img className="h-auto md:h-64 xl:h-auto xl:ml-10 mt-10 xl:mt-0 xl:-mb-20 lg:m-0 lg:ml-10 2xl:ml-10 xl:m-0 " src={amanahAdvisors} />
          </div>
        </div>
      </Block> */}
    </div>
  );
}

const Block = (props: any) => {
  return (
    <div className="bg-white rounded-2xl px-4 sm:px-8 md:px-16 lg:px-40 mx-4 md:mx-8">
      {props.children}
    </div>
  );
};
