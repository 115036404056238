import React, {useContext, useState, useEffect} from 'react'
import axios from '../../utils/axios'
import {Context} from '../../utils/store';
import { FiCheck } from "react-icons/fi";
import { useParams } from "react-router";
import CurrencyFormat from 'react-currency-format';

export default function CausesContribution() {
	const {state, dispatch} = useContext(Context)
    const { charityId } = useParams();

    const [charities, setCharities] = React.useState<Array<any> | null>(null)
    const [causesContributions, setCausesContributions] = useState<Array<any> | null>(null)
    
    const [charitiesResponse, setCharitiesResponse] = useState(null)
    const [causesResponse, setCausesResponse] = useState(null)

    const fetchAndSetData = async () =>{
        await axios.get("/charities")
        .then((response) => {
          setCharitiesResponse(response);
        }).catch((err) => console.log(err));
    
        await axios.get('/causes')
        .then((response)=>{
          setCausesResponse(response);
        }).catch(err=>console.log(err))
      }

      useEffect(()=>{
        // Setting Charities and Causes from response
        if(charitiesResponse && causesResponse){
          let newCausesContributions = [...causesResponse?.data?.response]
          newCausesContributions = newCausesContributions.filter((oneCause)=>{
            if(charityId){
                if(oneCause.parentCharity==charityId){ return true } 
                else return false
              }
              else{ 
                if(oneCause.isActive){
                  return true;
                }
              }
        })
          let newCharities = charitiesResponse.data.charities.filter((oneCharity)=>{
            if(charityId){
                if(oneCharity._id==charityId){ return true } 
                else return false
              }
              else{ 
                if(oneCharity.isActive){
                  return true;
                }
              }
          })
          dispatch({
              type: "firstTimeInfo",
              firstTimeInfo: {
                ...state.firstTimeInfo,
                charities: newCharities, causesContributions: newCausesContributions
              },
          });
          setCharities(newCharities); 
          setCausesContributions(newCausesContributions)
        }
      },[charitiesResponse, causesResponse])

      useEffect(() => {
        if(!state?.firstTimeInfo?.causesContributions && !state?.firstTimeInfo?.charities){
          fetchAndSetData()
        } 
        else{
          // Setting Charities and Causes from GLOBAL STATE:
          setCharities(state?.firstTimeInfo?.charities);
          setCausesContributions(state?.firstTimeInfo?.causesContributions) 
        }
      }, []);

   

    let allBlocks = null;
    let donationBlocks = null;

    if(causesContributions && state.firstTimeInfo && charities){
        allBlocks = charities?.map((charity, charityIndex)=>{
            donationBlocks = causesContributions.map((causesContributions)=>{
                if(charity._id === causesContributions.parentCharity){
                    return(
                        <DonationBlock 
                            id={causesContributions._id}
                            isZakat={causesContributions.isZakat}
                            name={causesContributions.name} 
                            description={causesContributions.description}
                            icon={causesContributions.icon}
                            />
                    )
                }
            })
            if(charity.isSelected){
                return(
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-3 mb-4 z-20 relative page-content-main">
                        <CharityBlock 
                            isSelected={charity.isSelected}
                            setCharities={setCharities} 
                            charities={charities} 
                            currentCharityIndex={charityIndex} 
                            img={charity.logo}
                        />
                        {donationBlocks}
                    </div>
                )
            } else {return null}
        })
    }
    if(causesContributions && state.firstTimeInfo && charities){
        return(
            <div>
                {allBlocks}
                    <div className="shadow-lg bg-camel mb-5 rounded-lg z-20 relative w-full md:w-30rem">
                    <div className="relative xl:mt-8 bg-white mx-2 p-3 md:pl-3 pl-2 px-6 h-28 md:h-16 rounded-lg">
                        <span className="mt-3 md:mt-2 text-sm md:text-base float-left justify-self-center">Total Donation</span>
                        <div className="inline-block mt-1 md:mt-0 float-left md:float-right">
                        <div className="inline-block mr-4 relative ">
                            <span className="absolute top-2 left-2">$</span>
                            {/* <input 
                                value={state?.firstTimeInfo?.donationTotal ? state?.firstTimeInfo?.donationTotal.toFixed(2) : 0}
                                className="bg-lightGrey w-32 placeholder-darkGrey md:w-32 pl-6 pr-6 pt-0 outline-none sm:text-sm border-gray-300  rounded-md h-10 " 
                                type="text"
                                placeholder="Your Amount" />  */}
                                <CurrencyFormat  disabled className="bg-lightGrey w-32 placeholder-darkGrey md:w-32 pl-6 pr-6 pt-0 outline-none sm:text-sm border-gray-300  rounded-md h-10 "  value={state?.firstTimeInfo?.donationTotal ? state?.firstTimeInfo?.donationTotal : 0} thousandSeparator={true}/>
                        </div>
                        <span className="mt-2 text-xs md:text-base">Per Selected Day</span>    
                        </div>
                    </div>
                    </div>
            </div>
        )
    } 
    else{return null}  
}

const DonationBlock = (props) => {

    const {state, dispatch} = useContext(Context)
    const [amount, setAmount] = useState(null)

    useEffect(()=>{
        if(state?.firstTimeInfo?.causesContributions){
            let index = state?.firstTimeInfo?.causesContributions?.findIndex((obj)=>obj._id===props.id)
            setAmount(state?.firstTimeInfo?.causesContributions[index]?.amount)
        }
    },[state?.firstTimeInfo?.causesContributions])

    const causesDonationHandler = (value: any) => {
        if(state?.firstTimeInfo?.causesContributions){
            let newCausesContributions = [...state.firstTimeInfo.causesContributions];
            let index = newCausesContributions?.findIndex((obj)=>obj._id==props.id)
            newCausesContributions[index].amount = value
            let totalAmount = 0;
            newCausesContributions.forEach((obj)=>{
                totalAmount = totalAmount + parseFloat((obj.amount ? obj.amount : 0));
            })
            dispatch({
                type: 'firstTimeInfo', 
                firstTimeInfo: {...state.firstTimeInfo, causesContributions: newCausesContributions, donationTotal: totalAmount}
            })
        }
    }

    return(
        <div className={props.isZakat ? "relative h-full shadow-lg h-96 bg-camel mb-5 rounded-lg" : "relative h-full shadow-round h-auto bg-white mb-5 rounded-lg"}>
            { props.isZakat ? <span className="absolute right-1 top-1/2 transform -translate-y-1/2 text-white ">Z <br/> A <br/> K <br/> A <br/> T <br/> </span> : null }
            <div className="bg-white h-full p-2 pt-6 rounded-lg bg-white card-x-padding">
                <div className="flex flex-col gap-y-4 justify-between h-full">
                    <div className="h-4/5 mb-0">
                        <img className="inline-block mr-1 2xl:mr-3 w-6 2xl:w-9" src={props.icon}/>
                        <h3 className="text-xs font-semibold 2xl:text-base text-black inline">{props.name}</h3>
                        <p className="text-xs 2xl:text-base text-black pb-0 mt-3 mb-0">{props.description}</p>
                    </div>

                    <div className="">
                            <div className="relative w-1/2 lg:w-2/3 inline-block">
                                <span className="absolute top-2 left-2 ">$</span>
                                {/* <input 
                                onChange={(e)=>{        
                                    let currentValue = twoNumbersAfterDot(e.target.value)
                                    causesDonationHandler(currentValue)
                                }}
                                value={amount}
                                    className="w-full mr-5 bg-lightGrey w-40 pl-7 pt-0 pr-4 placeholder-darkGrey outline-none text-xs 2xl:text-sm border-gray-300 rounded-md h-10" 
                                    type="number"
                                    placeholder="Your Amount" />  */}
                                    <CurrencyFormat className="w-full mr-5 bg-lightGrey w-40 pl-7 pt-0 pr-4 placeholder-darkGrey outline-none text-xs 2xl:text-sm border-gray-300 rounded-md h-10"  value={(amount) ? amount:""} thousandSeparator={true} onValueChange={(values) => {
                                        const {formattedValue, value} = values;
                                        // formattedValue = $2,223
                                        // value ie, 2223
                                        
                                        causesDonationHandler(value)
                                    }}/>
                            </div>  
                            <br/>  
                            <span className="inline-block lg:float-left mt-2 text-xs 2xl:text-xs font-Poppins">Per Selected Day</span>
                    </div>
                </div>
            </div>
        </div>
        )
}

const CharityBlock = (props: any) => {
    const {state, dispatch} = useContext(Context)

    const charityHandler = (isSelected) => {
        let newCharitiesForState = [...state?.firstTimeInfo?.charities];
        let newCharitiesForLocalState = [...props.charities]

        let onlyActiveCharities = newCharitiesForLocalState.filter((obj)=>obj.isSelected)

        newCharitiesForLocalState[props.currentCharityIndex].isSelected = isSelected;
        newCharitiesForState[props.currentCharityIndex].isSelected = isSelected;
        props.setCharities(newCharitiesForLocalState)


        // Removing amount of donations if charity not selected:
            let unSelectedCharityTotal = 0;
            let newCausesContributions = [...state.firstTimeInfo.causesContributions];
            let unselectedCausesIds = []    
            newCausesContributions.forEach((oneCause)=>{
                if(oneCause.parentCharity==state.firstTimeInfo.charities[props.currentCharityIndex]._id){
                    unselectedCausesIds.push(oneCause._id)
                }
            })
            newCausesContributions.filter((oneCause, index)=>{
                 if(unselectedCausesIds.includes(oneCause._id)){
                    unSelectedCharityTotal += !oneCause?.amount ? 0 : Number(oneCause?.amount);
                    newCausesContributions[index].amount='';
                 }
            })    
            let newDonationTotal = state.firstTimeInfo.donationTotal - unSelectedCharityTotal;

            dispatch({
                type: 'firstTimeInfo', 
                firstTimeInfo: {...state.firstTimeInfo, causesContributions: newCausesContributions, donationTotal: newDonationTotal, charities: newCharitiesForState}
            })
    }

    return (                        
        <div className={props.isSelected ? ('h-full shadow-round relative rounded-lg mb-5 pb-6 card-x-padding'):('shadow-round mr-4 mx-2 h-auto relative rounded-lg mb-5 pb-6')}>
            <div className="h-full bg-white">
                <div className="flex flex-col h-4/5 justify-center">
                    <img
                        className="mx-auto max-h-24"
                        src={props.img}
                        alt="article image"/>
                </div>
                <div className="flex flex-col h-1/5 justify-end">
                    <div className="h-12 shadow-md bg-darkGreen rounded-lg mx-auto w-full pl-1">
                        <div 
                        onClick={() => charityHandler(!props.isSelected)}
                        className={ props.isSelected ? ('h-12 cursor-pointer relative bg-lightGreen flex justify-center rounded-lg p-1 mb-3') : ('flex justify-center h-12 relative bg-lightGrey flex flex-row rounded-lg p-1 mb-3 cursor-pointer') }>
                        
                            { props.isSelected ?
                                <div className='mr-2 mt-2 bg-darkGreen pt-1 w-6 h-6 rounded-full'>
                                    <FiCheck className='text-white m-auto w-4 h-4'/>
                                </div> 
                                :
                                <div className='mr-2 mt-2 bg-white pt-1 w-6 h-6 rounded-full'>
                                    <FiCheck className='text-black m-auto w-4 h-4'/>
                                </div> 
                            }
                            <p className='inline-block mt-auto mb-auto px-auto cursor-default text-sm 2xl:text-lg cursor-pointer'>{props.isSelected ? "Selected" : "Select"}</p>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function twoNumbersAfterDot(value) {
  if (value.indexOf(".") != '-1') {
    value= value.substring(0, value.indexOf(".") + 3) ; 
    return value;  
  }
  return value;
}
