import React, { useEffect, useState } from "react";
import "../css/styles.css";
import { useParams } from "react-router-dom";
import axiosUtility from "../utils/axios";
import SkeletonLoader from "../components/SkeletonLoader";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
import smoothscroll from "smoothscroll-polyfill";

export default function LearningCenterDetails() {
  const { id } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [article, setArticle] = useState({
    featuredImage: "",
    title: "",
    description: "",
  });

  window.__forceSmoothScrollPolyfill__ = true;
  smoothscroll.polyfill();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEffect(() => {
    axiosUtility
      .get(`/learning-center/${id}`)
      .then((response) => {
        // console.log(response.data.response);
        Mixpanel.track("View LearningCenter Details Page", {
          Title: response.data.response.title,
        });
        setArticle(response.data.response);

        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }, [0]);
  return (
    <div className="bg-white mx-4 md:mx-8  rounded-3xl  relative ">
      <Helmet>
        <meta
          name="description"
          content="Read our blogs educating the Muslims on the importance of celebrating and honoring auspicious Islamic events and important personal days."
        />
        <title>Learning Center | CharityMoments</title>
      </Helmet>
      <SkeletonLoader showShadow={false} showLoader={showLoader} />
      <div
        style={{
          backgroundImage: `url(${article.featuredImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
        className="rouded rounded-3xl"
      >
        <img
          className="ml-auto rounded-t-3xl mr-auto max-h-96 z-10 opacity-0"
          src={article.featuredImage}
        />
        <div
          className="-mt-20 z-50"
          style={{
            backgroundImage: `linear-gradient(to bottom, transparent, white`,
            height: "71.5px",
          }}
        ></div>
      </div>

      <div className="border rounded-3xl border-camel border-b-4 border-t-4 border-r-4 border-l-4 p-4 py-6 mx-4 2xl:mx-80">
        <h1 className="text-center">{article.title}</h1>
      </div>
      <div className=" 2xl:px-80 px-4 sm:px-10 mt-10 pb-10">
        <div dangerouslySetInnerHTML={{ __html: article?.description }}></div>
      </div>
    </div>
  );
}
