import mixpanel from 'mixpanel-browser';
mixpanel.init('594909e43e4c33660c9032a84e40ce5f');

//let env_check = process.env.NODE_ENV === 'production';
let env_check =true;
let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;