import React, { useState, useEffect } from 'react'
import { MiToggleSwitch } from 'mi-toggle-switch';

export default function SwitchComponent(props:any) {

  const [isChecked, setIsChecked] = useState(false);

  	useEffect(()=>{
  		setIsChecked(props.switchValue)
  	},[props])

	const checkHandler = () => {	
			setIsChecked(!isChecked)
			props.action(props.indexOfDay)
	}

    return(
	    <div className="inline-block relative">
			{ !props.dateSelected ? <div className="absolute w-full h-full z-50 cursor-not-allowed"></div> : null }
        	<MiToggleSwitch
            onClick={checkHandler} 
            width="40" 
            height="22" 
			isEnable={isChecked}
            type="rectangular" />
	 	</div>
    )
}