import { useContext, useState, useEffect } from "react";
import { Context } from "../utils/store";
import { MenuContext } from "react-flexible-sliding-menu";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";

// Icons:
import frequency from "../assets/icons/frequency.svg";
import frequency_gold from "../assets/icons/frequency_gold.svg";
import islam from "../assets/icons/islam.svg";
import islam_gold from "../assets/icons/islam_gold.svg";
import user from "../assets/icons/user.svg";
import user_gold from "../assets/icons/user_gold.svg";
import charity from "../assets/icons/charity.svg";
import charity_gold from "../assets/icons/charity_gold.svg";
import causes from "../assets/icons/causes.svg";
import causes_gold from "../assets/icons/causes_gold.svg";
import checkout from "../assets/icons/checkout.svg";
import checkout_gold from "../assets/icons/checkout_gold.svg";
import donation from "../assets/icons/donation.svg";
import donation_gold from "../assets/icons/donation_gold.svg";
import payment from "../assets/icons/payment.svg";
import payment_gold from "../assets/icons/payment_gold.png";
import transaction from "../assets/icons/transaction.svg";
import transaction_gold from "../assets/icons/transaction_gold.svg";
import logout from "../assets/icons/checkout.svg";
import logout_gold from "../assets/icons/checkout_gold.svg";
import Swal from "sweetalert2";

function Menu() {
  const { state, dispatch } = useContext(Context);
  const { closeMenu } = useContext(MenuContext);
  const [cookies, setCookie, removeCookie] = useCookies();

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  useEffect(() => {
    axios
      .get("/config-settings")
      .then((response) => {
        dispatch({
          type: "firstTimeInfo",
          firstTimeInfo: {
            ...state.firstTimeInfo,
            coverFee: response.data.response.TRANSACTION_FEE,
            minDonation: response.data.response.MIN_DONATION_AMOUNT,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const icons = {
    // FirstTime:
    Frequency: { active: frequency_gold, inActive: frequency },
    SelectIslamicDays: { active: islam_gold, inActive: islam },
    SelectPersonalDays: { active: user_gold, inActive: user },
    ChooseYourCharities: { active: charity_gold, inActive: charity },
    CausesContribution: { active: causes_gold, inActive: causes },
    Checkout: { active: checkout_gold, inActive: checkout },

    // Profile:
    Profile: { active: user_gold, inActive: user },
    DonationSchedule: { active: donation_gold, inActive: donation },
    PaymentMethod: { active: payment_gold, inActive: payment },
    TransactionHistory: { active: transaction_gold, inActive: transaction },
    // "TransactionDetails": { active: transaction_gold, inActive: transaction},
    Logout: { active: logout_gold, inActive: logout },
  };
  const allPages = [
    { name: "Profile", title: "Profile" },
    { name: "DonationSchedule", title: "Donation Schedule" },
    { name: "PaymentMethod", title: "Payment Method" },
    { name: "TransactionHistory", title: "Transaction History" },
    { name: "Logout", title: "Log Out" },
  ];
  const firstTimePages = [
    {
      name: "Frequency",
      btnTitle: "Select Your Donation Frequency",
      title: "Frequency",
    },
    {
      name: "SelectIslamicDays",
      btnTitle: "Select Islamic Days",
      title: "Select Islamic Days",
    },
    {
      name: "SelectPersonalDays",
      btnTitle: "Select Personal Days",
      title: "Select Personal Days",
    },
    {
      name: "ChooseYourCharities",
      btnTitle: "Choose Your Charities",
      title: "Choose Your Charities",
    },
    {
      name: "CausesContribution",
      btnTitle: "Causes and Contributions",
      title: "Causes and Contributions",
    },
    { name: "Checkout", btnTitle: "Proceed to Checkout", title: "Checkout" },
  ];
  const currentPage =
    window.location.pathname == "/profile"
      ? "profile"
      : window.location.pathname.startsWith("/donation-automation")
      ? "donation-automation"
      : null;
  const currentTab =
    currentPage == "profile"
      ? state.profileCurrentPage
      : currentPage?.startsWith("donation-automation")
      ? state.firstTimeCurrentPage
      : null;

  const menuHandler = (pageName: string) => {
    if (currentPage == "profile") {
      dispatch({ type: "profileCurrentPage", profileCurrentPage: pageName });
    } else if (currentPage.startsWith("donation-automation")) {
      const checkpointPages = [
        "ChooseYourCharities",
        "CausesContribution",
        "Checkout",
      ];

      const charityPointPages = ["CausesContribution", "Checkout"];

      const checkoutPointPages = ["Checkout"];

      if (checkpointPages.indexOf(pageName) >= 0) {
        let selectedIslamicDays =
          state?.firstTimeInfo?.islamicDays &&
          state?.firstTimeInfo?.islamicDays?.filter(
            (islamicDay: { isIslamicDaySelected: boolean }) =>
              islamicDay.isIslamicDaySelected
          ).length;

        let selectedPersonalDays =
          state?.firstTimeInfo?.personalDays &&
          state?.firstTimeInfo?.personalDays?.filter(
            (personalDay: { date?: object }) =>
              personalDay.date && personalDay.isPersonalDayActive
          ).length;

        let selectedOtherDays =
          state?.firstTimeInfo?.otherDays &&
          state?.firstTimeInfo?.otherDays?.filter(
            (otherDay: { isSelected: boolean }) => otherDay.isSelected
          ).length;

        let selectedCharities =
          state?.firstTimeInfo?.charities &&
          state?.firstTimeInfo?.charities?.filter(
            (charity: { isSelected: any; isActive: any }) =>
              charity.isActive && charity.isSelected
          ).length;

        let selectedDonationTital = state?.firstTimeInfo?.donationTotal;

        if (state?.firstTimeInfo?.donationFreq === "recurring") {
          if (
            (state?.firstTimeInfo?.islamicDays != null &&
              selectedIslamicDays !== 0) ||
            (state?.firstTimeInfo?.personalDays != null &&
              selectedPersonalDays !== 0) ||
            (state?.firstTimeInfo?.otherDays != null && selectedOtherDays !== 0)
          ) {
            if (charityPointPages.indexOf(pageName) >= 0) {
              if (
                state?.firstTimeInfo?.charities != null &&
                selectedCharities !== 0
              ) {
                if (checkoutPointPages.indexOf(pageName) >= 0) {
                  if (
                    selectedDonationTital < state?.firstTimeInfo?.minDonation
                  ) {
                    if (selectedDonationTital <= 0) {
                      Toast.fire({
                        title: "Donation Amount is required.",
                        icon: "error",
                      });
                    } else {
                      Toast.fire({
                        title: `Total donation amount should be at least $${state?.firstTimeInfo?.minDonation}`,
                        icon: "error",
                      });
                    }
                  } else {
                    dispatch({
                      type: "firstTimeCurrentPage",
                      firstTimeCurrentPage: pageName,
                    });
                  }
                } else {
                  dispatch({
                    type: "firstTimeCurrentPage",
                    firstTimeCurrentPage: pageName,
                  });
                }
              } else {
                Toast.fire({
                  title: "Select at least one charity partner",
                  icon: "error",
                });
              }
            } else {
              dispatch({
                type: "firstTimeCurrentPage",
                firstTimeCurrentPage: pageName,
              });
            }
          } else {
            Toast.fire({
              title:
                "Select at least one Islamic day or one Sunnah day or one personal day",
              icon: "error",
            });
          }
        } else {
          if (
            (state?.firstTimeInfo?.islamicDays != null &&
              selectedIslamicDays !== 0) ||
            (state?.firstTimeInfo?.personalDays != null &&
              selectedPersonalDays !== 0)
          ) {
            if (charityPointPages.indexOf(pageName) >= 0) {
              if (
                state?.firstTimeInfo?.charities != null &&
                selectedCharities !== 0
              ) {
                if (checkoutPointPages.indexOf(pageName) >= 0) {
                  if (
                    selectedDonationTital < state?.firstTimeInfo?.minDonation
                  ) {
                    if (selectedDonationTital <= 0) {
                      Toast.fire({
                        title: "Donation Amount is required.",
                        icon: "error",
                      });
                    } else {
                      Toast.fire({
                        title: `Total donation amount should be at least $${state?.firstTimeInfo?.minDonation}`,
                        icon: "error",
                      });
                    }
                  } else {
                    dispatch({
                      type: "firstTimeCurrentPage",
                      firstTimeCurrentPage: pageName,
                    });
                  }
                } else {
                  dispatch({
                    type: "firstTimeCurrentPage",
                    firstTimeCurrentPage: pageName,
                  });
                }
              } else {
                Toast.fire({
                  title: "Select at least one charity partner",
                  icon: "error",
                });
              }
            } else {
              dispatch({
                type: "firstTimeCurrentPage",
                firstTimeCurrentPage: pageName,
              });
            }
          } else {
            Toast.fire({
              title: "Select at least one Islamic day or one personal day",
              icon: "error",
            });
          }
        }
      } else {
        dispatch({
          type: "firstTimeCurrentPage",
          firstTimeCurrentPage: pageName,
        });
      }
    }
    closeMenu();
  };

  const logoutHandler = async () => {
    await axios({
      method: "post",
      url: "/logout",
      data: { email: state.userInfo.email },
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        removeCookie("id");
        removeCookie("cookie");
        removeCookie("tokenId");
        removeCookie("refreshToken");
        dispatch({
          type: "userInfo",
          userInfo: null,
        });
        window.location.href = "/login";
      })
      .catch((err) => console.log(err));
  };

  let tabs = null;
  if (window.location.pathname == "/profile") {
    tabs = allPages.map((page) => {
      return (
        <li
          onClick={() => {
            page.name == "Logout" ? logoutHandler() : menuHandler(page.name);
          }}
          className={
            currentTab != page.name
              ? "py-4 pl-6 text-sm"
              : "bg-grey py-4 pl-6 text-sm text-camel"
          }
        >
          <a className="cursor-default">
            <img
              className="inline-block mr-2"
              src={
                currentTab == page.name
                  ? icons[page.name]?.active
                  : icons[page.name]?.inActive
              }
              alt="sidemenu"
            />
            {page.name == "TransactionDetails" ? null : page.title}
          </a>
        </li>
      );
    });
  } else if (window.location.pathname.startsWith("/donation-automation")) {
    tabs = firstTimePages.map((page) => {
      return (
        <li
          onClick={() => menuHandler(page.name)}
          className={
            currentTab != page.name
              ? "py-4 pl-6 text-sm"
              : "bg-grey py-4 pl-6 text-sm text-camel"
          }
        >
          <a className="cursor-default">
            <img
              className="inline-block mr-2"
              src={
                currentTab == page.name
                  ? icons[page.name]?.active
                  : icons[page.name]?.inActive
              }
              alt="sidemenu"
            />
            {page.title}
          </a>
        </li>
      );
    });
  }

  return (
    <div className="h-full shadow-2xl">
      <ul className="mt-12">{tabs}</ul>
      <button
        className="absolute top-3 right-3 cursor-default"
        onClick={closeMenu}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
    </div>
  );
}

export default Menu;
