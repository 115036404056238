import React, { useState, useContext, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { Context } from "../../utils/store";
import bg_blue from "../../assets/first_time/frequency_blue.png";
import bg_green from "../../assets/first_time/frequency_green.png";
import { relativeTimeRounding } from "moment";
import select from "../../assets/icons/select.png";
import selected from "../../assets/icons/selected.png";
import { useWindowSize } from "../../utils/useWindowSize";
import smoothscroll from "smoothscroll-polyfill";

export default function Frequency() {
  const { state, dispatch } = useContext(Context);

  const [donationFreq, setDonationFreq] = useState<String>("");
  const size = useWindowSize();

  useEffect(() => {
    setDonationFreq(state?.firstTimeInfo?.donationFreq);
  }, [state.firstTimeInfo]);

  const setDonationFreqHandler = (freqValue: string) => {
    if (size.width < 768) {
      smoothscroll.polyfill();
      window.scrollTo({ top: size.height, left: 0, behavior: "smooth" });
    }
    const prevOtherDays = state.firstTimeInfo && state.firstTimeInfo.otherDays ? [...state.firstTimeInfo.otherDays] : null;
    let nextOtherDays = prevOtherDays ? [...prevOtherDays] : null;
    if (freqValue === 'oneTime' && prevOtherDays) {
      nextOtherDays = prevOtherDays.map(o => ({...o, isSelected: false}));
    }
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, donationFreq: freqValue, otherDays: nextOtherDays },
    });
  };

  return (
    <>
      <div
        style={{ maxWidth: 1100 }}
        className="lg:gap-x-2 2xl:-ml-3 gap-x-12 grid grid-cols-1 xl:grid-cols-2 md:gap-x-10 mb-6 md:mb-10 md:gap-y-0 sm:gap-y-0 2xl:mx-0 freq-card-outer"
      >
        <Block
          title="Recurring"
          name="reccuring"
          text="Our most convenient option - schedule it and never miss it. Every
              year, you will be donating on the days you select to your
              favorite charities and causes - unless you decide to cancel.
              Never miss another important day."
          img={bg_blue}
          action={() => setDonationFreqHandler("recurring")}
        />
        <Block
          title="One-Time"
          name="oneTime"
          action={() => setDonationFreqHandler("oneTime")}
          text="Whether you’re looking to make a donation today or schedule Islamic and personal days that will be charged only once  - this option is for you. Give today or on the days you select and come back to create new schedules whenever you want."
          img={bg_green}
        />
      </div>
    </>
  );
}

function Block(props: any) {
  const { state, dispatch } = useContext(Context);

  const [donationFreq, setDonationFreq] = useState<String>("");

  useEffect(() => {
    setDonationFreq(state?.firstTimeInfo?.donationFreq);
  }, [state.firstTimeInfo]);

  return (
    <div
      className="place-self-auto 2xl:max-w-525 freq-card-main"
      style={{
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
      }}
    >
      <div className="z-20 mb-4 md:mb-8 2xl:mb-0 self-center 2xl:self-start md:mr-2">
        <div className="w-auto relative">
          {/* <img
            src={props.img}
            className="w-96 xl:w-full 2xl:max-w-525 h-56 md:h-64 2xl:h-80"
          /> */}
          <div className="relative fq-items top-0 left-0 w-96 max-w-full xl:w-full 2xl:w-auto 2xl:max-w-525 h-56 md:h-64 xl:h-72 2xl:h-80 rounded-sm z-20 p-4 freq-card-inner">
            <h2
              style={{ textShadow: "0px 3px 6px #00000029", paddingTop: 15 }}
              className="px-2 sm:px-4 lg:px-4 xl:px-6 md:pt-4 pb-2 md:pb-4 w-full text-white font-PoppinsMedium 2xl:text-xl freq-card-title"
            >
              {props.title}
            </h2>
            <p
              className={`px-2 sm:px-4 lg:px-4 xl:px-6 w-full opacity-80 font-Poppins text-xs md:text-xs sm:text-xs text-white xl:text-sm 2xl:text-base  ${
                props.name == "oneTime" ? "lg:pb-4 sm:-mb-0" : "pb-10"
              }`}
            >
              {props.text}
            </p>
            <button
              onClick={props.action}
              className={`   ${
                props.style
              } absolute bottom-4 left-1/2 transform -translate-x-1/2 rounded flex flex-row
            ${
              (donationFreq == "recurring" && props.name == "reccuring") ||
              (donationFreq == "oneTime" && props.name == "oneTime")
                ? `mb-1 mt-2 ${
                    props.name == "reccuring"
                      ? "bg-bismillahBlue2"
                      : "bg-green-700"
                  } text-xs md:text-base text-white z-20 ${
                    props.name == "oneTime" ? " " : ""
                  }  focus:outline-none shadow-lg py-2 2xl:py-3 px-6 md:px-12  mx-auto `
                : `mb-1 mt-2  ${
                    props.name == "oneTime" ? "bg-darkGreen" : "bg-blue-400"
                  }   text-xs md:text-base  text-white  z-20 ${
                    props.name == "oneTime" ? "" : ""
                  }   focus:outline-none shadow-lg py-2 2xl:py-3 px-6 md:px-12  mx-auto `
            }
                  `}
            >
              <div
                className={
                  (donationFreq == "recurring" && props.name == "reccuring") ||
                  (donationFreq == "oneTime" && props.name == "oneTime")
                    ? "bg-green-400 w-4 h-4 md:w-5 md:h-5 mr-3 rounded-full"
                    : "bg-white w-4 h-4 md:w-5 md:h-5 mr-3 rounded-full"
                }
              >
                <img
                  // style={{height:9}}
                  className="md:mt-1.5 mt-1 h-2 w-2 md:w-3 md:h-2.5 ml-auto mr-auto"
                  src={
                    (donationFreq == "recurring" &&
                      props.name == "reccuring") ||
                    (donationFreq == "oneTime" && props.name == "oneTime")
                      ? select
                      : selected
                  }
                />
              </div>
              Bismillah
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
