import React, { useState, useEffect, useReducer } from "react";
import Button from "../components/Button";
import axiosUtility from "../utils/axios";
import { useHistory } from "react-router";
import charity_partner from "../assets/charity_partner.png";
import SkeletonLoader from "../components/SkeletonLoader";
import { reducer, initialState } from "../utils/store";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
export default function CharityPartners() {
  const [charities, setCharities] = useState<Array<any> | null>(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    Mixpanel.track("View Partners Page");
    axiosUtility
      .get("/charities")
      .then((response) => {
        let newCharities = [];
        response.data.charities.forEach((oneCharity) => {
          if (oneCharity.isActive) {
            newCharities.push(oneCharity);
          }
        });
        setCharities(newCharities);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }, [0]);

  let charityBlocks = null;
  if (charities) {
    charityBlocks = charities.map((el, index) => {
      // console.log(el)
      return <CharityBlock {...el} img={el.logo} key={index} />;
    });
  }

  return (
    <div>
      <Helmet>
        <title>Charity Partners | CharityMoments</title>
        <meta
          name="description"
          content="Learn about the trusted charity partners we work with to ensure your donations are making the maximum impact."
        />
      </Helmet>
      <img
        className="hidden md:flex absolute w-screen top-0"
        src={charity_partner}
      />
      <Block>
        <h1 className="text-2xl md:text-4xl 2xl:text-8xl mb-8 md:mb-9 text-center page-title-text">
          Charity Partners
        </h1>
        <p className="text-sm 2xl:text-2xl font-PoppinsMedium text-black text-center pb-4">
          Learn more about the charitable organizations we work with.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 md:gap-x-8 justify-items-center mt-5">
          <SkeletonLoader showShadow={true} showLoader={showLoader} />
          <SkeletonLoader showShadow={true} showLoader={showLoader} />
          <SkeletonLoader showShadow={true} showLoader={showLoader} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mb-10 charity-partner-card-main">
          {charityBlocks}
        </div>
      </Block>
    </div>
  );
}

const Block = (props: any) => {
  return (
    <div className="bg-white rounded-2xl px-4 sm:px-8 md:px-16 mx-4 md:mx-8 lg:px-40 2xl:px-80 pt-6 md:pt-24 md:pb-24">
      {props.children}
    </div>
  );
};

const CharityBlock = (props: any) => {
  let history = useHistory();
  const navigate = () => {
    history.push(`/charity-partners/${props._id}`);
  };
  return (
    // <div className="bg-white  relative shadow-round rounded-2xl  w-full md:mx-0 mt-6 text-center 2xl:pt-8 lg:pt-5 sm:pt-5 pt-2">
    //   <div className="p-4 h-24 md:h-24 lg:h-24 flex flex-wrap  content-center  ">
    //     <img className="mx-auto max-w-24 xl:w-52 max-h-24 lg:w-40 xl:max-h-32   md:w-52 md:max-h-32  " src={props.img} />
    //   </div>
    //   <div className='px-5 2xl:px-12'>
    //     <Button
    //       action={navigate}
    //       width="60"
    //       style="mt-6 mb-4 rounded-lg w-full h-10 2xl:h-12 md:py-2 2xl:py-3 shadow-camel "
    //       title="Learn More"
    //     />
    //   </div>
    // </div>
    <div className="h-56 shadow-round relative z-20  rounded-lg mb-5 mx-2">
      <div className="flex h-36 p-4 pb-0 flex items-center content-center">
        <img className="mx-auto max-h-24" src={props.img} alt="articleimage" />
      </div>

      <div className="px-5 2xl:px-12 ">
        <Button
          action={navigate}
          width="60"
          style="mt-2 mb-4 rounded-lg w-full h-10 2xl:h-12 md:py-2 2xl:py-3 shadow-camel "
          title="Learn More"
        />
      </div>
    </div>
  );
};
