import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../utils/store";
import axios from "../../utils/axios";
import editIcon from "../../assets/icons/change.svg";
import ArrowLeft from "../../assets/icons/ArrowLeft.png";
import { FiCheck } from "react-icons/fi";
import DayBlock from "../../components/DayBlock";
import Button from "../../components/Button";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import axiosUtility from "../../utils/axios";
import Swal from "sweetalert2";
import CheckBox from "../../components/CheckBox";
import InfoCircle from "../../components/InfoCircle";
import InfoMsg from "../../components/InfoMsg";
import { confirmAlert } from "react-confirm-alert";
import { MiToggleSwitch } from "mi-toggle-switch";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function DonationDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();

  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [scheduleStatus, setScheduleStatus] = useState(null);
  const [islamicDays, setIslamicDays] = useState(null);
  const [personalDays, setPersonalDays] = useState(null);
  const [otherDays, setOtherDays] = useState(null);
  const [charityName, setCharityName] = useState([]);
  const [cause, setCause] = React.useState();
  const [cookies, setCookie] = useCookies();

  const [islamicDaysCard, setIslamicDaysCard] = useState(true);
  const [personalDaysCard, setPersonalDaysCard] = useState(true);
  const [otherDaysCard, setOtherDaysCard] = useState(true);

  useEffect(() => {
    setScheduleId(props.scheduleData._id);
    setScheduleData(props.scheduleData);
    setIslamicDays(props.scheduleData.islamicDays);
    setPersonalDays(props.scheduleData.personalDays);
    setOtherDays(props.scheduleData.otherDays);
    setScheduleStatus(props.scheduleData.isActive);
  }, [props.scheduleData]);

  useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      let newCharities = [];
      response.data.charities.forEach((oneCharity) => {
        props.scheduleData.charities.map((one) => {
          if (oneCharity._id == one) {
            newCharities.push([oneCharity.name, oneCharity._id]);
            setCharityName(newCharities);
          }
        });
        // console.log(oneCharity)
      });
    });
  }, [0]);

  const toggleStatus = (status: any) => {
    setScheduleStatus(status);
  };

  const handleDetailsCard = (details: any) => {
    if (details == "islamicDays") {
      setIslamicDaysCard(false);
    }

    if (details == "personalDays") {
      setPersonalDaysCard(false);
    }

    if (details == "otherDays") {
      setOtherDaysCard(false);
    }
  };

  const addNewScheduleHandler = () => {
    dispatch({
      type: "firstTimeCurrentPage",
      firstTimeCurrentPage: "Frequency",
    });
    history.push("/donation-automation");
  };
  const removeScheduleHandler = async () => {
    confirmAlert({
      title: "Delete Donation Schedule",
      message: "Are you sure to delete this donation schedule.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDonationSchedule(),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  const inactiveScheduleHandler = async () => {
    confirmAlert({
      title: "Deactivate Schedule",
      message:
        "This action cannot be undone. Are you sure you want to deactivate the schedule?",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeDonationScheduleStatus(),
        },
        {
          label: "No",
          onClick: () => console.log(""),
        },
      ],
    });
  };

  const deleteDonationSchedule = async () => {
    alert(props.scheduleData._id);
    const response = await axios({
      url: "/donationschedule/delete",
      method: "POST",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: JSON.stringify({ id: props.scheduleData._id }),
    })
      .then((response) => {
        Toast.fire({
          title: "Schedule has been deleted",
          icon: "success",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        Toast.fire({
          title: `${err}`,
          icon: "error",
        });
      });
  };

  const changeDonationScheduleStatus = async () => {
    const response = await axios({
      url: "/donationschedule/update-status",
      method: "POST",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
        "Content-type": "application/json",
      },
      data: {
        id: props.scheduleData._id,
        isActive: !scheduleStatus,
      },
    })
      .then((res) => {
        setScheduleStatus(!scheduleStatus);
        Toast.fire({
          title: "Donation Schedule updated successfully.",
          icon: "success",
        });
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        Toast.fire({
          title: `${err}`,
          icon: "error",
        });
      });
  };

  let donationBlocks = null;

  // donationBlocks=props.scheduleData.contributions.map((cause, index) => {
  //   return(
  //     <div className="table-row"><p className=" 2xl:text-base text-sm table-cell text-left px-6 py-1.5"></p>
  //   <p  key={index} className="flex-auto font-Poppins self-end text-right mb-auto table-cell text-sm 2xl:text-base ">
  //        {cause.name}
  //         </p></div>
  //  )
  // })

  donationBlocks = props.scheduleData.contributions.map((cause, index) => {
    return (
      <div className="flex flex-row">
        <p className=" 2xl:text-base  text-sm table-cell text-left px-6 py-1.5"></p>
        <p
          className=" 2xl:text-base text-sm table-cell text-left font-Poppins px-6  py-1"
          key={index}
        >
          {cause.name}
        </p>
        <p className=" text-sm 2xl:text-base text-black ml-auto  py-1 font-Poppins ">
          ${cause.amount}
        </p>
      </div>
    );
  });
  // console.log(charityName)
  //console.log(props.scheduleData);

  const [charityArray, setCharityArray] = React.useState([]);

  React.useEffect(() => {
    axiosUtility.get("/charities").then((response) => {
      setCharityArray(response.data.charities);
    });
  }, [0]);
  const mapping = charityArray.map((charity, index) => {
    // console.log(charity)

    // props.scheduleData.contributions.map((cause, index) => {
    //   if(cause.parentCharity == charity._id)
    //   {
    //     console.log(cause)
    //   }
    // })
    const isCharityFound = props.scheduleData.contributions.some(
      (el) => el.parentCharity === charity._id
    );
    if (isCharityFound) {
      return (
        <>
          <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
            Charity: {charity.name}
          </p>
          <span className="text-brownGrey ml-2 w-full  grid ">
            {props.scheduleData.contributions.map((cause, index) => {
              if (cause.parentCharity == charity._id) {
                return (
                  <>
                    <div className="">
                      <p className="flex-auto font-PoppinsMedium self-end text-left mb-auto text-sm 2xl:text-base text-black mr-2 w-full flex"></p>
                      <span>{cause.name}</span>
                      <span className="float-right ml-auto text-sm 2xl:text-base text-black  font-Poppins ">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(cause.amount)}
                      </span>
                    </div>
                    <br />
                  </>
                );
              }
            })}
          </span>
        </>
      );
    }
  });
  // axiosUtility
  // .get("/charities")
  // .then((response) => {
  //   response.data.charities.forEach((oneCharity) => {
  //     props.scheduleData.charities.map((one) => {
  //       if (oneCharity._id == one) {
  //         return(<p>{oneCharity.name}</p>)
  //       }
  //     })
  //     // console.log(oneCharity)

  //   })

  // })

  return (
    <div className="z-20 relative">
      {!scheduleStatus && (
        <div
          className="p-4 mb-4 text-sm text-red-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
          role="alert"
        >
          The Schedule is inactive as per your request. We will not process any
          future transaction for this schedule. Past transaction processed for
          the Schedule can be reviewed under Transaction History.
        </div>
      )}
      <div className="mt-8 flex flex-wrap">
        <span className="flex flex-nowrap ml-1 mt-2 items-center">
          <img
            onClick={() => props.setDonationsDetailsShown(false)}
            className="inline-block  transform -translate-y-1 h-4 md:h-auto mr-4 mb-6 relative z-20"
            src={ArrowLeft}
          />
          <h3 className="inline-block -mt-2 text-xl xl:text-2xl mr-2 mt-0 xl:-ml-0 ml-4 lg:ml-2 mb-6 z-50 font-Montserrat">
            View Your Schedule#{props.scheduleNumber} For Donation
          </h3>
        </span>
        <div className="ml-auto 2xl:-mt-2 text-right sche-for-donate">
          <button
            onClick={addNewScheduleHandler}
            className="mb-3 inline-block px-3 py-3 focus:outline-none bg-camel rounded text-white text-xs md:text-base font-Poppins"
          >
            Add New Donation Schedule
          </button>
          {/* <button
            onClick={removeScheduleHandler}
            className="mb-3 inline-block px-3 py-3 focus:outline-none bg-red rounded text-white text-xs md:text-base ml-4 font-Poppins"
          >
            Delete Schedule
          </button> 

          <div className="inline-block">
            <span className="ml-3">Inactive</span>
            <MiToggleSwitch
              activeColor="#68d56f"
              deactiveColor="red"
              isEnable={scheduleStatus}
              style={{ top: "10px", marginLeft: 10 }}
              onClick={changeDonationScheduleStatus}
            />
            <span className="ml-2">Active</span>
          </div>
          */}
          {scheduleStatus && (
            <button
              onClick={inactiveScheduleHandler}
              className="mb-3 inline-block px-3 py-3 focus:outline-none bg-red rounded text-white text-xs md:text-base ml-4 font-Poppins"
            >
              Deactivate Schedule
            </button>
          )}
        </div>
      </div>
      <hr className="mb-6" />
      <div className="grid lg:grid-cols-2 gap-x-3 2xl:flex">
        <div>
          <Info
            totalDays={
              scheduleData?.islamicDays?.length +
              scheduleData?.personalDays?.length +
              scheduleData?.otherDays?.length
            }
            totalDonation={props.scheduleData.totalDonationAmount}
            cause={donationBlocks}
            total={
              props.scheduleData.coverTransactionFee
                ? props.scheduleData.totalDonationAmount +
                  props.scheduleData.processingfee
                : props.scheduleData.totalDonationAmount
            }
            sunnah={props.scheduleData.otherDays.length}
            personal={props.scheduleData.personalDays.length}
            islamicdays={props.scheduleData.islamicDays.length}
            frequency={props.scheduleData.frequency}
            amount={props.scheduleData.totalDonationAmount}
            transactionFee={props.scheduleData.coverTransactionFee}
            fee={props.scheduleData.processingfee.toFixed(2)}
            charity={charityName.map((name) => {
              return (
                <>
                  {name} <br />
                </>
              );
            })}
            coverTransactionFee={props.scheduleData.coverTransactionFee}
          />
        </div>
        <div>
          <SecondCard
            amount={props.scheduleData.totalDonationAmount}
            charityBlock={mapping}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-4">
        {islamicDaysCard && scheduleData?.islamicDays?.length > 0 ? (
          <DaysBlock
            scheduleId={props.scheduleData._id}
            dayBlockType={"IslamicDays"}
            scheduleData={props.scheduleData}
            toggleStatus={toggleStatus}
            handleDetailsCard={handleDetailsCard}
          />
        ) : null}
        {personalDaysCard && scheduleData?.personalDays?.length > 0 ? (
          <DaysBlock
            scheduleId={props.scheduleData._id}
            dayBlockType={"PersonalDays"}
            scheduleData={props.scheduleData}
            toggleStatus={toggleStatus}
            handleDetailsCard={handleDetailsCard}
          />
        ) : null}
        {otherDaysCard && scheduleData?.otherDays?.length > 0 ? (
          <DaysBlock
            scheduleId={props.scheduleData._id}
            dayBlockType={"OtherDays"}
            scheduleData={props.scheduleData}
            toggleStatus={toggleStatus}
            handleDetailsCard={handleDetailsCard}
          />
        ) : null}
      </div>
    </div>
  );
}
const SecondCard = (props: any) => {
  return (
    <>
      <div className="2xl:w-28rem mb-5 w-full justify-self-center xl:justify-items-auto bg-white rounded-xl shadow-round px-4 md:px-10 py-5 col-span-8 xl:col-span-4">
        {props.charityBlock}
        <div className="border-b-2 mb-4 opacity-75"></div>
        <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
          Donation Amount:
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black amount">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.amount)}
          </span>
        </p>
      </div>
    </>
  );
};

const Info = (props: any) => {
  const [state, setState] = React.useState({
    checkedA: true,
  });

  const [isTransactionFeesInfo, setIsTransactionFeesInfo] = useState(false);
  const [coverFee, setCoverFee] = React.useState(0.0);
  return (
    <>
      {/* <div className="bg-lightGrey w-screen\2 mt-4 px-8 rounded-xl py-6 relative z-20"> */}
      <div className="2xl:w-28rem mb-5 w-full justify-self-center xl:justify-items-auto bg-white rounded-xl shadow-round px-4 md:px-10 py-5 col-span-8 xl:col-span-4">
        <p className="font-bold text-black text-base 2xl:text-xl mb-2 font-PoppinsMedium">
          Summary
        </p>
        <p className="text-black text-sm 2xl:text-base mb-2 font-PoppinsMedium">
          Frequency: {props.frequency == "recurring" ? "Reccuring" : "One Time"}
        </p>
        <p className="flex mt-3 text-sm 2xl:text-base mb-2 font-PoppinsMedium ">
          Islamic Days:
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base ">
            {" X "}
            {props.islamicdays}
          </span>
        </p>
        <p className="flex mt-3 text-sm 2xl:text-base mb-2 font-PoppinsMedium ">
          Personal Days:
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base ">
            {" X "}
            {props.personal}
          </span>
        </p>
        <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium ">
          Sunnah Days:
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base ">
            {" X "}
            {props.sunnah}
          </span>
        </p>
        <div className="border-b-2 mb-4 opacity-75"></div>
        {/* {props.charityBlock} */}
        {/* <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
        C
        <span className="flex-auto font-PoppinsMedium self-end text-left ml-2 mb-auto text-sm 2xl:text-base text-black">
          {props.charity}
        </span>
      </p>
      <div className="table w-full ">
        <div className=" table-row-group  ">
          <p className="flex mt-3  text-sm 2xl:text-base mb-4 text-left table-cell font-PoppinsMedium text-black">
          
            <div className='-mt-7'></div>
            {props.cause}
          </p>
        </div></div> */}
        <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
          Total
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
            {props.totalDays}
          </span>
        </p>
        <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
          Total Donation
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.totalDonation)}
          </span>
        </p>
        {/* <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
        Donation 
        <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
          ${props.amount}
        </span>
      </p> */}
        {props.coverTransactionFee}
        <div className="flex mt-3 text-sm 2xl:text-base">
          <CheckBox
            // setIsChecked={settransactionFee}
            isDisabled={true}
            isChecked={props.transactionFee}
            label="Cover Transaction Fees"
          />
          <div className="relative flex-auto ml-2">
            <InfoMsg
              isShown={isTransactionFeesInfo}
              text="Make your gift go further! Covering the transaction fees allows us to send your donation amount in full to the charity partners. The transaction fees include processing fees associated with the payment processor, fees charged by the payment processor for payouts to charities and a platform fee to cover expenses such as the domain, hosting, servers, cloud databases, storage, ongoing product improvements, UI/UX design, and web development."
            />
            <InfoCircle
              isInfoTextShown={isTransactionFeesInfo}
              toggleInfoText={setIsTransactionFeesInfo}
            />
          </div>
          <span className="flex-auto self-end text-right mb-auto text-sm 2xl:text-base text-black">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.fee)}
          </span>
        </div>
        {/* <p className="flex mt-3 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black ">
       Cover Transactio<span className="flex-auto self-end text-right mb-auto text-sm 2xl:text-base text-black">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((props.fee))}
        </span></p> */}
        <p className="flex mt-3  text-sm 2xl:text-base mt-6 text-left font-PoppinsMedium text-black">
          Total Charge per Selected Day
          <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
            {/* ${props.total.toFixed(2)} */}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.total.toFixed(2))}
          </span>
        </p>

        {/* </div> */}
      </div>
    </>
  );
};

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 5000,
});

const DaysBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);
  const [isEdit, setIsEdit] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [islamicDays, setIslamicDays] = useState(null);
  const [personalDays, setPersonalDays] = useState(null);
  const [otherDays, setOtherDays] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [shedules, setSchedules] = useState(null);
  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(null);

  useEffect(() => {
    let currentScheduleIndex = state?.donationSchedules?.findIndex(
      (schedule) => {
        if (schedule._id == props.scheduleId) {
          return true;
        }
      }
    );
    if (currentScheduleIndex >= 0) {
      const { islamicDays, personalDays, otherDays } =
        state?.donationSchedules[currentScheduleIndex];
      setIslamicDays(islamicDays);
      setPersonalDays(personalDays);
      setOtherDays(otherDays);
      setScheduleData(props.scheduleData);
      setSchedules(state.donationSchedules);
      setCurrentScheduleIndex(currentScheduleIndex);
    }
  }, [state]);

  const removeDayHandler = (dayType, id) => {
    let newDonationSchedules = [...shedules];
    let newIslamicDays = [...islamicDays];
    let newPersonalDays = [...personalDays];
    let newOtherDays = [...otherDays];

    if (dayType == "islamicDays") {
      let dayIndex = newIslamicDays.findIndex((day) => {
        if (day._id == id) {
          return true;
        }
      });
      newIslamicDays.splice(dayIndex, 1);
      newDonationSchedules[currentScheduleIndex].islamicDays = newIslamicDays;
      dispatch({
        type: "donationSchedules",
        donationSchedules: newDonationSchedules,
      });
    }

    if (dayType == "personalDays") {
      let dayIndex = newPersonalDays.findIndex((day) => {
        if (day._id == id) {
          return true;
        }
      });
      newPersonalDays.splice(dayIndex, 1);
      newDonationSchedules[currentScheduleIndex].personalDays = newPersonalDays;
      dispatch({
        type: "donationSchedules",
        donationSchedules: newDonationSchedules,
      });
    }

    if (dayType == "otherDays") {
      let dayIndex = newOtherDays.findIndex((day) => {
        if (day._id == id) {
          return true;
        }
      });
      newOtherDays.splice(dayIndex, 1);
      newDonationSchedules[currentScheduleIndex].otherDays = newOtherDays;

      dispatch({
        type: "donationSchedules",
        donationSchedules: newDonationSchedules,
      });
    }
  };
  // console.log(state.donationSchedules)
  const saveHandler = async () => {
    setIsEdit(true);
    let isActive;

    if (
      islamicDays?.length == 0 &&
      personalDays?.length == 0 &&
      otherDays?.length == 0
    ) {
      isActive = false;
    } else {
      isActive = true;
    }

    const response = await editDonationSchedule({
      id: props.scheduleId,
      islamicDays: islamicDays,
      personalDays: personalDays,
      otherDays: otherDays,
      isActive: isActive,
    });
  };
  const editDonationSchedule = async ({
    id,
    islamicDays,
    personalDays,
    otherDays,
    isActive,
  }) => {
    const response = await axios({
      url: "/donationschedule/edit",
      method: "POST",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: JSON.stringify({
        islamicDays,
        personalDays,
        otherDays,
        id,
        isActive,
      }),
    })
      .then((response) => {
        if (
          islamicDays?.length == 0 &&
          personalDays?.length == 0 &&
          otherDays?.length == 0
        ) {
          props.toggleStatus(false);
        } else {
          props.toggleStatus(true);
        }

        if (islamicDays?.length == 0) {
          props.handleDetailsCard("islamicDays");
        }
        if (personalDays?.length == 0) {
          props.handleDetailsCard("personalDays");
        }
        if (otherDays?.length == 0) {
          props.handleDetailsCard("otherDays");
        }
        Toast.fire({
          title: "Schedule has been changed",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        Toast.fire({
          title: `${err}`,
          icon: "error",
        });
      });
  };

  let days = null;
  if (props.dayBlockType == "IslamicDays") {
    days = islamicDays?.map((day, index) => {
      return (
        <DayBlock
          scheduleStyle={"ml-auto"}
          key={index}
          profileRemoveDayHandler={removeDayHandler}
          dayType="islamicDays"
          id={day._id}
          removeIconShown={!isEdit}
          isIslamicDays={true}
          dayOfMonth={day?.dayOfMonth}
          monthName={day.monthName}
          dayName={day.name}
          amountPerDay={
            scheduleData.coverTransactionFee
              ? (day.donationAmt + scheduleData.processingfee).toFixed(2)
              : day.donationAmt.toFixed(2)
          }
        />
      );
    });
  } else if (props.dayBlockType == "PersonalDays") {
    days = personalDays?.map((day, index) => {
      return (
        <DayBlock
          scheduleStyle={"ml-auto"}
          key={index}
          date={day.date}
          profileRemoveDayHandler={removeDayHandler}
          dayType="personalDays"
          id={day._id}
          removeIconShown={!isEdit}
          dayName={day.name}
          isPersonalDays={true}
          manualEntryText={day?.manualEntryText ? day?.manualEntryText : null}
          amountPerDay={
            scheduleData.coverTransactionFee
              ? (day.donationAmt + scheduleData.processingfee).toFixed(2)
              : day.donationAmt.toFixed(2)
          }
        />
      );
    });
  } else if (props.dayBlockType == "OtherDays") {
    days = otherDays?.map((day, index) => {
      return (
        <DayBlock
          scheduleStyle={"ml-auto"}
          key={index}
          profileRemoveDayHandler={removeDayHandler}
          dayType="otherDays"
          id={day?._id}
          removeIconShown={true}
          name={day?.name}
          isSelected={day?.isSelected}
          removeIconShown={!isEdit}
          amountPerDay={
            scheduleData.coverTransactionFee
              ? (day?.donationAmt + scheduleData.processingfee).toFixed(2)
              : (day?.donationAmt).toFixed(2)
          }
        />
      );
    });
  }

  let scheduleTitle =
    props.dayBlockType == "IslamicDays"
      ? "Islamic Days"
      : props.dayBlockType == "PersonalDays"
      ? "Personal Days"
      : "Sunnah Days";

  return (
    <div className="bg-lightGrey w-96 px-8 rounded-xl py-6 w-full relative z-20">
      <div className="grid grid-cols-2 mb-6">
        <h3 className="font-normal text-lg text-black">{scheduleTitle}</h3>
        {isEdit ? (
          <img
            onClick={() => setIsEdit(!isEdit)}
            className="justify-self-end self-center"
            src={editIcon}
          />
        ) : (
          <div
            onClick={() => saveHandler()}
            className="inline-block justify-self-end self-center bg-camel px-3 py-1 rounded-lg"
          >
            <span className="cursor-default text-white">
              Save
              <FiCheck className="ml-1 inline-block" />
            </span>
          </div>
        )}
      </div>
      <div>{days}</div>
    </div>
  );
};
