/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../../utils/store";
import axios from "../../utils/axios";
import { useCookies } from "react-cookie";
import membersince from "../../assets/icons/member_since.png";
import donate from "../../assets/icons/total_donated.png";
import days from "../../assets/icons/days_celebrated.png";
import email from "../../assets/icons/email.svg";
import password from "../../assets/icons/password.svg";
import phone from "../../assets/icons/phone.svg";
import addressImg from "../../assets/icons/address.svg";
import { InputProfileEdit } from "../../components/Input";
import edit from "../../assets/icons/change.svg";
import camera from "../../assets/icons/camera.svg";
import moment from "moment";
import element from "../../assets/grey_element.png";
import Swal from "sweetalert2";
import Geocode from "react-geocode";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
interface userInfo {
  mfaEnabled: boolean;
  firstName: String,
  lastName: String,
  joinedDate: any
  address: String,
  city: String,
  state: String,
  zipCode: String,
  country: String,
  userImage: string,
  totalDonated: any,
  email: String,
  phone: any
}
export default function Profile() {
  const { state, dispatch } = useContext(Context);
  const [cookies] = useCookies();
  const [userInfo, setUserInfo] = useState<userInfo>(null);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const initialFormErrors = {
    errorCounter: 0,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    password: null,
    confirmPassword: null,
    name: null,
    address: null,
    city: null,
    stateValue: null,
    zipCode: null,
    country: null,
  };
  useEffect(() => {
    setAddress(userInfo?.address);
    setCountry(userInfo?.country);
    setZipCode(userInfo?.zipCode);
    setStateValue(userInfo?.state);
    setCity(userInfo?.city);
    setMfaEnabled(userInfo?.mfaEnabled)
  }, [userInfo]);
  const [formErrors] = React.useState(initialFormErrors);
  const [predictions, setPredictions] = useState<any>([]);
  const [address, setAddress] = React.useState(userInfo?.address);
  const [city, setCity] = React.useState(userInfo?.city);
  const [stateValue, setStateValue] = React.useState(userInfo?.state);
  const [zipCode, setZipCode] = React.useState(userInfo?.zipCode);
  const [country, setCountry] = React.useState(userInfo?.country);
  const [mfaEnabled, setMfaEnabled] = React.useState(userInfo?.mfaEnabled);
  const handlePredictionSelection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>, prediction: null) => {
    console.log("here it comes", prediction);
    e.preventDefault();
    Geocode.fromAddress(prediction.description).then(
      (response: any) => {
        if (response.results[0].address_components) {
          console.log(
            "geocode response to check",
            response.results[0].address_components
          );
          response.results[0].address_components.forEach((address: any) => {
            for (var j = 0; j < address.types.length; j++) {
              if (address.types[j] == "postal_code") {
                setZipCode(address.long_name);
              }
              if (address.types[j] == "country") {
                setCountry(address.long_name);
              }
              if (address.types[j] == "administrative_area_level_1") {
                setStateValue(address.short_name);
              }
              if (
                address.types[j] == "locality" ||
                address.types[j] == "sublocality"
              ) {
                setCity(address.long_name);
              }
            }
          });
        }

        // console.log("geocode", response);
      },
      (error: any) => {
        console.error("error", error);
      }
    );
    if (prediction !== null) {
      setAddress(prediction.structured_formatting.main_text);
      setPredictions([]);
    }
  };
  const getAllTransactions = async () => {
		await axios({
			method: "post",
			url: "/transactions",
			headers: {
				Authorization: `Bearer ${cookies.tokenId}`,
			},
		}).then((response) => {
			// console.log(response);

			setTransactions(response.data.transactions.docs);
			// if(response.data.transactions.length > 0) setTableDataHandler();
			// dispatch({
			// 	type: 'transactionHistory',
			// 	transactionHistory: response.data.transactions
			// })
		});
	};

	React.useEffect(() => {
		if (!state?.transactionHistory) {
			getAllTransactions()
		}
		else {
			setTransactions(state?.transactionHistory)
		}

	}, [state])
  const googleAutocomplete = async (text: string) =>
    new Promise((resolve, reject) => {
      if (!text) {
        return reject("Need valid text input");
      }

      // for use in things like GatsbyJS where the html is generated first
      if (typeof window === "undefined") {
        return reject("Need valid window object");
      }

      try {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          { input: text, componentRestrictions: { country: "us" } },
          resolve
        );
      } catch (e) {
        reject(e);
      }
    });
  const handleAddressSearch = async (text: string) => {
    setAddress(text);
    const handleDebounce = setTimeout(async () => {
      try {
        if (text.length < 2) {
          setPredictions([]);
          return;
        }
        const nextPredictions: any = await googleAutocomplete(text);
        setPredictions(nextPredictions);
      } catch (e) {
        console.error(e);
      }
    }, 400);

    return () => {
      clearTimeout(handleDebounce);
    };
  };

  useEffect(() => {
    console.log("in profile page", state.userInfo, state.isLoggedIn);
    console.log("need to get userinfo again");
    getUserData();
    // getFunFacts();
  }, [0]);

  const getUserData = async () => {
    await axios({
      method: "post",
      url: "/getuser",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response) => {
        console.log(response.data.response);
        dispatch({
          type: "isLoggedIn",
          isLoggedIn: true,
        });
        //const responseData = await getFunFacts();
        //console.log("fun facts", responseData);
        dispatch({
          type: "userInfo",
          userInfo: {
            ...response.data.response,
           // ...responseData.data.response,
          },
        });
        setUserInfo({
          ...response.data.response,
          //...responseData.data.response,
        });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({
          type: "isLoggedIn",
          isLoggedIn: false,
        });
      });
  };
  const getFunFacts = async () => {
    return axios({
      method: "GET",
      url: "/funfacts",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    });
  };
  const sendVerification = async () => {
    console.log("comes here for password change");
    await axios({
      method: "post",
      url: "/forgot-password",
      data: { email: state.userInfo.email },
    })
      .then(() => {
        editPassword();
      })
      .catch((err) => {
        console.log(err.response);
        Toast.fire({
          title: err.response.data.response.message,
          icon: "warning",
        });
      });
  };

  const editPassword = async () => {
    console.log("comes here for password change");
    Swal.fire({
      title: "Change Password",
      confirmButtonColor: "#D0A342",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: `Change password`,
      showCloseButton: true,
      // footer: '<a href="/login">Forgot Password?</a>',
      html: `
      <p style="font-size:14px">A verification code has been sent to the supplied email address (${state.userInfo.email}). Please enter the verification code here alongwith the new password.</p>
      <input type="text" id="verifyCode" class="swal2-input" value="" placeholder="verification code">
  <input type="password" id="newPassword" class="swal2-input" className="email-alert-input" placeholder="Password">`,
      focusConfirm: false,
      preConfirm: () => {
        const verifyCode = Swal.getPopup().querySelector("#verifyCode").value;
        if (!verifyCode) {
          Swal.showValidationMessage(`Please enter verification code`);
        }
        const newPassword = Swal.getPopup().querySelector("#newPassword").value;
        if (!newPassword) {
          Swal.showValidationMessage(`Please enter the new password`);
        }
        return { verifyCode, newPassword };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const passwordData = {
          verifyCode: result?.value?.verifyCode,
          newPassword: result?.value?.newPassword,
          email: state.userInfo.email,
        };
        await axios({
          method: "post",
          url: "/verify-password",
          data: passwordData,
        })
          .then(async (response: any) => {
            if (response.data.message === "Success") {
              console.log(response.data);
              Toast.fire({
                title: response.data.response,
                icon: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
            Toast.fire({
              title: err.response.data.response.message,
              icon: "error",
            });
          });
      }
    });
  };

  const updatePhone = async () => {
    console.log(newPhone)
    await axios({
      method: "post",
      url: "/user/editphone",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { phone: '+'+newPhone },
    })
      .then(async (response) => {
        setEditPhone(false);
        Toast.fire({
          title: "Phone number has been updated",
          icon: "success",
        });
        await getUserData();
      })
      .catch((err) => {
        console.log(err.response);
        setEditPhone(false);
      });
  };
  const hiddenImageFileInput = useRef<any>(null);
  const handleImageClick = () => {
    hiddenImageFileInput.current.click();
  };
  const handleImageChange = (event: any) => {
    const imageFileObj = event.target.files[0];
    console.log(imageFileObj);
    uploadFileHandler(imageFileObj);
  };

  const uploadFileHandler = async (file: any) => {
    if (!file) {
      return false;
    }
    let formData = new FormData();
    formData.append("file", file);
    return await axios({
      method: "post",
      url: "/uploadimage",
      headers: {
        "Content-Type": `multipart/form-data`,
      },
      data: formData,
    })
      .then(async (response: any) => {
        console.log(response.data.file);
        setUserInfo({
          ...userInfo,
          userImage: response.data.file,
        });
        updateImage(response.data.file);
      })
      .catch((err) => {
        console.log(err.response);
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const updateImage = async (image: any) => {
    await axios({
      method: "post",
      url: "/user/editpicture",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { userImage: image },
    })
      .then(async (response) => {
        setUserInfo({
          ...userInfo,
          userImage: response.data.response.userImage,
        });
        setEditPhone(false);
      })
      .catch((err) => {
        console.log(err.response);
        setEditPhone(false);
      });
  };

  const updateAddress = async () => {
    const newFields = {
      address: address,
      state: stateValue,
      city: city,
      zipCode: zipCode,
      country: country,
    };
    await axios({
      method: "post",
      url: "/user/editaddress",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: newFields,
    })
      .then(async (response) => {
        console.log(response);
        setEditAddress(false);
        getUserData();
      })
      .catch((err) => {
        console.log(err.response);
        setEditAddress(false);
      });
  };
  const [transactions, setTransactions] = React.useState<any>(null);

  React.useEffect(() => {
    console.log(transactions);
  }, [transactions]);
  React.useEffect(() => {
    const getAllTransactions = async () => {
      await axios({
        method: "post",
        url: "/transactions",
        headers: {
          Authorization: `Bearer ${cookies.tokenId}`,
        },
      }).then((response) => {
        console.log(response);
        setTransactions(response.data.transactions.docs);
      });
    };
    getAllTransactions();
  }, [0]);

  // const [toggleState, setToggleState] = useState("off");

  const toggle = async () => {
    const isMfaEnabled = mfaEnabled === false ? true : false;
    await axios({
      method: "post",
      url: "/user/changemfa",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { email: userInfo.email, toggleMfa: isMfaEnabled },
      // we need to send either the user's email or cognito username
    })
      .then(async (response) => {
        Toast.fire({
          title: `2FA has been ${isMfaEnabled ? 'activated' : 'deactivated'}!`,
          icon: isMfaEnabled ? 'success' : 'error',
        });
        console.log(response)
        setUserInfo({
          ...userInfo,
          mfaEnabled: response.data.response.mfaEnabled,
        });
        setMfaEnabled(response.data.response.mfaEnabled);
      })
      .catch((err) => {
        Toast.fire({
          title: "There was some error updating your 2FA preference, please try again later.",
          icon: "error",
        });
        console.log(err.response);
      });
  }
  let total = 0;
  return userInfo ? (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-7 gap-y-4">
      <Helmet>
        <title>User Profile | CharityMoments</title>
      </Helmet>
      <div className="2xl:-mr-10">
        <div
          style={{ backgroundColor: "#faf6ec" }}
          className="0 p-4 2xl:p-6 px-8 rounded-3xl"
        >
          <p className="font-Montserrat text-black 2xl:text-4xl md:text-2xl text-xl mt-6">
            <span className="text-camel">Salaam</span> {userInfo?.firstName}!
          </p>
          <p className="2xl:text-base md:text-sm text-sm mb-6 mt-3">
            May Allah bless you and your loved ones with everything good in this
            world and the next. As you celebrate the days of Allah, your
            generous aid helps and comforts thousands of people around the
            world. Thank you so much for being a part of the CharityMoments
            family.
          </p>
        </div>
        <div className="px-4">
          <p className="text-black 2xl:text-xl md:text-base text-base  font-PoppinsSemiBold mb-4 mt-9">
            Fun Facts
          </p>
          <div
            style={{ backgroundColor: "#fcfcfc" }}
            className="rounded-3xl py-8 px-6 -mx-5 grid grid-cols-1 gap-y-4"
          >
            <div
              style={{ boxShadow: "1px 3px 20px #71ABEE1C" }}
              className="flex flex-row bg-white  relative rounded-3xl ml-10"
            >
              <div className="flex flex-row -ml-10">
                <img
                  src={membersince}
                  className="2xl:w-40 lg:w-32 md:w-28 sm:w-28 w-20 mr-4 2xl:mr-10 "
                  alt="member-date"
                />
                <p className="text-black 2xl:text-base text-sm font-PoppinsSemiBold mt-auto mb-auto">
                  Member Since
                  <br />
                  <span className="font-Poppins text-brownGrey">
                    {moment(userInfo?.joinedDate).format("DD MMM, YYYY")}
                  </span>
                </p>
              </div>
              <img
                src={element}
                className="absolute md:flex hidden -bottom-8 right-16 "
                alt="total-donated"
              />
              <img
                src={element}
                className="absolute md:flex hidden -top-8 -right-8 "
                alt="total-donated"
              />
            </div>
            <div
              style={{ boxShadow: "1px 3px 20px #71ABEE1C" }}
              className="flex flex-row bg-white relative rounded-3xl ml-10 "
            >
              <div className="flex flex-row -ml-10">
                <img
                  src={donate}
                  className="2xl:w-40 lg:w-32 md:w-28 sm:w-28 w-20 mr-4 2xl:mr-10 "
                  alt="total-donated"
                />
                <p className="text-black 2xl:text-base text-sm font-PoppinsSemiBold mt-auto mb-auto">
                  Total Donated
                  <br />
                  <span className="font-Poppins text-brownGrey">
                    {
                      transactions?.map((oneTransaction) => {
                        total += oneTransaction.transactionAmount       
                    })}
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((total).toFixed(2)))}
                  </span>
                </p>
              </div>
              <img
                src={element}
                className="absolute md:flex hidden -bottom-8 z-auto right-16 "
              />
              <img
                src={element}
                className="absolute md:flex hidden -top-8 -right-8 "
              />
            </div>
            <div
              style={{ boxShadow: "1px 3px 20px #71ABEE1C" }}
              className="flex flex-row bg-white relative rounded-3xl ml-10 "
            >
              <div className="flex flex-row -ml-10">
                <img
                  src={days}
                  className="2xl:w-40  lg:w-32 md:w-28 sm:w-28 w-20 mr-4 2xl:mr-10 "
                  alt="days-celebrated"
                />
                <p className="text-black 2xl:text-base text-sm font-PoppinsSemiBold mt-auto mb-auto">
                  Days Celebrated
                  <br />
                  <span className="font-Poppins text-brownGrey">
                    {transactions?.length} Days
                  </span>
                </p>
              </div>
              <img
                src={element}
                className="absolute md:flex hidden  -bottom-8 right-16 "
                alt="days-celebrated"
              />
              <img
                src={element}
                className="absolute md:flex hidden  -top-8 -right-8 "
                alt="days-celebrated"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#fbfbfb" }}
        className="rounded-3xl relative 2xl:ml-10  "
      >
        <div className="relative w-48 mx-auto mt-8 md:mt-10 py-2 bg-white rounded-xl">
          <img
            src={userInfo?.userImage}
            alt="user"
            className="ml-auto mr-auto z-20 max-h-36"
          />
          <div className="bg-white p-2 rounded-lg w-8 ml-auto -mt-4 mr-8 relative z-30">
            <img
              src={camera}
              alt="camera-icon"
              onClick={() => handleImageClick()}
            />
          </div>
          <input
            type="file"
            ref={hiddenImageFileInput}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
        <p className="text-black font-PoppinsSemiBold text-3xl mt-4 mb-8 md:mb-16 px-3 md:px-0 text-center">
          <span className="text-center">{userInfo?.firstName}</span>{" "}
          <span>{userInfo?.lastName}</span>{" "}
        </p>
        <div className="bg-white px-3 sm:px-5 py-4 md:pt-8 md:pb-4 mb-3 gap-y-10 rounded-3xl sm:mx-3">
          <div className="table w-full z-30">
            <div className=" table-row-group">
              <div className="table-row ">
                <img
                  src={email}
                  style={{ minWidth: 10 }}
                  className="-mb-1 mr-3 sm:mr-1 lg:mr-2 xl:mr-3 flex"
                  alt="email icon"
                />
                <p className="2xl:text-base text-sm table-cell flex  ">Email</p>

                <p className="2xl:text-base text-sm table-cell pr-5 ">:</p>
                <p className="text-black 2xl:text-base text-sm table-cell font-PoppinsMedium break-all">
                  {userInfo?.email}
                </p>
              </div>
              <div className="table-row z-auto">
                <img src={password} className="-mb-1 mt-10 flex" />
                <p className="2xl:text-base text-sm table-cell ">Password</p>
                <p className="2xl:text-base text-sm table-cell">:</p>
                <p className="text-black 2xl:text-base text-sm table-cell grid grid-cols-2 font-PoppinsMedium ">
                  ********
                  <span
                    className="z-30 cursor-pointer"
                    onClick={() => sendVerification()}
                  >
                    <img
                      className="z-30"
                      style={{ minWidth: 10, width: 19 }}
                      src={edit}
                      alt="edit password icon"
                    />
                  </span>
                </p>
              </div>
              <div className="table-row z-30">
                <img
                  src={phone}
                  className="-mb-1 mt-10 flex"
                  alt="phone_icon"
                />
                <p className="2xl:text-base text-sm table-cell">Phone</p>
                <p className="2xl:text-base text-sm table-cell">:</p>
                {!editPhone ? (
                  <p className="w-52 md:w-80 xl:w-52 2xl:w-64  flex items-center justify-between gap-2 place-items-center">
                    <PhoneInput
                    disabled
                      country={"us"}
                      value={userInfo?.phone}
                      placeholder="Enter new phone"
                      onChange={(phone) => setNewPhone(phone)}
                      containerClass="w-full h-10 col-span-4 flex py-1 2xl:pb-2.5 2xl:pt-3 flex-wrap content-center border text-xs sm:text-sm 2xl:text-base rounded-lg bg-lightGrey2 focus:ring-gray-100 focus:outline-none placeholder-grey2 z-20 mw-170"
                      inputStyle={{ border: "none", backgroundColor: "transparent" }}
                      buttonStyle={{ border: "none", backgroundColor: "transparent" }}
                    />
                    {/* {userInfo?.phone} */}
                    {userInfo?.phone && (
                      <div className="w-6">
                        <button
                          className="z-30 mt-2"
                          onClick={() => setEditPhone(true)}
                        >
                          <img
                            className="z-30"
                            style={{ width: 19 }}
                            src={edit}
                            alt="edit phone"
                          />
                        </button>
                      </div>
                    )}
                  </p>
                ) : (
                  <div className="h-12 grid grid-flow-col items-center grid-cols-6 gap-2 place-items-center">
                    <PhoneInput
                      country={"us"}
                      value={userInfo?.phone}
                      placeholder="Enter new phone"
                      onChange={(phone) => setNewPhone(phone)}
                      containerClass="w-48 h-10 col-span-4 flex py-1 2xl:pb-2.5 2xl:pt-3 flex-wrap content-center border text-xs sm:text-sm 2xl:text-base rounded-lg bg-lightGrey2 focus:ring-gray-100 mb-6 focus:outline-none placeholder-grey2 z-20"
                      inputStyle={{ border: "none", backgroundColor: "transparent", fontFamily: "Poppins-Medium" }}
                      buttonStyle={{ border: "none", backgroundColor: "transparent" }}
                    />
                    <div className="cursor-pointer col-span-1"><p
                      className="text-sm px-2 h-12 hover:text-green-500"
                      onClick={() => updatePhone()}
                    >
                      Save
                    </p></div>
                    <div className="cursor-pointer col-span-1"><p
                      className="text-sm text-red h-12 hover:text-yellow-900"
                      onClick={() => setEditPhone(false)}
                    >
                      X
                    </p></div>
                    </div>
                )}
              </div>
              <div className="table-row">
                <img src={phone} className="-mb-1 mt-10 flex " />
                <p className="2xl:text-base text-sm table-cell">2FA</p>
                <p className="2xl:text-base text-sm table-cell">:</p>
                <p className="text-black 2xl:text-base text-sm grid grid-cols-2 font-PoppinsMedium ">
                  {mfaEnabled ? 'Enabled' : 'Disabled'}
                  <div className={`table-cell  switch ${mfaEnabled ? 'on' : 'off'}`} onClick={toggle} />
                </p>

              </div>
              <div className="table-row">
                <img src={addressImg} className="-mb-1 mt-10 flex " />
                <p className="2xl:text-base text-sm table-cell">Address</p>
                <p className="2xl:text-base text-sm table-cell">:</p>
                {!editAddress ? (
                  <p className="flex flex-wrap text-black 2xl:text-base text-sm table-cell whitespace-normal font-PoppinsMedium ">
                    {userInfo?.address}
                    {userInfo?.city && `, ${userInfo?.city}`}
                    {userInfo?.state && `, ${userInfo?.state}`}
                    {userInfo?.zipCode && `, ${userInfo?.zipCode}`}
                    {userInfo?.country && `, ${userInfo?.country}`}
                    {
                      <>
                        <span
                          className="z-30 cursor-pointer"
                          onClick={() => setEditAddress(!editAddress)}
                        >
                          <img
                            className="z-30 "
                            style={{ minWidth: 10, width: 19 }}
                            src={edit}
                            alt="edit address"
                          />
                        </span>
                      </>
                    }
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {editAddress ? (
            <>
              <div className="col-span-2 -mt-6 relative z-50">
                <div className="col-span-2 -mx-3">
                  <InputProfileEdit
                    value={address}
                    id="address"
                    onChange={(event: any) =>
                      handleAddressSearch(event.target.value)
                    }
                    onKeyDown={(event: any) =>
                      handleAddressSearch(event.target.value)
                    }
                    onBlur={(e: { relatedTarget: { id: string; }; }) => {
                      if (e.relatedTarget?.id !== "address") {
                        setPredictions([]);
                      }
                    }}
                    title="Address"
                    placeholder="Type your Address"
                    error={formErrors.address}
                  />
                  {predictions?.length > 0 && (
                    <div
                      tabindex="0"
                      id="address"
                      className="absolute bg-white w-auto z-30 p-4 shadow-round mb-4 -mt-6"
                    >
                      <ul>
                        {predictions?.map((prediction: any) => (
                          <li key={prediction?.place_id}>
                            <button
                              id="address"
                              onClick={(e) =>
                                handlePredictionSelection(e, prediction)
                              }
                              onKeyDown={(e) =>
                                handlePredictionSelection(e, prediction)
                              }
                            >
                              {prediction?.description || "Not found"}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <img
                        className="mt-4 w-28"
                        src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
                        alt="Powered by Google"
                      />
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 -mx-3">
                  <InputProfileEdit
                    value={city}
                    id="city"
                    onChange={(event: any) => setCity(event.target.value)}
                    title="City"
                    placeholder="Type your City"
                    error={formErrors.city}
                  />
                  <InputProfileEdit
                    value={stateValue}
                    onChange={(event: any) => setStateValue(event.target.value)}
                    id="state"
                    title="State"
                    placeholder="Type your State"
                    error={formErrors.stateValue}
                  />
                  <InputProfileEdit
                    value={zipCode}
                    onChange={(event: any) => setZipCode(event.target.value)}
                    id="zipCode"
                    title="Zip Code"
                    placeholder="Type your Zip Code"
                    error={formErrors.zipCode}
                  />
                  <InputProfileEdit
                    value={country}
                    id="country"
                    onChange={(event: any) => setCountry(event.target.value)}
                    title="Country"
                    placeholder="Type your country"
                    error={formErrors.country}
                  />
                </div>
                <div className="flex flex-row mx-auto mt-2 mb-2">
                  <button
                    className="text-sm px-4 ml-auto "
                    onClick={() => updateAddress()}
                  >
                    Save
                  </button>
                  <button
                    className="text-sm text-red mr-auto"
                    onClick={() => {
                      setEditAddress(false);
                      setAddress(userInfo?.address);
                      setCity(userInfo?.city);
                      setStateValue(userInfo?.state);
                      setZipCode(userInfo?.zipCode);
                      setCountry(userInfo?.country);
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}
