import axios from 'axios'
console.log('process.env.REACT_APP_BASE_URL => ', process.env.REACT_APP_BASE_URL)
const axiosUtility = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://oysjx974ui.execute-api.us-east-2.amazonaws.com/dev/api',
  // baseURL: 'http://localhost:3000/dev/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

export default axiosUtility
