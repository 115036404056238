import React from "react";
import img1 from "../assets/days_dates/img1.png";
import img2 from "../assets/days_dates/img2.png";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import muhharam_1 from "../assets/days_dates/muhharam_1.jpg";
import muhharam_2 from "../assets/days_dates/muhharam_2.jpg";
import rabi_1 from "../assets/days_dates/rabi_1.jpg";
import rajab_1 from "../assets/days_dates/rajab_1.jpg";
import rajab_2 from "../assets/days_dates/rajab_2.jpg";
import shaban_1 from "../assets/days_dates/shaban_1.jpg";
import shaban_2 from "../assets/days_dates/shaban_2.jpg";
import shawwal_1 from "../assets/days_dates/shawwal.jpg";
import { Mixpanel } from "../Mixpanel";
import ramadan from "../assets/days_dates/ramadan.jpg";
import dhul from "../assets/days_dates/dhul.png";
import ramadan2jpg from "../assets/days_dates/ramadan2.jpg";
import dhu2jpg from "../assets/days_dates/dhu2jpg.jpg";
import rabi2jpg from "../assets/days_dates/rabi2jpg.jpg";
import smoothscroll from "smoothscroll-polyfill";

export default function DaysDatesDetails(props: any) {
  const { title } = useParams();

  window.__forceSmoothScrollPolyfill__ = true;
  smoothscroll.polyfill();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  Mixpanel.track("View Days&Dates Detail Page", {
    Title: title,
  });
  const muhharamText1 = (
    <span>
      The first Islamic month is Muḥarram and it’s also one of the four sacred
      months in Islam. According to some companions of the Prophet ﷺ - Muḥarram
      is the most sacred of the four. All kinds of warfare is forbidden during
      this month.
      <br />
      The Prophet ﷺ said, "The most virtuous fasting after the month of Ramadan
      is Allah's month Al-Muḥarram."
      <br />
      This is the only month known to have been labeled “Allah’s Month” by the
      Prophet of Allah ﷺ . This shows the month’s high stature in Islam. It’s
      also a month in which the Prophet ﷺ fasted regularly and consistently.
    </span>
  );

  const muhharamText2 = (
    <span>
      The Day of Ashura is the 10th day of the sacred month of Muḥarram. It’s a
      blessed and auspicious day for the Muslims. After the noble Hijrah
      (emigration from Makkah to Al-Madinah) of the Prophet ﷺ, Muslims were
      required to fast on this day during the first year of Hijrah. It became
      optional (but recommended) to fast from the second year onward. “Allah's
      Messenger ﷺ ordered the Muslims to fast on the day of Ashura, and when
      fasting in the month of Ramadan was prescribed, it became optional for one
      to fast on that day Ashura or not.” - Mother of the believers - Sayyidah
      ‘Aisha رضي الله عنها <br />
      The Prophet Muhammad ﷺ recommended us to fast on this day as it wipes out
      the sins of the past year. <br />
      “Fast the Day of Ashura, for indeed I anticipate that Allah will forgive
      (the sins of) the year before it." - Prophet ﷺ. <br />
      Some of the auspicious events that reportedly took place on this day
      include:
      <br />· <span className="ml-5"></span>Prophet Adam was forgiven by Allah.{" "}
      <br />· <span className="ml-5"></span>Prophet Musa was saved from the
      Pharoah.
      <br />· <span className="ml-5"></span>Prophet Nuh’s ark landed on Mount
      al-Judi after six months at sea.
      <br />· <span className="ml-5"></span>Prophet Yunus’ people were forgiven
      by Allah. <br />
      <br />
      Even though many events took place on this day that are causes for
      celebration and rejoice for Muslims, the Day of Ashura also witnessed the
      historical tragedy of the massacre of the beloved grandson of our Prophet
      ﷺ - Sayyiduna al-Husayn رضي الله عنه. <br />
      <br />
      The following excerpt has been taken from{" "}
      <a
        className="text-skyBlue hover:opacity-75"
        href="https://muwasala.org/2015/10/18/the-day-of-ashura-2/"
      >
        Muwasala
      </a>
      : <br />
      Sayyidi Habib Umar bin Hafiz (may Allah protect him and benefit us by him)
      said:
      <br />
      <br />
      “Think of your wrongdoings in the previous year as files saved on a
      computer. By fasting the Day of Ashura, you press the delete button and
      wipe out all those files. Respond to this invitation. This is not the work
      of a software designer, this is the Prophet, the most knowledgeable of
      creation, who received his knowledge from the All-Knowing, the All-Wise.”
      <br />
      <br />
      We should also renew our repentance on Ashura. The Messenger of Allah ﷺ
      said that it is a day on which Allah allowed a certain people to repent
      and He will continue to allow others to do the same (Tirmidhi).
      <br />
      <br />
      The Prophet ﷺ also taught us that if someone spends generously on their
      family on the Day of `Ashura’, Allah will treat them with generosity for
      the rest of the year (Bayhaqi). Sufyan bin `Uyaynah said, “We tried this
      for fifty years and all that we saw was good.”
      <br />
      <br />
      Habib Umar said: “The purpose of this is that you show extra compassion to
      them. This is then a means of protecting your family from the forces of
      corruption that attempt to encroach from all sides. This does not just
      mean buying them food. You need to show them the compassion of Muhammad ﷺ
      through his teachings. Show them that the Prophet has taught you to laugh
      with them and to make sure they are well. He has taught you that it hurts
      you to see them suffer in any way in this life or the next. Let them
      experience the compassion of his teachings and show them that after 1,400
      years his teachings are alive in your home.”
      <br />
      <br />
      It has likewise been narrated that the one who gives charity on this day
      will have the reward of a whole year’s charity.
      <br />
      <br />
      <span className="font-bold">End Quote.</span>
      <br />
      <br />
      CharityMoments invites you to come celebrate the blessed month of Muharram
      and the auspicious Day of Ashura by fasting often and giving charity to
      the needy. May Allah accept it from you.
      <br />
    </span>
  );

  const rabiText1 = (
    <span>
      The third Islamic month is Rabī‘ al-Awwal and it’s the month in which the
      beloved of Allah, our noble Prophet Muhammad ﷺ was born. <br /> <br />
      Traditionally, this month has always been a month of great joy and
      happiness for the Muslims as they celebrate the birth of Allah’s
      most-loved creation, our beautiful Messenger ﷺ . Muslims would feed the
      poor, give out charity, recite poetry in veneration of the Prophet ﷺ, send
      abundant salutations of peace and blessings on the Nabi ﷺ and sing qasaid
      about his ﷺ noble qualities.
      <br /> <br />
    </span>
  );

  const rabiText2 = (
    <span>
      Whether the actual birthday of the Prophet Muhammad ﷺ was on the 12th of
      Rabī‘ al-Awwal or not is a matter of scholarly difference. It is however a
      very well-known opinion of the scholars that it was in fact on the 12th of
      Rabī‘ al-Awwal and this is why many Muslims including many Muslim nations
      celebrate it on that day. <br />
      <br />
      Some of the recommended acts on this day include:
      <br />· <span className="ml-5"></span>Feeding the poor and the needy and
      being generous with the Muslims
      <br />· <span className="ml-5"></span>Sending abundant salawat on the
      Prophet ﷺ <br />· <span className="ml-5"></span>Giving out charity
      <br />· <span className="ml-5"></span>Reciting al-Shama’il al-Muhammadiyya
      of Imam al-Tirmidhi <br />· <span className="ml-5"></span>Reciting qasaid,
      poetry and nasheed in praise of the Prophet ﷺ <br />·{" "}
      <span className="ml-5"></span>Making immense supplications and thanking
      Allah for blessing the Ummah with Sayyiduna Muhammad ﷺ <br />
      <br />
      Celebrate the month of Rabī‘ al-Awwal and the Mawlid of RasulAllah ﷺ with
      CharityMoments.
    </span>
  );
  const rajabText1 = (
    <span>
      Rajab is one of four sacred months in Islam and it is by itself whereas
      the other three months come in succession. The reward for doing good
      increases in sacred months whereas sins committed during them are
      considered graver offenses. <br />
      <br />
      The blessings of this month are immense and it’s a key to the months of
      goodness that follow it. In the famous supplication, the Prophet ﷺ is
      reported to have said:
      <br />
      <br />
      اللَّهُمَّ بَارِكْ لَنَا في رَجَبٍ وَ شَعْبَانَ وَ بَلِّغْنا رَمَضَانَ{" "}
      <br />
      <br />
      “O Allah bless us in Rajab and Sha`ban and enable us to reach Ramadan!”
      <br />
      <br />
      Many scholars have mentioned the significance of this month and stressed
      the importance of fasting during the sacred months. According to Imam Abu
      Bakr al-Warraq, “In the month of Rajab, you sow the seeds, in Sha`ban, you
      irrigate them, and in Ramadan, you reap the harvest.”
      <br />
      <br />
      Some of the merits of Rajab are:
      <br />· <span className="ml-5"></span>The Prophet’s ﷺ blessed parents were
      married in Rajab.
      <br />· <span className="ml-5"></span>Sayyidah Aminah became pregnant with
      the Best of Creation ﷺ in this month. <br />·{" "}
      <span className="ml-5"></span>Most scholars say that Al Isra (The Night
      Journey) and Wal Mi`raj (The Heavenly Ascension) by the Prophet ﷺ took
      place on the 27th night of Rajab. <br />
    </span>
  );

  const rajabText2 = (
    <span>
      سُبْحَانَ الَّذِي أَسْرَىٰ بِعَبْدِهِ لَيْلًا مِّنَ الْمَسْجِدِ الْحَرَامِ
      إِلَى الْمَسْجِدِ الْأَقْصَى الَّذِي بَارَكْنَا حَوْلَهُ لِنُرِيَهُ مِنْ
      آيَاتِنَا ۚ إِنَّهُ هُوَ السَّمِيعُ الْبَصِيرُ <br />
      <br />
      Exalted is He who took His Servant by night from al-Masjid al-Haram to
      al-Masjid al- Aqsa, whose surroundings We have blessed, to show him of Our
      signs. Indeed, He is the Hearing, the Seeing.
      <br />
      <br />
      Al Qur’an | 17:1
      <br />
      <br />
      Widely reported by many scholars to have taken place on the 27th of Rajab
      a year before the migration to Madinah, Al Isra’ Wal Mi’raj or the Night
      Journey and the Heavenly Ascension of the Prophet ﷺ was one of the most
      remarkable and miraculous events to have taken place during his lifetime.
      The Messenger of Allah ﷺ was transported by a creature named al-Buraq from
      Makkah to Jerusalem and then from there to the Heavens on this night.
      Angel Jibril (Gabriel) accompanied the Prophet ﷺ in this journey. Along
      the way, they stopped at Madinah, Mount Sinai, Bethlehem, and the
      graveyard of the Prophet Musa which is not too far from Jerusalem. Allah’s
      Messenger ﷺ stopped at these places to “dismount and pray”. When Angel
      Jibril and the Prophet ﷺ reached Al Masjid Al Aqsa, 124,000 prophets were
      waiting for the Final Messenger ﷺ who lead them in prayers signifying that
      he ﷺ is indeed the leader and imam of all previous prophets who came
      before him ﷺ. <br />
      <br />
      After this, Allah’s Apostle ﷺ began his ascension to the Heavens from The
      Dome Of the Rock in Masjid Al Aqsa. As per Dar al-Ifta Al Misriyyah -
      here, all the laws of nature and the concepts of time and space as we know
      cease to apply and everything the Prophet experiences and sees is beyond
      human comprehension. At each of the seven heavens, the Prophet accompanied
      by Jibril encounters a gate and a watcher. At the gate of the lowest
      heaven, Jibril asks the gatekeeper to open the gate - and after some back
      and forth questions, the angels let them in with extreme happiness and
      joy. This repeats at each of the seven heavens. <br />
      <br />
      Prophet Muhammad ﷺ traveled through seven heavens and met prophet(s) at
      each heaven. He ﷺ encountered:
      <br />· <span className="ml-5"></span>First Heaven - Adam <br />·{" "}
      <span className="ml-5"></span>Second Heaven - Yahya & ‘Isa <br />·{" "}
      <span className="ml-5"></span>Third Heaven - Yusuf <br />·{" "}
      <span className="ml-5"></span>Fourth Heaven - Idris <br />·{" "}
      <span className="ml-5"></span>Fifth Heaven - Harun <br />·{" "}
      <span className="ml-5"></span>Sixth Heaven - Musa <br />·{" "}
      <span className="ml-5"></span>Seventh Heaven - Ibrahim
      <br /> <br />
      Allah’s Final Messenger ﷺ greeted each of the prophets with the greetings
      of peace and they responded by saying, “Welcome dear brother and welcome O
      Prophet of Allah!” Prophet Adam ﷷﷵ and Prophet Ibrahim ﷷﷵ however
      responded by saying “dear son” instead of “dear brother.” <br />
      <br />
      On this journey, as the Prophet ﷺ passed through the Heavens, Angel Jibril
      showed him wondrous things. For example, the Prophet ﷺ was reminded about
      hijamah (cupping), was shown Bayt al-Ma’moor - the qiblah of the angels
      that sits above the Ka’bah and eventually came to the Sidrat al-Muntaha or
      The Lote Tree. It is important to note that the Messenger of Allah ﷺ also
      witnessed heaven and hell and learned about its inhabitants. <br />
      <br />
      At Sidrat al-Muntaha, Prophet Muhammad ﷺ noticed four rivers - two of the
      visible ones were the Nile and the Euphrates from this world and the other
      two were rivers of Paradise that were hidden. At The Lote Tree, which was
      the supreme limit beyond which no creation of Allah ever crossed, Angel
      Jibril asked The Prophet of Allah ﷺ to proceed alone.
      <br />
      <br />
      And after that, our truthful Prophet ﷺ - the beloved of Allah - the Light
      of mankind - stood in the presence of Allah the Almighty at a distance of
      “two bow-lengths away or even closer.”
      <br />
      <br />
      The details of the exchange that went on between Allah and His beloved
      Prophet ﷺ is not known. It is however reported that Allah revealed the
      last two verses of Surah Baqarah (Chapter 2 of the Qur’an) to His
      Messenger ﷺ directly without any intermediary (Angel Jibril). Also, what
      is known from that encounter is Allah Almighty gifted the Ummah with Salah
      or the daily prayers on this occasion. The original amount prescribed was
      50 prayers but after Prophet Musa ﷷﷵ implored the Messenger ﷺ to go back
      and lower the amount, it was finally lowered down to 5 daily prayers, each
      with the reward of 10 prayers. <br />
      <br />
      According to Shaykh Habib Umar, the scholars opined that the best night in
      relation to the Ummah as a whole was the night on which the Prophet ﷺ was
      born, whereas the best night in relation to the Prophet ﷺ himself was the
      night of the Isra’ and Mi`raj.
      <br />
      <br />
      Finally, in quoting{" "}
      <a
        className="text-skyBlue hover:opacity-75"
        href="https://www.dar-alifta.org/Foreign/ViewArticle.aspx?ID=1872&CategoryID=4"
      >
        Dar alIftah:
      </a>
      <br />
      <br />
      “The Isra` and Mi’raj carry many great and noble meanings. Those meanings
      are not only relevant to the Prophet but to all Muslims and believers. At
      the Prophet’s time, the journey brought glad tidings to the believers and
      strengthened their faith. Similarly, when commemorating this occasion,
      Muslims are instructed to remember that for us, the Isra` and Mi’raj
      represents an inward journey towards the depths of our hearts, in search
      of Allah the Almighty, by which we will be able to realize our ultimate
      journey towards the eternal bliss of coming in His presence.”
      <br />
      <br />
      This was a tremendous event for the Muslims. We, at CharityMoments,
      encourage you to honor this day by giving charity to our partners. We hope
      all the blessed days of Allah brings you closer to Him and make you love
      the Messenger of Allah ﷺ even more.
    </span>
  );

  const shawwalText = (
    <span>
      Shawwāl is the tenth Islamic month. It ushers in a sense of a new
      beginning for Muslims, completely recharged and rejuvenated after the
      blessed month of Ramadan. <br />
      <br />
      The first of Shawwāl is also Eid al-Fitr which comes after the end of
      Ramadan. During Ramadan, Muslims are expected to put in an immense amount
      of work - fasting, reading Qur’an, being patient, giving charity, praying
      into the night and so much more. This is why Eid al-Fitr is a time for
      celebration and happiness for the Muslims. We thank Allah for the
      opportunity to have witnessed Ramadan and pray that our worship was
      accepted by Him SWT. <br />
      <br />
      In Shawwāl, Muslims are also encouraged to fast six days as per the hadith
      of the Messenger of Allah ﷺ: “Fasting in Ramadan and following it with six
      days in Shawwal is like continual fasting.”
      <br />
      <br />
      When Ramadan ends and the evening comes in, we should glorify Allah (give
      takbir) from Maghrib till the beginning of the Eid al-Fitr prayers. <br />
      <br />
      The following excerpt (with slight modifications) has been taken from
      Muwasala (
      <a
        className="text-skyBlue hover:opacity-75"
        href="https://muwasala.org/2016/07/05/the-night-of-eid-al-fitr/"
      >
        source here
      </a>
      ):
      <br />
      <br />
      It is a Sunnah of the Prophet ﷺ to give life to the night before Eid,
      meaning spending whatever time we are able to during that night in worship
      and remembrance such that the night comes alive. The least we should do is
      to pray Maghrib, Isha and Fajr in congregation and then devote whatever
      time we are able to Allah. It has been narrated that the Messenger of
      Allah ﷺ said: “There are five nights on which du`a is not rejected: the
      first night of Rajab, the fifteenth night of Sha`ban, Thursday night, the
      night before Eid al-Fitr and the night before Eid al-Nahr (al-Adha).”
      Sayyiduna `Ali, may Allah be well pleased with him, used to spend four
      nights in worship: the first night of Rajab, the nights before the two
      Eids, and the 15th night of Sha`ban.
      <br />
      <br />
      It is mentioned in the hadith that whoever gives life to the nights before
      the two Eids, Allah will give life to his heart on the day when hearts
      die. What is primarily meant is safety on the Day of Judgement, but even
      in this life the majority of people’s hearts are dead, starved of the
      remembrance of Allah and heedless of the return to Him. Remembering Allah
      on nights such as these, however, when most people are busy with other
      things, will give life and tranquility to our hearts.
      <br />
      <br />
      Eid then will be a true celebration, an expression of our gratitude to
      Allah for the ability to worship and remember Him in the previous days and
      nights.
    </span>
  );

  const shabanText1 = (
    <span>
      It is the eighth Islamic month and it comes between the sacred month of
      Rajab and the blessed month of Ramadan. A lot of goodness branches off
      from this auspicious month. <br />
      <br />
      Our Prophet ﷺ said, ““It is a month where people tend to neglect, between
      the months of Rajab and Ramadan. It is a month in which the deeds are
      raised to the Lord of the worlds, and I like my deeds to be raised while I
      am fasting.” It is also the month in which prayers and salutations on the
      Messenger ﷺ was revealed: <br />
      <br />
      إِنَّ ٱللَّهَ وَمَلاَئِكَـتَهُ يُصَلُّونَ عَلَى ٱلنَّبِيِّ يٰأَيُّهَا
      ٱلَّذِينَ آمَنُواْ صَلُّواْ عَلَيْهِ وَسَلِّمُواْ تَسْلِيماً <br />
      <br />
      Indeed Allah and His angels bestow their prayers upon the Prophet. O you
      who believe, bestow prayers and peace upon him in abundance.
      <br />
      <br />
      Al Qur’an | 33:56
      <br />
      <br />
      According to some scholars, the Qiblah or the direction that Muslims face
      when praying was changed during the month of Sha‘bān. The first Qiblah of
      the Muslims was Bayt al-Maqdis (Jerusalem) where the sacred Al-Aqsa mosque
      is located. Eighteen months after the hijrah, Allah changed the Qiblah of
      the Muslims to face the Ka’bah in Masjid Al Haram in Makkah. Additionally,
      some scholars of Sirah say that it was the month in which the moon was
      split in half for the Messenger of Allah ﷺ.
      <br />
      <br />
      As such, Muslims should venerate this month and increase their good deeds.
      We are encouraged to:
      <br />· <span className="ml-5"></span>Fast more often in preparation for
      the month of Ramadan as the Prophet of Allah ﷺ loved fasting during this
      month
      <br />· <span className="ml-5"></span>Pray the night prayers before the
      dawn
      <br />· <span className="ml-5"></span>Send abundant salawat on the
      Messenger of Allah ﷺ<br />· <span className="ml-5"></span>Remember Allah
      more often
      <br />· <span className="ml-5"></span>Supplicate to Allah more often
      <br />
    </span>
  );
  const shabanText2 = (
    <span>
      One of the greatest nights of the year is the 15th night of the month of
      Sha‘bān. According to some, it is the single greatest night after Laylat
      al-Qadr in Ramadan. <br />
      <br />
      The following excerpt has been taken from Muwasala which summarizes the
      blessings of this night
      <br />
      <br />
      The Fifteenth Night of Sha`ban, known in Arabic as Laylat al-Nisf min
      Sha`ban, literally “the eve of the middle day of Sha`ban,” is one of the
      greatest nights of the year. (In Islam the night precedes the day so
      really it is the night before the fifteenth day of Sha`ban.) `Ata’ bin
      Yasar said that after Laylat al-Qadr there is no night better than the
      Fifteenth Night of Sha`ban. Its greatness is due to the divine gifts that
      are bestowed during it.
      <br />
      <br />
      One night Sayyidah `A’ishah noticed that the Messenger of Allah ﷺ had left
      her house. She went out to see where he was and found him in the Baqi`
      Graveyard raising his arms to the heavens in supplication. He said to her
      that on that night, the fifteenth night of Sha`ban, Allah forgives more
      people than there are hairs on the sheep of the tribe Kalb, an Arab tribe
      renowned for the great flocks of sheep that its members possessed. He also
      said ﷺ : “Allah gazes at His creation on the fifteenth night of Sha`ban
      and then forgives all His slaves except for two types of people: those who
      attribute partners to Allah and those who have rancour for their fellow
      Muslims.” The Prophet ﷺ said that when this night comes we should spend it
      in prayer and fast the following day, because truly Allah calls out to His
      slaves from sunset until dawn: “Is there anyone seeking forgiveness from
      Me so that I may forgive him? Is there anyone seeking provision from Me so
      I may provide for him? Is there anyone suffering so I may relieve his
      suffering?”
      <br />
      <br />
      Imam `Ali went out on the fifteenth night of Sha`ban and gazed at the
      heavens. He said that the Prophet Dawud (peace be upon him) went out at a
      similar time on this same night and said that if anyone calls upon Allah
      at this time, Allah answers him and if anyone seeks His forgiveness, He
      forgives him. For this reason he would spend the night in worship. It has
      been narrated that the Messenger of Allah ﷺ said: “There are five nights
      on which du`a’ is not rejected: the first night of Rajab, the fifteenth
      night of Sha`ban, Thursday night, the night before Eid al-Fitr and the
      night before Eid al-Nahr (al- Adha).”
      <br />
      <br />
      `Ikrimah and other commentators of the Qur’an were of the opinion that the
      “Blessed Night” referred to in Surah al-Dukhan is the fifteenth night of
      Sha`ban. Allah says of it: In it every decreed affair becomes distinct.
      According to this opinion it is the night in which everything that Allah
      has decreed for His slaves for the ensuing year becomes manifest. Their
      provision is allotted and those who are destined to die are named. `Ata’
      bin Yasar said that on this night the Angel of Death receives a scroll on
      which are the names of all those who are destined to die in the coming
      year. He said: “A man may plant crops, get married and build buildings
      while his name has already been recorded amongst the dead. The Angel of
      Death is only waiting for the order to take his soul.” Thus many of the
      pious would ask Allah on this night to be recorded amongst the felicitous,
      just as they would ask for plentiful provision.
      <br />
      <br />
      Sayyiduna `Umar bin `Abd al-`Aziz said: “Do not neglect four nights in the
      year, because on those nights Allah pours His mercy upon His slaves: the
      first night of Rajab, the fifteenth night of Sha`ban, the night before Eid
      al-Fitr and the night before Eid al-Adha.” It was thus the habit of some
      of the early Muslims to “bring life” to the fifteenth night of Sha`ban.
      They encouraged people to come together in the mosque on that night to
      pray, supplicate and seek forgiveness.
      <br />
      <br />
      A number of the hadith that talk about the immense gifts which Allah
      bestows on this night mention several categories of people that are
      excluded from receiving these gifts. Among those mentioned are those who
      attribute partners to Allah and those who have rancour in their hearts for
      their fellow Muslims. Some of the scholars mentioned specifically those
      who insult the Companions or the early generations of the Muslims or
      declare their fellow Muslims to be disbelievers or innovators. Other
      categories mentioned are those who fornicate, cut the ties of kinship or
      disrespect their parents.
      <br />
      <br />
      These categories of people are also denied forgiveness and acceptance on
      other great nights such as the first night of Ramadan and Laylat al-Qadr.
      We should thus do our utmost to avoid these attributes. One of the early
      scholars said: “The best attributes are to have a sound heart, a generous
      soul and sincerely want good for the Ummah. Through these qualities the
      great ones reached the stations that they reached, not through a great
      amount of prayer and fasting.”
      <br />
      <br />
      May Allah not deprive us of any of the gifts that are bestowed on this
      great night. May He give us the ability to seek His pleasure by praying to
      Him and seeking His forgiveness in this night. We ask that He decrees for
      us all that is good and deflects from us all that is harmful. May He
      bestow never-ending peace and blessings upon our guide and master
      Muhammad, through whom we came to know the superiority of one time over
      another and in whose guidance is all good in this life and the next.
      <br />
      <br />
      Source (including references that were omitted here can be found here):
      <a
        className="ml-1 text-skyBlue hover:opacity-75"
        href="https://muwasala.org/2015/05/29/the-15th-night-of-shaban/#more-1333"
      >
        Muwasala
      </a>
    </span>
  );

  const ramadanText1 = (
    <span>
      Ramaḍān is the ninth Islamic month. Even though it is not a designated
      “sacred month”, it is however one of the most blessed months in the
      Islamic year - a month billions of Muslims worldwide awaits in eagerness
      every year. This month is full of blessings and gifts from Allah SWT.
      <br />
      <br />
      The first third of the month are the days of mercy from Allah SWT. The
      second third of the month are the days of Allah’s forgiveness. The last
      third of the month are the days of salvation from the Hellfire. When
      Ramaḍān begins, according to a narration, =the Prophet ﷺ said, “When the
      first night of Ramaḍān comes, the devils and the rebellious jinn are
      chained, the gates of Hell are locked and not one of them is opened; the
      gates of Paradise are opened and not one of them is locked; and a crier
      calls, 'You who desire what is good, come forward, and you who desire
      evil, refrain.' Some are freed from Hell by Allah, and that happens every
      night."
      <br />
      <br />
    </span>
  );

  const ramadanText2 = (
    <span>
      Today, in its essence, it’s the month of fasting for Muslims. But many
      major events took place during this holy month. Firstly, the Qur’an was
      revealed to our Prophet Muhammad ﷺ on this month. It is the month of
      fasting for Muslims. Fasting is the fourth pillar of Islam. Every sane,
      able-bodied, and healthy Muslims during the holy month of Ramaḍān fasts
      from dawn till sunset every day. Those traveling are not required to fast,
      however. It is the month of Laylat al-Qadr - the single greatest night in
      the entire year. Doing good deeds during this night is equivalent to doing
      good deeds for 1,000 months. The Prophet ﷺ asked us to look for Laylat
      al-Qadr during the last ten nights of this month - especially during the
      odd numbered nights. Ramaḍān is also the month of victory and Muslims won
      countless battles in their history during the month of Ramaḍān. The
      Prophet ﷺ himself won the battle of Badr which took place on the 17th of
      Ramaḍān. This was a decisive battle that the Muslims had to win or face
      extinction from the face of the Earth. Also, the coqneust of Makkah by the
      Prophet Muhammad ﷺ conquered took place during Ramaḍān with an army of
      10,000 Muslims. Other battles that Muslims won during this month include
      the conquest of Jerusalem where Sultan Salahuddin Al Ayyubi defeated the
      Crusaders. The Muslims of the Mamluk dynasty defeated the Mongols for the
      first time on the 25th of Ramaḍān at the battle of Ain Jalut which saved
      the Islamic civilization from a catastrophic end. And the people of Taif
      accepted Islam in the month of Ramaḍān after 20 years of hostilities
      against the Prophet ﷺ. Many other important events took place for the
      Muslims during this blessed month - we encourage you to learn further on
      this topic.
      <br />
      <br />
      During this month, while Muslims are fasting, many actions are prohibited
      and heavily discouraged. Muslims are encouraged to rectify their bad
      habits, clean up their life and be morally upright. We’re also advised to
      use this month as a means to go through renewal and cleansing and ask
      Allah for forgiveness from all past misdeeds.
      <br />
      <br />
      Some recommended acts during Ramaḍān include:
      <br />· <span className="ml-5"></span>Eating suhoor (pre-dawn meal)
      <br />· <span className="ml-5"></span>Eat iftar on time
      <br />· <span className="ml-5"></span>Praying tarawih and tahajjud at
      night
      <br />· <span className="ml-5"></span>Reading the entire Qur’an at least
      once
      <br />· <span className="ml-5"></span>Give a lot of charity to the poor
      and needy
      <br />· <span className="ml-5"></span>Make a lot of du’a and salawat
      <br />· <span className="ml-5"></span>Perform I’tikaaf in the masjid
      during the last ten nights of Ramaḍān <br />·{" "}
      <span className="ml-5"></span>Staying healthy - physically and spiritually{" "}
      <br />· <span className="ml-5"></span>Unite with other Muslims and ask
      forgiveness from them for any past transgressions <br />·{" "}
      <span className="ml-5"></span>Self-reflection with the goal of becoming a
      better person
      <br />
      <br />
      Allah SWT has given the Muslims a lot of opportunities to become a
      completely reformed person during and after Ramaḍān. It is upto us to take
      advantage of its full blessings. This month is full of blessings and Allah
      showers us with immense Divine favors and gifts. He SWT makes it easy -
      whatever was difficult before. It is a month of zakat and sadaqah. It is a
      month we thank Allah for our blessings and attempt to help the less
      fortunate.
      <br />
      <br />
      CharityMoments invites you to celebrate Ramaḍān and honor it by increasing
      your good deeds which will make the Prophet of Allah ﷺ happy.
    </span>
  );

  const dhuText1 = (
    <span>
      Dhū al-Ḥijjah is one of four sacred months in Islam and the 12th or the
      final month of the Islamic year. It is the month of immense blessings.
      <br />
      <br />
      The first ten days of Dhu’l-Hijjah are one of the most important days for
      a Muslim during the whole year. Our Prophet ﷺ said, “There are no days on
      which righteous deeds are more beloved to Allah than these ten days.”
      <br />
      <br />
      The best day of the entire year is the 9th day of Dhū al-Ḥijjah which is
      the Day of Arafah. On this day, the Muslims who make pilgrimage to Makkah
      visit Mount Arafah as part of the Hajj. On this day, there is an immense
      outpouring of divine mercy, blessings and bounty for all Muslims. Our
      Messenger ﷺ informed us that, "There is no day on which Allah frees people
      from the Fire more so than on the day of Arafah. He comes close to those
      (people standing on Arafah), and then He reveals before His Angels saying,
      ‘What are these people seeking." For those who are not physically at
      Arafah on that day, it is encouraged for them to fast as per the hadith
      that states: "Fasting on the Day of Arafah expiates the sins of the past
      year and the coming year." - Messenger of Allah ﷺ. According to a hadith
      in Abu Dawud, the Messenger of Allah ﷺ has reportedly said that anyone who
      has an atom’s weight of faith in their heart will be forgiven by Allah on
      this day, whether or not they actually stand at Arafah. Therefore, it is
      highly recommended to fast and make a lot of du’a on the Day of Arafah.
      <br />
      <br />
      The Prophet ﷺ said: “The best prayer is the prayer of the Day of Arafah.
      The best thing which I and the Prophets before me have said is:
      <br />
      لاَ إِلَهَ إِلَّا اللهُ وَحْدَهُ لاَ شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَ لَهُ
      الْحَمْدُ يُحْيُي و يُمِيت وَ هُوَ حَيٌّ لا يَموتُ بِيَدِهِ الخَيْرُ و هَو
      عَلَى كُلِّ شَيءٍ قَدِير
      <br />
      <br />
      “There is no deity save Allah alone. He has no partners. To Him belongs
      the dominion and all praise. He brings life and brings death and He is the
      Living Who never dies. To Him belongs all good. He has power over all
      things.”
    </span>
  );
  const dhuText2 = (
    <span>
      Dhū al-Ḥijjah is also the month of Hajj. Hajj or pilgrimage is one of the
      fifth and essential pillars of faith for the Muslims and is obligatory to
      perform for all able-bodied Muslims who can afford it. An accepted Hajj’s
      reward is nothing but paradise for a believer.
      <br />
      <br />
      In a hadith, it is reported that “The Prophet ﷺ used to fast on the first
      nine days of Dhul-Hijjah and the day of Ashura, and three days each month,
      the first Monday of the month and two Thursdays.”
      <br />
      <br />
      Some recommended acts during this month include:
      <br />· <span className="ml-5"></span>Recite the Qur’an often
      <br />· <span className="ml-5"></span>Make du’a, do dhikr and send salawat
      on the Prophet ﷺ abundantly.
      <br />· <span className="ml-5"></span>Strengthen and preserve ties of
      kinship <br />· <span className="ml-5"></span>Give a lot of charity <br />
      In conclusion, we would like to remind you that our Prophet ﷺ said: “There
      are no days that are greater before Allah or in which good deeds are more
      beloved to Him, than these ten days, so recite a great deal of tahleel,
      takbeer and tahmeed during them.”
      <br />
      <br />
      As a reminder, these are:
      <br />· <span className="ml-5"></span>Tahmeed: Al-hamdu Lillah (All
      praises be to God)
      <br />· <span className="ml-5"></span>Tahleel: Laa ilaha ill-Allah (There
      is no god but Allah)
      <br />· <span className="ml-5"></span>Tasbeeh: Subhaan-Allah (Glory be to
      God)
      <br />
      <br />
      CharityMoments invites you to celebrate and honor Dhū al-Hijjah by fasting
      its first 9 days, especially on the Day of Arafah, and to increase all
      your good deeds.
    </span>
  );

  const [img1, setImg1] = React.useState();
  const [img2, setImg2] = React.useState();
  const [text1, setText1] = React.useState();
  const [text2, setText2] = React.useState();
  React.useEffect(() => {
    if (title == "Muharram") {
      setImg1(muhharam_1);
      setImg2(muhharam_2);
      setText1(muhharamText1);
      setText2(muhharamText2);
    } else if (title == "Rabī‘ al-Awwal") {
      setImg1(rabi_1);
      setImg2(rabi2jpg);
      setText1(rabiText1);
      setText2(rabiText2);
    } else if (title == "Rajab") {
      setImg1(rajab_1);
      setImg2(rajab_2);
      setText1(rajabText1);
      setText2(rajabText2);
    } else if (title == "Sha‘bān") {
      setImg1(shaban_1);
      setImg2(shaban_2);
      setText1(shabanText1);
      setText2(shabanText2);
    } else if (title == "Shawwāl") {
      setImg1(shawwal_1);
      setText1(shawwalText);
    } else if (title == "Ramaḍān") {
      setImg1(ramadan);
      setImg2(ramadan2jpg);
      setText1(ramadanText1);
      setText2(ramadanText2);
    } else if (title == "Dhū al-Ḥijjah") {
      setImg1(dhul);
      setImg2(dhu2jpg);
      setText1(dhuText1);
      setText2(dhuText2);
    }
  }, [title]);

  return (
    <div className="bg-white mx-5 rounded-3xl relative px-4 xl:px-56 2xl:px-96 px-10">
      <Helmet>
        <title>Days & Dates | CharityMoments</title>
        <meta
          name="description"
          content="Learn about the blessed Islamic months and the sacred events that took place in them."
        />
      </Helmet>
      <h1 className="text-center pt-10 md:pt-24 mb-10 xl:mb-20 text-3xl 2xl:text-8xl ">
        {title}
      </h1>
      <div className="grid lg:grid-cols-3 lg:gap-x-10 flex flex-wrap content-center sm:grid-cols-1 lg:mb-24">
        <img
          src={img1}
          className="mb-10 mx-auto xl:mb-0 w-auto h-auto rounded-3xl"
        />
        <div className="md:col-span-2 sm:mb-8">
          <h2 className="text-xl 2xl:text-4xl leading-relaxed">
            <span className="text-camel font-Poppins mr-2">
              {title == "Muharram" ||
              title == "Rajab" ||
              title == "Rabī‘ al-Awwal" ||
              title == "Shawwāl" ||
              title == "Sha‘bān" ||
              title == "Ramaḍān" ||
              title == "Dhū al-Ḥijjah"
                ? "|"
                : null}
            </span>
            {title == "Sha‘bān"
              ? "Sha‘bān"
              : null || title == "Rajab"
              ? "Rajab"
              : null || title == "Muharram"
              ? "Muharram"
              : null || title == "Rabī‘ al-Awwal"
              ? "Rabī‘ al-Awwal	&"
              : null || title == "Shawwāl"
              ? "Shawwāl	&"
              : null || title == "Ramaḍān"
              ? "Ramaḍān"
              : null || title == "Dhū al-Ḥijjah"
              ? "Dhū al-Ḥijjah"
              : null}
            <span className="text-camel">
              {" "}
              {title == "Rabī‘ al-Awwal"
                ? "Prophet’s ﷺ Birthday"
                : null || title == "Shawwāl"
                ? "Eid al-Fitr"
                : null}
            </span>
          </h2>
          <p className="leading-7 mt-4 text-sm xl:text-base">
            {/* A year lasts for about 354 days and consists of 12 months in the
            Islamic/Hijri calendar. As the first month of the year, Muharram is
            known as the month of remembrance and it is believed to be the most
            sacred month. Muslims are not allowed to fight during Muharram. Some
            important Islamic historical events that happened during the month
            include: <br /><br />
            The Battle of Karbala (currently in Iraq) in the year 680
            CE, which enabled Husayn ibn Ali, a grandson of the Prophet
            Muhammed, and his army to enter the city on the first day of the
            month. The restriction of Husayn ibn Ali's access to water on the
            seventh day. The death of Husayn ibn Ali and his clan (Ahl al-Bayt)
            on the 10th day of the month. */}
            {text1}
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 lg:gap-x-10 grid-cols-1 mt-10 pb-24">
        <div className="md:col-span-2 lg:ml-auto">
          {title == "Rabī‘ al-Awwal" ||
          title == "Ramaḍān" ||
          title == "Dhū al-Ḥijjah" ? null : (
            <>
              <h2 className="text-xl 2xl:text-4xl leading-relaxed">
                <span className="text-camel font-Poppins mr-2">
                  {title == "Muharram" || title == "Rajab" || title == "Sha‘bān"
                    ? "|"
                    : null}
                  {/* |  */}
                </span>
                {/* 10th of Muharram, */}
                {title == "Muharram"
                  ? "The Day of Ashura"
                  : null || title == "Rajab"
                  ? "Isra’ and Mi`raj"
                  : null || title == "Sha‘bān"
                  ? "Laylat al-Nisf min Sha`ban -"
                  : null}
                <span className="text-camel">
                  {title == "Sha‘bān" ? " The Fifteenth Night of Sha`ban" : ""}
                  {/* Day of Ashura */}
                </span>
              </h2>
            </>
          )}

          <p className="leading-7 mt-4 text-sm xl:text-base">
            {text2}
            {/* A year lasts for about 354 days and consists of 12 months in the
            Islamic/Hijri calendar. As the first month of the year, Muharram is
            known as the month of remembrance and it is believed to be the most
            sacred month. Muslims are not allowed to fight during Muharram. Some
            important Islamic historical events that happened during the month
            include: <br /><br />The Battle of Karbala (currently in Iraq) in the year 680
            CE, which enabled Husayn ibn Ali, a grandson of the Prophet
            Muhammed, and his army to enter the city on the first day of the
            month. The restriction of Husayn ibn Ali's access to water on the
            seventh day. The death of Husayn ibn Ali and his clan (Ahl al-Bayt)
            on the 10th day of the month. */}
          </p>
        </div>
        <img
          src={img2}
          className="my-4 xl:my-0 mt-10 w-auto mx-auto h-auto rounded-3xl"
        />
      </div>
    </div>
  );
}
