
	const InfoMsg = (props:any) => {

		if(props.isShown){
		return(
			<div className="transform -translate-x-full xl:-translate-x-full w-44 xl:w-96 absolute bottom-10 z-30">
				<div className={`box ${props?.arrowRight ? "arrow-right" : "arrow-bottom"} shadow-lg text-xs xl:text-sm`}>
					{props.textWithJsx ? 
						<span dangerouslySetInnerHTML={{__html: props.textWithJsx}}></span>
					: props.text }
				</div>
			</div>
			)
		}
		else{
			return null;
		}
	}     

	export default InfoMsg;    