import React, { useReducer, Fragment } from "react";
import { reducer, initialState } from "../utils/store";
import snow_black from "../assets/landing/snow_black.svg";
import gold_snow from "../assets/landing/snow_gold.svg";
import partners from "../assets/faqs/partners.svg";
import users from "../assets/faqs/users.svg";
import charity from "../assets/faqs/charity.svg";
import Collapse from "@kunukn/react-collapse";
import islam from "../assets/faqs/islam.svg";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { AiOutlineSearch, AiFillCloseCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
import faqs from "./../faqs_data.json";

export default function FAQs(props: any) {
  const [name, setName] = React.useState("About CharityMoments");
  // const [isOpen, setIsOpen] = React.useState(false);
  const [isOpened, setIsOpened] = React.useState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [nameSecond] = React.useState();
  const [faqsList, setFaqsList] = React.useState([]);
  const [faqIndex, setFaqIndex] = React.useState(0);
  const [searchFaq, setSearchFaq] = React.useState("");

  const onToggle = (index: any) => {
    setFaqIndex(faqIndex === index ? null : index);
  };
  const handleFaqCategory = (faqTitle: any) => {
    setName(faqTitle);
    const filterByCategory = [...faqs];
    const result = filterByCategory.filter((item) => item.name === faqTitle);
    setFaqsList(result);
    setFaqIndex(0);
  };

  React.useEffect(() => {
    const filterByCategory = [...faqs];
    const result = filterByCategory.filter(
      (item: any) => item.name === "About CharityMoments"
    );
    setFaqsList(result);
  }, []);

  const initialSearchFilter = () => {
    const filterByCategory = [...faqs];
    const result = filterByCategory.filter(
      (item: any) => item.name === "About CharityMoments"
    );
    setFaqsList(result);
    setSearchFaq("");
  };

  const handleFaqSeach = (value: any) => {
    setSearchFaq(value);

    if (value.toLowerCase().trim() === "") {
      const result = faqs.filter(
        (item: any) => item.name === "About CharityMoments"
      );
      setFaqsList(result);
    } else {
      const filterByCategory = [...faqs];
      let filteredList = filterByCategory.filter((item: any) => {
        return (
          item.title.toLowerCase().trim().indexOf(value.toLowerCase().trim()) >=
            0 ||
          item.text.toLowerCase().trim().indexOf(value.toLowerCase().trim()) >=
            0
        );
      });
      setFaqsList(filteredList);
    }
  };

  return (
    <div className="bg-white mx-4 md:mx-8 rounded-3xl px-4 pt-6 md:pt-24 relative">
      <Helmet>
        <title>FAQs | CharityMoments</title>
        <meta
          name="description"
          content="Frequently asked questions about our platform and how we do things."
        />
      </Helmet>
      <img src={gold_snow} className="absolute bottom-6 w-16 left-10" />
      <img src={gold_snow} className="absolute top-16 w-10 md:left-14" />
      <img src={gold_snow} className="absolute bottom-96 w-6 right-14" />
      <img src={snow_black} className="absolute top-96 w-5 left-4 opacity-25" />
      <h1 className="text-center text-xl md:text-2xl xl:text-4xl 2xl:text-8xl mb-8 md:mb-14">
        Frequently Asked Questions (FAQs)
      </h1>

      <div
        style={{ maxWidth: 700 }}
        className="mt-1 relative rounded-md shadow-sm flex-grow ml-auto mr-auto"
      >
        <input
          style={{ backgroundColor: "#fafafa" }}
          type="text"
          name="price"
          id="price"
          className=" focus:ring-gray-200   focus:outline-none focus:ring focus:border-gray-200 text-base  focus:outline-none  block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-12"
          placeholder="Ask a question"
          onChange={(e) => handleFaqSeach(e.target.value)}
          value={searchFaq}
        />
        <div
          style={{ display: searchFaq === "" ? "none" : "" }}
          className="absolute inset-y-0 right-10 flex items-center"
          onClick={initialSearchFilter}
        >
          <AiFillCloseCircle
            className="w-10 h-6 mx-3"
            style={{ color: "#7e7e7e", cursor: "pointer" }}
          />
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center">
          <AiOutlineSearch className="text-camel w-10 h-6 mx-3" />
        </div>
      </div>
      <p
        style={{ display: searchFaq !== "" ? "none" : "" }}
        className="text-center mt-6 text-base"
      >
        Or choose a category to quickly find the help you need.
      </p>
      <div
        style={{ display: searchFaq !== "" ? "none" : "" }}
        className="lg:grid lg:grid-cols-4  px-12 lg:ml-auto 2xl:w-3/4 xl:w-2/2 lg:mr-auto mt-14 sm:grid sm:grid-cols-1 md:grid md:grid-cols-2 lg:gap-x-10 md:gap-x-10 sm:gap-y-5  "
      >
        <button
          style={{ maxWidth: 400 }}
          onClick={() => handleFaqCategory("About CharityMoments")}
          className="bg-white mb-5 place-self-center  grid grid-cols-1sm:mb-5 w-full focus:text-yellow-400 h-40 shadow-xl rounded-lg shadow-round group bg-white rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-yellow-500  md:mx-5 rounded-3xl"
        >
          <img src={charity} className="place-self-center -mb-10" />
          <span className="mr-auto ml-auto text-sm xl:text-base">
            About CharityMoments
          </span>
        </button>
        <button
          style={{ maxWidth: 400 }}
          onClick={() => handleFaqCategory("Users & Accounts")}
          className="bg-white mb-5 place-self-center grid grid-cols-1 h-40 w-full sm:mb-5 focus:text-yellow-400 shadow-round rounded-lg shadow-lg group bg-white rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-yellow-500  md:mx-5 rounded-3xl "
        >
          <img src={users} className="place-self-center -mb-10" />
          <span className="mr-auto ml-auto text-sm xl:text-base">
            Users & Accounts
          </span>
        </button>
        <button
          style={{ maxWidth: 400 }}
          onClick={() => handleFaqCategory("Charity Partners & Causes")}
          className="bg-white place-self-center mb-5 grid w-full grid-cols-1sm:mb-5 h-40 focus:text-yellow-400 shadow-round rounded-lg shadow-lg group bg-white rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-yellow-500  md:mx-5 rounded-3xl"
        >
          <img src={partners} className="place-self-center -mb-10" />
          <span className="mr-auto ml-auto text-sm xl:text-base px-1.5">
            Charity Partners & Causes
          </span>
        </button>
        <button
          style={{ maxWidth: 400 }}
          onClick={() => handleFaqCategory("Islam")}
          className="bg-white mb-5 h-40 w-full place-self-center focus:text-yellow-400 grid grid-cols-1 sm:mb-5 shadow-round rounded-lg shadow-lg group  text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-yellow-500  md:mx-5 rounded-3xl"
        >
          <img src={islam} className="place-self-center -mb-10" />
          <span className="mr-auto ml-auto text-sm xl:text-base">Islam</span>
        </button>
      </div>
      <h1
        style={{ display: searchFaq !== "" ? "none" : "" }}
        className="text-center text-xl xl:text-4xl text-black font-bold mt-12 mb-5 font-PoppinsSemiBold"
      >
        {name}
      </h1>

      {name != "" ? (
        <>
          <p
            style={{ display: searchFaq !== "" ? "none" : "" }}
            className="text-center text-xs xl:text-base 2xl:text-lg font-Poppins"
          >
            Get answers to all your questions.
          </p>
          <div>
            <section className="text-gray-700">
              <div className="container md:px-5 md:py-24 pt-8 pb-6 mx-auto">
                <div className="flex flex-col lg:w-5/5 md:-mx-2">
                  <div className="w-full lg:w-2/2 md:px-4 md:py-2 ">
                    {faqsList &&
                      faqsList.map((item: any, index) => {
                        return (
                          <Fragment>
                            <button
                              className="
                                relative
                                2xl:mx-24
                                xl:mx-32
                                font-semibold
                                shadow-xl
                                shadow-round
                                w-full
                                xl:w-4/5
                                2xl:w-11/12
                                2xl:max-w-screen-xl
                                2xl:text-lg
                                text-left
                                bg-white border border-gray-100 rounded-md
                                py-3 px-4
                                rounded-xl
                                focus:outline-none hover:text-gray-500 flex flex-row "
                              onClick={() => onToggle(index + 1)}
                            >
                              {item.title}
                              <div className="ml-auto pl-2 ">
                                <div className="bg-camel rounded-full ml-auto mr-1 w-5 h-5 ">
                                  {faqIndex === index + 1 ? (
                                    <VscChevronUp className="text-white m-auto mt-0.5 pt-0.5" />
                                  ) : (
                                    <VscChevronDown className="text-white m-auto mt-0.5 pt-0.5" />
                                  )}
                                </div>
                              </div>
                            </button>
                            <Collapse
                              className="mb-6"
                              isOpen={faqIndex === index + 1}
                              transition="height 300ms cubic-bezier(.4, 0, .2, 1)"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: `${item.text}`,
                                }}
                                className="xl:mx-32 2xl:mx-24 z-30 relative w-full xl:w-4/5 2xl:w-full 2xl:max-w-screen-xl bg-gray-100 rounded px-5 py-6 rounded-b-lg 2xl:text-base md:text-sm text-sm font-PoppinsMedium"
                              ></p>
                            </Collapse>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : null}
    </div>
  );
}
