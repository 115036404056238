import React, { useContext, useEffect } from "react";
import bg2 from "../assets/first_time/bg_3.png";
import bg1 from "../assets/first_time/bg1.png";
import bg_2 from "../assets/firsttime_bg.png";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import axios from "../utils/axios";
import Frequency from "./FirstTime/Frequency";
import SelectIslamicDays from "./FirstTime/SelectIslamicDays";
import SelectPersonalDays from "./FirstTime/SelectPresonalDays";
import Checkout from "./FirstTime/Checkout";
import CausesContribution from "./FirstTime/CausesContribution";
import ChooseYourCharities from "./FirstTime/ChooseYourCharities";
import frequency from "../assets/icons/frequency.svg";
import frequency_gold from "../assets/icons/frequency_gold.svg";
import checkout from "../assets/icons/checkout.svg";
import checkout_gold from "../assets/icons/checkout_gold.svg";
import islam from "../assets/icons/islam.svg";
import islam_gold from "../assets/icons/islam_gold.svg";
import user from "../assets/icons/user.svg";
import user_gold from "../assets/icons/user_gold.svg";
import causes from "../assets/icons/causes.svg";
import causes_gold from "../assets/icons/causes_gold.svg";
import charity from "../assets/icons/charity.svg";
import charity_gold from "../assets/icons/charity_gold.svg";
import linkedin from "../assets/icons/linkedin.png";
import twitter from "../assets/icons/group-8805.png";
import instagram from "../assets/icons/group-8806.png";
import facebook from "../assets/icons/group-8807.png";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import Bell from "../assets/icons/bell_2x.png";
import { MenuContext } from "react-flexible-sliding-menu";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
import Swal from "sweetalert2";

export default function FirstTime() {
  let history = useHistory();
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const { state, dispatch } = useContext(Context);
  const { toggleMenu } = useContext(MenuContext);
  const [page, setPage] = React.useState("Frequency");
  const [currentPage, setCurrentPage] = React.useState(<></>);
  const [loading, setLoading] = React.useState(false);
  const [minDonation, setMinDonation] = React.useState();

  const allPages = [
    {
      name: "Frequency",
      btnTitle: "Select Your Donation Frequency",
      title: "Select Your Donation Frequency",
    },
    {
      name: "SelectIslamicDays",
      btnTitle: "Select Islamic Days",
      title: "Select Islamic Days",
    },
    {
      name: "SelectPersonalDays",
      btnTitle: "Select Personal Days",
      title: "Select Personal Days",
    },
    {
      name: "ChooseYourCharities",
      btnTitle: "Choose Your Charities",
      title: "Choose Your Charities",
    },
    {
      name: "CausesContribution",
      btnTitle: "Causes and Contributions",
      title: "Causes and Contributions",
    },
    { name: "Checkout", btnTitle: "Proceed to Checkout", title: "Checkout" },
  ];

  const getDonationMin = async () => {
    await axios
      .get("/config-settings")
      .then((response) => {
        setMinDonation(response.data.response.MIN_DONATION_AMOUNT);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDonationMin();
    Mixpanel.track("View donation-automation Page");
    dispatch({ type: "pagesList", pagesList: allPages });
    setPage(state?.firstTimeCurrentPage);
    document.getElementsByTagName('body')[0].classList.add('sticky-menu')
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('sticky-menu')
    }
  }, []);

  useEffect(() => {
    setPage(state.firstTimeCurrentPage);
  }, [state.firstTimeCurrentPage]);

  const checkAndSetPage = (page: any) => {
    const checkpointPages = [
      "ChooseYourCharities",
      "CausesContribution",
      "Checkout",
    ];

    const charityPointPages = ["CausesContribution", "Checkout"];

    const checkoutPointPages = ["Checkout"];

    if (checkpointPages.indexOf(page) >= 0) {
      let selectedIslamicDays =
        state?.firstTimeInfo?.islamicDays &&
        state?.firstTimeInfo?.islamicDays?.filter(
          (islamicDay: { isIslamicDaySelected: boolean }) =>
            islamicDay.isIslamicDaySelected
        ).length;

      let selectedPersonalDays =
        state?.firstTimeInfo?.personalDays &&
        state?.firstTimeInfo?.personalDays?.filter(
          (personalDay: { date?: object }) =>
            personalDay.date && personalDay.isPersonalDayActive
        ).length;

      let selectedOtherDays =
        state?.firstTimeInfo?.otherDays &&
        state?.firstTimeInfo?.otherDays?.filter(
          (otherDay: { isSelected: boolean }) => otherDay.isSelected
        ).length;

      let selectedCharities =
        state?.firstTimeInfo?.charities &&
        state?.firstTimeInfo?.charities?.filter(
          (charity: { isSelected: any; isActive: any }) =>
            charity.isActive && charity.isSelected
        ).length;

      let selectedDonationTital = state?.firstTimeInfo?.donationTotal;

      if (state?.firstTimeInfo?.donationFreq === "recurring") {
        if (
          (state?.firstTimeInfo?.islamicDays != null &&
            selectedIslamicDays !== 0) ||
          (state?.firstTimeInfo?.personalDays != null &&
            selectedPersonalDays !== 0) ||
          (state?.firstTimeInfo?.otherDays != null && selectedOtherDays !== 0)
        ) {
          if (charityPointPages.indexOf(page) >= 0) {
            if (
              state?.firstTimeInfo?.charities != null &&
              selectedCharities !== 0
            ) {
              if (checkoutPointPages.indexOf(page) >= 0) {
                if (selectedDonationTital < minDonation) {
                  if (selectedDonationTital <= 0) {
                    Toast.fire({
                      title: "Donation Amount is required.",
                      icon: "error",
                    });
                  } else {
                    Toast.fire({
                      title: `Total donation amount should be at least $${minDonation}`,
                      icon: "error",
                    });
                  }
                } else {
                  setPage(page);
                }
              } else {
                setPage(page);
              }
            } else {
              Toast.fire({
                title: "Select at least one charity partner",
                icon: "error",
              });
            }
          } else {
            setPage(page);
          }
        } else {
          Toast.fire({
            title:
              "Select at least one Islamic day or one Sunnah day or one personal day",
            icon: "error",
          });
        }
      } else {
        if (
          (state?.firstTimeInfo?.islamicDays != null &&
            selectedIslamicDays !== 0) ||
          (state?.firstTimeInfo?.personalDays != null &&
            selectedPersonalDays !== 0)
        ) {
          if (charityPointPages.indexOf(page) >= 0) {
            if (
              state?.firstTimeInfo?.charities != null &&
              selectedCharities !== 0
            ) {
              if (checkoutPointPages.indexOf(page) >= 0) {
                if (selectedDonationTital < minDonation) {
                  if (selectedDonationTital <= 0) {
                    Toast.fire({
                      title: "Donation Amount is required.",
                      icon: "error",
                    });
                  } else {
                    Toast.fire({
                      title: `Total donation amount should be at least $${minDonation}`,
                      icon: "error",
                    });
                  }
                } else {
                  setPage(page);
                }
              } else {
                setPage(page);
              }
            } else {
              Toast.fire({
                title: "Select at least one charity partner",
                icon: "error",
              });
            }
          } else {
            setPage(page);
          }
        } else {
          Toast.fire({
            title: "Select at least one Islamic day or one personal day",
            icon: "error",
          });
        }
      }
    } else {
      setPage(page);
    }
  };

  useEffect(() => {
    console.log(page);
    if (page == "Frequency") {
      Mixpanel.track("View Frequency Page");
      setCurrentPage(<Frequency />);
    } else if (page == "SelectIslamicDays") {
      Mixpanel.track("View SelectIslamicDays Page");
      setCurrentPage(<SelectIslamicDays />);
    } else if (page == "SelectPersonalDays") {
      Mixpanel.track("View SelectPersonalDays Page");
      setCurrentPage(<SelectPersonalDays />);
    } else if (page == "ChooseYourCharities") {
      Mixpanel.track("View ChooseYourCharities Page");
      setCurrentPage(<ChooseYourCharities />);
    } else if (page == "CausesContribution") {
      Mixpanel.track("View CausesContribution Page");
      setCurrentPage(<CausesContribution />);
    } else if (page == "Checkout") {
      Mixpanel.track("View Checkout Page");
      setCurrentPage(<Checkout />);
    }
  }, [page]);

  const backHandler = () => {
    let currentIndex = allPages.findIndex((el) => el.name === page);
    console.log(currentIndex);
    setPage(allPages[currentIndex - 1].name);
    dispatch({
      type: "firstTimeCurrentPage",
      firstTimeCurrentPage: allPages[currentIndex - 1].name,
    });
  };

  const nextStep = () => {
    let currentIndex = allPages.findIndex((el) => el.name === page);
    let currentPage = allPages.find((el) => el.name === page);

    if (
      currentPage.name == "SelectPersonalDays" ||
      currentPage.name == "Checkout"
    ) {
      let isSelectedPersonalDays = state?.firstTimeInfo?.personalDays.find(
        (el: any) => el.isPersonalDayActive == true
      );
      let isSelectedIslamicDays = state?.firstTimeInfo?.islamicDays;
      if (
        isSelectedPersonalDays == undefined &&
        isSelectedIslamicDays == null
      ) {
        Toast.fire({
          title: "Islamic Days/Personal Days are required.",
          icon: "error",
        });
        //setLoading(false);
        return;
      }
    }

    if (
      currentPage.name == "SelectPersonalDays" ||
      currentPage.name == "ChooseYourCharities" ||
      currentPage.name == "CausesContribution" ||
      currentPage.name == "Checkout"
    ) {
      let selectedIslamicDays = state?.firstTimeInfo?.islamicDays?.filter(
        (islamicDay: { isIslamicDaySelected: boolean }) =>
          islamicDay.isIslamicDaySelected
      ).length;

      let selectedPersonalDays = state?.firstTimeInfo?.personalDays?.filter(
        (personalDay: { date?: object }) =>
          personalDay.date && personalDay.isPersonalDayActive
      ).length;

      let selectedOtherDays = state?.firstTimeInfo?.otherDays?.filter(
        (otherDay: { isSelected: boolean }) => otherDay.isSelected
      ).length;

      if (state?.firstTimeInfo?.donationFreq === "recurring") {
        if (
          selectedIslamicDays === 0 &&
          selectedPersonalDays === 0 &&
          selectedOtherDays === 0
        ) {
          Toast.fire({
            title:
              "Select at least one Islamic day or one Sunnah day or one personal day",
            icon: "error",
          });
          return;
        }
      } else {
        if (selectedIslamicDays === 0 && selectedPersonalDays === 0) {
          Toast.fire({
            title: "Select at least one Islamic day or one personal day",
            icon: "error",
          });
          return;
        }
      }
    }

    if (
      currentPage.name == "ChooseYourCharities" ||
      currentPage.name == "CausesContribution" ||
      currentPage.name == "Checkout"
    ) {
      let selectedCharities = state?.firstTimeInfo?.charities?.filter(
        (charity: { isSelected: any; isActive: any }) =>
          charity.isActive && charity.isSelected
      ).length;

      if (selectedCharities === 0) {
        Toast.fire({
          title: "Select at least one charity partner",
          icon: "error",
        });
        return;
      }
    }

    if (
      currentPage.name == "CausesContribution" ||
      currentPage.name == "Checkout"
    ) {
      // alert(state?.firstTimeInfo?.donationTotal);

      if (state?.firstTimeInfo?.donationTotal < minDonation) {
        if (state?.firstTimeInfo?.donationTotal <= 0) {
          Toast.fire({
            title: "Donation Amount is required.",
            icon: "error",
          });
          return;
        } else {
          Toast.fire({
            title: `Total donation amount should be at least $${minDonation}`,
            icon: "error",
          });
          //setLoading(false);
          return;
        }
      }
    }
    setPage(allPages[currentIndex + 1].name);
    dispatch({
      type: "firstTimeCurrentPage",
      firstTimeCurrentPage: allPages[currentIndex + 1].name,
    });
  };

  return (
    <>
      <Helmet>
        <title>Schedule Your Donation | CharityMoments</title>
        <meta
          name="description"
          content="Create your donation schedule with CharityMoments."
        />
      </Helmet>
      <div className="lg:hidden bg-white w-max p-1 rounded-r-lg shadow-fullCard mb-4">
        <button
          onClick={toggleMenu}
          className="ml-4 items-center px-3 py-2 rounded text-teal-200"
        >
          <svg
            className="fill-current text-camel h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        <span className="text-gray-500 px-2 text-sm">Checkout Steps</span>
      </div>
      <div className="px-0 sm:px-4 xl:px-10  w-full h-screen/2 p-2 grid grid-cols-1 auto-cols-max lg:grid-cols-4 page-content-cnt">
        <div
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
          }}
          className="hidden lg:flex px-2 bg-white h-screen/2 md:px-4 2xl:px-12 md:mr-8 lg:mr-4 xl:mr-8 mb-8 rounded-side md:relative text-side min-h-540 fixed-menu-col"
        >
          <div className="relative h-full w-full z-20">
            <button
              onClick={() => checkAndSetPage("Frequency")}
              className={
                page != "Frequency"
                  ? "z-50 flex flex-row text-gray-500 rounded-side p-3 2xl:p-4 focus:outline-none w-full text-left mt-10 text-sm 2xl:text-side font-Poppins"
                  : "z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex flex-row w-full text-left mt-10 text-sm 2xl:text-side font-Poppins"
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != "Frequency" ? frequency : frequency_gold}
              />
              Frequency
            </button>
            <button
              onClick={() => checkAndSetPage("SelectIslamicDays")}
              className={
                page != "SelectIslamicDays"
                  ? "z-50 text-gray-500 rounded-side p-3 2xl:p-4  focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins"
                  : "z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex w-full text-left text-sm 2xl:text-side font-Poppins"
              }
            >
              <img
                className="mr-2 ml-2 h-4 mt-auto mb-auto"
                src={page != "SelectIslamicDays" ? islam : islam_gold}
              />
              Select Islamic Days
            </button>
            <button
              onClick={() => checkAndSetPage("SelectPersonalDays")}
              className={
                page != "SelectPersonalDays"
                  ? "z-50 text-gray-500 rounded-lg p-3 2xl:p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side"
                  : "z-50 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex flex w-full text-left text-sm 2xl:text-side"
              }
            >
              <img
                className="mr-2 ml-2 mt-auto h-5 mb-auto"
                src={page != "SelectPersonalDays" ? user : user_gold}
              />
              Select Personal Days
            </button>
            <button
              onClick={() => checkAndSetPage("ChooseYourCharities")}
              className={
                page != "ChooseYourCharities"
                  ? "z-50 flex flex-row text-gray-500 rounded-side p-3 2xl:p-4 focus:outline-none w-full text-left text-sm 2xl:text-side font-Poppins "
                  : "z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex flex-row w-full text-left text-sm 2xl:text-side font-Poppins"
              }
            >
              <img
                className="mr-2 ml-1.5 h-4 mt-auto mb-auto"
                src={page != "ChooseYourCharities" ? charity : charity_gold}
              />
              Choose Your Charities
            </button>
            <button
              onClick={() => checkAndSetPage("CausesContribution")}
              className={
                page != "CausesContribution"
                  ? "z-50 flex flex-row text-gray-500 rounded-side p-3 2xl:p-4 focus:outline-none w-full text-left text-sm 2xl:text-side font-Poppins "
                  : "z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex flex-row w-full text-left text-sm 2xl:text-side font-Poppins"
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto -mt-0.5 h-5"
                src={page != "CausesContribution" ? causes : causes_gold}
              />
              Causes & Contribution
            </button>
            <button
              onClick={() => checkAndSetPage("Checkout")}
              className={
                page != "Checkout"
                  ? "z-50 flex flex-row text-gray-500 rounded-side p-3 2xl:p-4 focus:outline-none w-full text-left text-sm 2xl:text-side font-Poppins "
                  : "z-20 bg-gray-100 focus:outline-none text-camel rounded-side p-3 2xl:p-4 flex flex-row w-full text-left text-sm 2xl:text-side font-Poppins"
              }
            >
              <img
                className="mr-2 ml-1.5 h-5"
                src={page != "Checkout" ? checkout : checkout_gold}
              />
              Checkout
            </button>
            <div className="absolute bottom-4 md:bottom-4 2xl:bottom-10 h-auto w-full ">
              <div className="justify-center flex flex-wrap">
                <a
                  href="mailto:salaam@charitymoments.com"
                  className="bg-transparent text-brownGrey relative mb-2 p-2 text-xs 2xl:text-sm 2xl:text-base hover:opacity-80"
                >
                  salaam@charitymoments.com
                </a>
                <div className="relative mb-6 justify-center flex flex-row bg-transparent">
                  <p className="mt-auto text-xs 2xl:text-sm mb-auto text-brownGrey text-center">
                    <a href="/terms-and-conditions">Terms and Conditions</a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="/privacy-policy">Privacy Policy</a>
                  </p>
                  {/* <p>|</p>
              <p className="mt-auto text-xs 2xl:text-sm mb-auto ml-2">Privacy Policy</p> */}
                </div>
              </div>
              <div className="justify-center flex flex-wrap lg:mb-8 pt-0 sm:mb-3 relative bg-white">
                {/* Made with ❤️ in NYC */}
                <a
                  className="hover:opacity-80"
                  href="https://www.facebook.com/gocharitymoments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={facebook} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://twitter.com/Charity_Moments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={twitter} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.linkedin.com/company/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={linkedin} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.instagram.com/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8" src={instagram} />
                </a>
              </div>
            </div>
          </div>
          {/* <img
            className="hidden xl:flex absolute z-10 bottom-0 h-full left-0 w-full"
            src={bg1}
          /> */}
        </div>
        <div
          style={{
            backgroundImage: `url(${bg2})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
            backgroundSize: "fixed",
          }}
          className="px-3 bg-white pb-8 md:px-12 lg:px-5 xl:px-12 mb-10 min-h-screen/2 rounded-side col-span-3 relative md:mb-8 xl:lg:col-start-2 lg:col-start-2"
        >
          <div className="mt-4 md:mt-8 md:mb-6 mb-2">
            {page === "Frequency" ? null : (
              <img
                onClick={backHandler}
                className="inline-block transform -translate-y-1 h-4 md:h-auto mr-3 relative z-20 cursor-pointer"
                src={ArrowLeft}
              />
            )}
            <h1 className="inline-block text-base lg:text-xl 2xl:text-2xl mt-0 xl:ml-1 ml-4 lg:ml-2 md:mb-4 mb-3 z-50 base-title-text">
              {allPages.find((x) => x.name === page).title}
            </h1>
            <hr />
          </div>
          {currentPage}
          {page == "Checkout" ? null : (
            <Button
              action={nextStep}
              style={
                "items-center justify-center md:mt-6 px-12 py-3 relative flex flex-1 content-center ml-auto mr-auto md:ml-0 text-xs shadow-button md:text-base text-Poppins hover:opacity-90 relative z-10 btn-res-spacing"
              }
              width="auto"
              title={
                "Next: " +
                allPages[allPages.findIndex((x) => x.name === page) + 1]
                  .btnTitle +
                " >"
              }
            />
          )}
          {/* <img
            className="hidden 2xl:flex absolute z-10 bottom-0 w-screen"
            src={bg2}
          /> */}
        </div>
      </div>
    </>
  );
}
