import React, { useState, useEffect } from "react";
import axios from "../utils/axios";
import Button from "../components/Button";
import { useParams } from "react-router";
import charity_partner from "../assets/charity_partner.png";
import SkeletonLoader from "../components/SkeletonLoader";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../Mixpanel";
import smoothscroll from "smoothscroll-polyfill";

export default function CharityPartnersDetails() {
  const [showLoader, setShowLoader] = useState(true);
  const [articles, setArticles] = useState<Array<any> | null>(null);
  const [charity, setCharity] = useState({
    country: "",
    startedYear: "",
    name: "",
    description: "",
    image: "",
    logo: "",
    status: "",
    isVerified: false,
    certificate: "",
  });
  const { id } = useParams();

  window.__forceSmoothScrollPolyfill__ = true;
  smoothscroll.polyfill();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEffect(() => {
    axios
      .get(`/charities/${id}`)
      .then((response) => {
        // console.log(response.data.response);
        setCharity(response.data.response);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
    axios
      .get(`/causesforcharity/${id}`)
      .then((response) => {
        setArticles(response.data.response);
      })
      .catch((err) => console.log(err));
    // setArticles([{name:'Meeting new Islamic faces & What we need to',description:'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live...'},{name:'Meeting new Islamic faces & What we need to',description:'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live...'},{name:'Meeting new Islamic faces & What we need to',description:'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live...'}])
  }, []);

  let articleBlocks = null;
  if (articles) {
    articleBlocks = articles?.map((el, index) => (
      <ArticleBlock
        img={el.image}
        key={index}
        title={el.name}
        description={el.description}
      />
    ));
  }

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Learn about the trusted charity partners we work with to ensure your donations are making the maximum impact."
        />
        <title>Charity Partners | CharityMoments</title>
      </Helmet>
      <img
        className="hidden md:flex absolute top-0 w-screen"
        src={charity_partner}
      />
      <Block>
        <SkeletonLoader showLoader={showLoader} />
        <h1 className="text-center text-2xl md:text-8xl pb-8 md:mb-12">
          {charity.name}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 justify-items-center">
          <img className="rounded-xl shadow-camel" src={charity.image} />
          <div className="mt-2 md:pl-8">
            <img
              src={charity.logo}
              className="h-16 2xl:h-20 w-auto mb-4 mt-4 md:mt-0"
            />
            {charity ? (
              <div className="">
                <div className="flex flex-wrap content-center grid grid-cols-4">
                  <p className="text-black text-xs 2xl:text-xl  table-cell ">
                    {charity.startedYear ? "Started In:" : null}
                  </p>
                  <p className="text-xs 2xl:text-xl col-span-3 pl-2 table-cell">
                    {charity.startedYear}
                  </p>
                </div>
                <div className="flex flex-wrap content-center grid grid-cols-4 py-3">
                  <p className="text-black text-xs 2xl:text-xl  table-cell  ">
                    {charity.country ? "Country:" : null}
                  </p>
                  <p className="text-xs 2xl:text-xl col-span-3 pl-2 table-cell">
                    {charity.country}
                  </p>
                </div>
                <div className="flex flex-wrap content-center grid grid-cols-4">
                  <p className="text-black text-xs 2xl:text-xl  table-cell  ">
                    {charity.description ? "Works On:" : null}
                  </p>
                  <p className="text-xs 2xl:text-xl col-span-3 pl-2 table-cell">
                    {charity.description}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 xl:grid-cols-3 my-12">
          <SkeletonLoader showLoader={showLoader} />
          <SkeletonLoader showLoader={showLoader} />
          <SkeletonLoader showLoader={showLoader} />
          {articleBlocks}
        </div>

        {charity.isVerified ? (
          <div>
            <div className="border-t" />
            <div className="grid grid-cols-1 xl:grid-cols-2 mt-12 ">
              <h2 className="md:text-2xl md:leading-10 font-semibold whitespace-pre-line ">
                Charity<span className="text-camel  ">Moments</span> is proud to
                be an{" "}
                <span className="lg:whitespace-pre-line">
                  official partner with {charity.name}.
                </span>
              </h2>
              <div className="text-right mt-4 md:mt-10 xl:mt-4 ml-0 xl:ml-auto m-auto xl:mr-0 rounded-lg shadow-camel">
                <Button
                  title="See proof of partnership >"
                  action={() => window.open(charity?.certificate, "_blank")}
                ></Button>
              </div>
            </div>
          </div>
        ) : null}
      </Block>
    </div>
  );
}

const Block = (props: any) => {
  return (
    <div className="bg-white rounded-2xl px-4 sm:px-8 md:px-16 lg:px-40 xl:px-56 2xl:px-96 pt-10 mx-4 md:mx-8 md:pt-24 pb-10 xl:pb-12">
      {props.children}
    </div>
  );
};

const ArticleBlock = (props: any) => {
  let shortDescription = props.description;

  return (
    <div className="bg-white relative shadow-round rounded-lg  pb-4 mt-0">
      <div>
        <img
          className="w-full rounded-xl h-auto object-cover"
          src={props.img}
        />
      </div>
      <div className="px-4 py-4">
        <h4
          style={{ fontWeight: 400 }}
          className="text-left text-base 2xl:text-xl  mt-2 "
        >
          {props.title}
        </h4>
        <p className="text-left text-xs 2xl:text-base mt-3  leading-relaxed">
          {shortDescription}
        </p>
      </div>
    </div>
  );
};
