import React, { useContext } from "react";
import thanks from "../../assets/thankyou.png";
import pinterest from "../../assets/icons/group-8804.svg";
// import linkedin from "../assets/icons/linkedin.png";
import twitter from "../../assets/icons/group-8805.png";
import instagram from "../../assets/icons/group-8806.png";
import facebook from "../../assets/icons/group-8807.png";
import Button from "../../components/Button";
import gold_snow from "../../assets/landing/snow_gold.svg";
import black_snow from "../../assets/landing/snow_black.svg";
import black from "../../assets/landing/black.svg";
import logos from "../../assets/icons/social_icons.png";
import linkedin from "../../assets/icons/linkedin.png";

import { Context } from "../../utils/store";
export default function ThankYou() {
  const { state, dispatch } = useContext(Context);
  console.log(state.isLoggedIn);
  const press = () => {
    if (state.isLoggedIn == true) {
      window.location.href = "/profile";
    } else {
      window.location.href = "/login";
    }
  };
  return (
    <>
      <div
        style={{ backgroundImage: `url(${thanks})`, backgroundSize: "cover" }}
        className="rounded-3xl shadow-lg relative mx-5 thankyou-header"
      >
        {/* <img src={thanks}
                className="w-full object-cover h-36 2xl:h-auto rounded-3xl"/> */}
        <div className="relative">
          <h1
            style={{
              paddingTop: 82,
              textShadow: "0 1.5px 3px rgba(204, 173, 52, 0.6)",
            }}
            className="font-Montserrat text-center 2xl:text-5xl md:text-4xl sm:text-3xl text-2xl px-9"
          >
            THANK YOU FOR MAKING A DIFFERENCE!
          </h1>
          <p
            style={{
              marginTop: 68,
              textShadow: "0 1.5px 3px rgba(238, 213, 113, 0.33)",
            }}
            className="text-camel 2xl:text-4xl md:text-3xl sm:text-2xl text-xl font-PoppinsSemiBold text-center"
          >
            What you did is kind of a big deal.
          </p>
          <p
            style={{ marginTop: 33 }}
            className="px-5 2xl:text-xl md:text-lg sm:text-base text-sm text-center text-camel font-PoppinsSemiBold"
          >
            “Allah said: Spend in charity, O son of Adam, and I will spend on
            you.”
            <br />
            <span className="text-black text-center ">
              
              — The Messenger of Allah ﷺ
            </span>
          </p>
          <p
            style={{ marginTop: 117, paddingBottom: 35, marginBottom: 35 }}
            className="px-5 2xl:text-xl md:text-lg sm:text-base text-sm text-black text-center font-PoppinsSemiBold"
          >
            Every time we process your donation, you’ll receive an email confirmation. <br />
            <small style={{fontSize: "13px"}}>(Please check your junk/spam folder for the verification and confirmation email)</small>
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 gap-x-5 mx-5 md:grid-cols-1 md:gap-y-5 gap-y-5">
        <div className="shadow-lg relative  px-14 py-8 rounded-3xl bg-white grid md:mb-7 mb-5">
          <img
            src={black_snow}
            className="absolute w-7 top-6 right-14 opacity-25"
          />
          <div className="justify-center  flex flex-wrap content-center relative mb-5 ">
            <div className="justify-center flex flex-wrap lg:mb-0 sm:mb-3 relative">
              {/* Made with ❤️ in NYC */}
              <a
                className="hover:opacity-80"
                href="https://www.facebook.com/gocharitymoments"
                target="_blank"
              >
                <img className="h-8 mr-2" src={facebook} />
              </a>
              <a
                className="hover:opacity-80"
                href="https://twitter.com/Charity_Moments"
                target="_blank"
              >
                <img className="h-8 mr-2" src={twitter} />
              </a>
              <a
                className="hover:opacity-80"
                href="https://www.linkedin.com/company/charitymoments/"
                target="_blank"
              >
                <img className="h-8 mr-2" src={linkedin} />
              </a>
              <a
                className="hover:opacity-80"
                href="https://www.instagram.com/charitymoments/"
                target="_blank"
              >
                <img className="h-8" src={instagram} />
              </a>
            </div>
          </div>
          <p
            className="text-center text-black  2xl:text-lg md:text-base text-sm
                font-PoppinsMedium mb-6"
          >
            Help others celebrate the days of Allah by spreading the word.
          </p>
          <p className="2xl:text-lg md:text-base text-sm text-center font-PoppinsMedium mt-auto ">
            “If anyone calls others to follow right guidance, his reward will be
            equivalent to those who follow him (in righteousness) without their
            reward being diminished in any respect.”
            <br />
            <span className="text-black">— The Messenger of Allah ﷺ</span>
          </p>
        </div>
        <div className="shadow-lg  px-14 py-8 rounded-3xl bg-white grid relative md:mb-7 mb-5">
          <img
            src={gold_snow}
            className="absolute  top-16 -left-1 opacity-50"
          />
          <img
            src={gold_snow}
            className="absolute w-12 -bottom-2 left-12 opacity-25"
          />
          <p className="text-center text-black  font-PoppinsMedium mb-6 2xl:text-lg md:text-lg text-sm">
            Your generosity goes a long way in helping our most vulnerable
            members of society.
          </p>
          <p className="text-center text-black  font-PoppinsMedium mb-6 2xl:text-lg md:text-lg text-sm">
            May Allah truly bless you and your loved ones.
          </p>
          <p className="text-center 2xl:text-lg md:text-base text-sm font-PoppinsMedium mt-auto">
            “If anyone relieves a Muslim believer from one of the hardships of
            this worldly life, Allah will relieve him of one of the hardships of
            the Day of Resurrection…”
            <br />
            <span className="text-black">— The Messenger of Allah ﷺ</span>
          </p>
        </div>
        <div className="shadow-lg  px-14 py-8 rounded-3xl bg-white grid relative md:mb-7 mb-5">
          <img
            src={gold_snow}
            className="absolute w-12 bottom-6 left-4 opacity-25"
          />
          <img src={gold_snow} className="absolute  top-6 left-3 opacity-50" />
          <img
            src={gold_snow}
            className="absolute  bottom-28 right-16 opacity-25"
          />
          <img src={black} className="absolute  top-10 right-24 opacity-25" />
          <p className="md:mt-10 text-center font-PoppinsMedium text-black 2xl:text-lg md:text-base text-sm">
            Now that you’ve created your first schedule, want to add more
            events?
          </p>
          <p className="text-center font-PoppinsMedium text-black 2xl:text-lg md:text-base text-sm mt-5 md:m-0">
            Login to your account to create a new schedule.
          </p>

          <Button
            action={press}
            style="shadow-button lg:mt-auto ml-auto mr-auto mb-5 mt-5 lg:w-full"
            title="Access your account >"
          />
        </div>
      </div>
    </>
  );
}
