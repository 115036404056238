import React, { useState, useContext, useEffect } from "react";
import { Context } from "../utils/store";

const DayBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);
  const [date, setDate] = useState(null);

  const updateBasedOnFrequency = () => {
    let newOtherDays = state?.firstTimeInfo?.otherDays?.map((item: any) => ({
      ...item,
      isSelected: false,
    }));
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: {
        ...state.firstTimeInfo,
        otherDays: [...newOtherDays],
      },
    });
    console.log("New Otherdays Running");
  };

  useEffect(() => {
    if (state?.firstTimeInfo?.donationFreq === "oneTime") {
      updateBasedOnFrequency();
    }
  }, [0]);

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const removeDayHandler = (dayType, id) => {
    if (dayType == "islamicDays") {
      let newIslamicDays = [...state.firstTimeInfo.islamicDays];
      let index = newIslamicDays.findIndex((obj) => {
        if (obj._id == id) {
          return true;
        }
      });
      newIslamicDays[index].isActive = false;
      newIslamicDays[index].isIslamicDaySelected = false;
      dispatch({
        type: "firstTimeInfo",
        firstTimeInfo: {
          ...state.firstTimeInfo,
          islamicDays: newIslamicDays,
        },
      });
    } else if (dayType == "personalDays") {
      let newPersonalDays = [...state.firstTimeInfo.personalDays];
      let index = newPersonalDays.findIndex((obj) => {
        if (obj._id == id) {
          return true;
        }
      });
      newPersonalDays[index].date = null;
      newPersonalDays[index].isPersonalDayActive = false;
      dispatch({
        type: "firstTimeInfo",
        firstTimeInfo: {
          ...state.firstTimeInfo,
          personalDays: newPersonalDays,
        },
      });
    } else if (dayType == "otherDays") {
      let newOtherDays = [...state.firstTimeInfo.otherDays];
      let index = newOtherDays.findIndex((obj) => {
        if (obj._id == id) {
          return true;
        }
      });
      newOtherDays[index].isSelected = false;
      dispatch({
        type: "firstTimeInfo",
        firstTimeInfo: {
          ...state.firstTimeInfo,
          otherDays: newOtherDays,
        },
      });
    }
  };
  if (props?.dayType == "personalDays" && props?.date && !date) {
    let day = ("0" + (props?.date?.day).toString()).slice(-2);
    let month = ("0" + props?.date?.month).toString().slice(-2);
    let year = props?.date?.year;

    let newDate = `${month}/${day}/${year}`;
    setDate(newDate);
  }

  if (props.dayType == "islamicDays" || props.dayType == "personalDays") {
    return (
      <div className="shadow-round h-auto relative bg-white flex flex-row rounded-lg p-2 mb-3 days-block-card">
        <span className="font-semibold w-56 text-black mt-auto mb-auto px-2 cursor-default text-sm 2xl:text-lg">
          <span className="block text-base font-normal text-camel">
            {props.monthName}
          </span>
          <span className="relative">
            {props.isIslamicDays ? (
              <span className="font-normal mr-4">
                {props.dayOfMonth}
                <span className="text-black text-xs absolute top-0 font-normal">
                  {nth(props.dayOfMonth)}
                </span>
              </span>
            ) : null}
          </span>
          <span className="font-normal">{props.dayName}</span>
          {props.isPersonalDays && props.manualEntryText ? (
            <span className="block font-normal text-base">
              {props.manualEntryText}
            </span>
          ) : null}
          {props?.date ? (
            <span className="block font-normal text-grey2">Date: {date}</span>
          ) : null}
        </span>
        <div
          className={`inline-block bg-lightGrey py-2 w-auto px-3 self-center rounded-lg h-16 flex-right ml-auto ${props.scheduleStyle}`}
        >
          <p className="text-darkGreen text-center">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.amountPerDay)}
          </p>
          <p className="text-xs text-center font-thin">Per Day</p>
        </div>
        {!props?.removeIconShown ? null : (
          <span
            onClick={
              props?.profileRemoveDayHandler
                ? () => props?.profileRemoveDayHandler(props.dayType, props.id)
                : () => removeDayHandler(props.dayType, props.id)
            }
            className="x -top-2 -right-2"
          ></span>
        )}
      </div>
    );
  } else {
    return (
      <div className="shadow-round h-auto relative bg-white flex flex-row rounded-lg p-2 mb-3 days-block-card">
        <span className="font-semibold w-64 text-black mt-auto mb-auto pl-4 cursor-default text-sm 2xl:text-lg">
          <span className="font-normal">{props.name}</span>
        </span>
        <div className="inline-block bg-lightGrey py-2 px-3 self-center rounded-lg w-auto h-16 flex-right ml-auto">
          <p className="text-darkGreen text-center">${props.amountPerDay}</p>
          <p className="text-xs text-center font-thin">Per Day</p>
        </div>
        {!props?.removeIconShown ? null : (
          <span
            onClick={
              props?.profileRemoveDayHandler
                ? () => props?.profileRemoveDayHandler(props.dayType, props.id)
                : () => removeDayHandler(props.dayType, props.id)
            }
            className="x -top-2 -right-2"
          ></span>
        )}
      </div>
    );
  }
};

export default DayBlock;
