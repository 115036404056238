import React, { useContext, useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Context } from "../../utils/store";
import axios from "../../utils/axios";
import InfoCircle from "../../components/InfoCircle";
import InfoMsg from "../../components/InfoMsg";
import { useParams } from "react-router";

export default function ChooseYourCharities() {
  const { state, dispatch } = useContext(Context);
  const { charityId } = useParams();

  const [charities, setCharities] = useState<Array<any> | null>(null);
  
  const [charitiesResponse, setCharitiesResponse] = useState(null)
  const [causesResponse, setCausesResponse] = useState(null)

  const fetchAndSetData = async () =>{
    await axios.get("/charities")
    .then((response) => {
      setCharitiesResponse(response);
    }).catch((err) => console.log(err));

    await axios.get('/causes')
    .then((response)=>{
      setCausesResponse(response);
    }).catch(err=>console.log(err))
  }

  useEffect(()=>{
    // Setting Charities and Causes from response
    if(charitiesResponse && causesResponse){
      let newCausesContributions = [...causesResponse?.data?.response]
      newCausesContributions = newCausesContributions.filter((oneCause)=>{
        if(charityId){
          if(oneCause.parentCharity==charityId){ return true } 
          else return false
        }
        else{ 
          if(oneCause.isActive){
            return true;
          }
        }
      })
      let newCharities = charitiesResponse.data.charities.filter((oneCharity)=>{
        if(charityId){
          if(oneCharity._id==charityId){ return true } 
          else return false
        }
        else{ 
          if(oneCharity.isActive){
            return true;
          }
        }
      })

      newCharities.forEach((charity, index)=>{
        newCharities[index].isSelected = false;
      })

      dispatch({
          type: "firstTimeInfo",
          firstTimeInfo: {
            ...state.firstTimeInfo,
            charities: newCharities, causesContributions: newCausesContributions
          },
      });
      setCharities(newCharities); 
    }
  },[charitiesResponse, causesResponse])

  useEffect(() => {
    if(!state?.firstTimeInfo?.causesContributions || !state?.firstTimeInfo?.charities){
      fetchAndSetData()
    } 
    else{
      // Setting Charities from GLOBAL STATE:
      setCharities(state?.firstTimeInfo?.charities); 
    }
  }, []);


  const [button, setButton] = React.useState(0);

  let charityBlocks = null;
  if (charities) {
    charityBlocks = charities.map((el, index) => {
      return (
        <CharityBlock
          name={el.name}
          id={el._id}
          isSelected={el.isSelected}
          setCharities={setCharities}
          charities={charities}
          currentCharityIndex={index}
          img={el.logo}
        />
      );
    });
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-4 md:mb-10 page-content-main">
        {charityBlocks}
      </div>
    </div>
  );
}

const CharityBlock = (props: any) => {
  const { state, dispatch } = useContext(Context);

  const [charityInfoShown, setCharityInfoShown] = useState(false)
  const [causesOfThisCharity, setCausesOfThisCharity] = useState()

  useEffect(()=>{
    setCausesOfThisCharity(state.firstTimeInfo.causesContributions.filter(oneCause=>oneCause.parentCharity==props.id))
  },[state])

  const charityHandler = (isSelected: boolean) => {
    let newCharities = [...props.charities];
    newCharities[props.currentCharityIndex].isSelected = isSelected;
    props.setCharities(newCharities);
    dispatch({
      type: "firstTimeInfo",
      firstTimeInfo: { ...state.firstTimeInfo, charities: newCharities },
    });


    // Removing amount of donations if charity not selected:
      let unSelectedCharityTotal = 0;
      let newCausesContributions = [...state?.firstTimeInfo?.causesContributions];
      let unselectedCausesIds: any[] = []    
      newCausesContributions.forEach((oneCause)=>{
          if(oneCause.parentCharity==state.firstTimeInfo.charities[props.currentCharityIndex]._id){
              unselectedCausesIds.push(oneCause._id)
          }
      })

      newCausesContributions.filter((oneCause, index)=>{
           if(unselectedCausesIds.includes(oneCause._id)){
              unSelectedCharityTotal += !oneCause?.amount ? 0 : Number(oneCause?.amount);
              newCausesContributions[index].amount='';
           }
      })    
      let newDonationTotal = state.firstTimeInfo.donationTotal - unSelectedCharityTotal;

          dispatch({
              type: 'firstTimeInfo', 
              firstTimeInfo: {...state.firstTimeInfo, causesContributions: newCausesContributions, donationTotal: newDonationTotal}
          })
  };

  let causesOfThisCharityJsxList = causesOfThisCharity?.map(cause=>{
    return(
      `<p class="text-white mt-2 leading-5 text-base md:text-lg">${cause.name}</p>`
    )
  }).join('')

  return (
    <div className="h-56 shadow-round relative z-20  rounded-lg mb-5 mx-2 card-x-padding">
      <div className="absolute right-1 top-1">
      <InfoMsg
                arrowRight={true}
                isShown={charityInfoShown}
                textWithJsx={`<p class="text-white mt-2 leading-5 text-base md:text-lg">With ${props.name}, you can support the following causes:</p>` + causesOfThisCharityJsxList}
              />
        <InfoCircle 
          isInfoTextShown={charityInfoShown}
          toggleInfoText={()=>setCharityInfoShown((prev)=>!prev)}/>
      </div>
      <div className="flex h-36 p-4 py-0 flex items-center content-center">
        <img
          className="mx-auto p-2 max-h-24"
          src={props.img}
          alt="articleimage"
        />
      </div>
      <div
            className={
              props.isSelected
                ? "shadow-md bg-darkGreen pl-1 h-12 rounded-lg mx-auto w-full"
                : "shadow-md bg-brownGrey pl-1 h-12 rounded-lg mx-auto w-full"
            }
          >
            <div
              onClick={() => charityHandler(!props.isSelected)}
              className={
                props.isSelected
                  ? "h-12 cursor-pointer relative bg-lightGreen flex justify-center rounded-lg p-1 mb-3"
                  : "flex cursor-pointer justify-center h-12 relative bg-lightGrey flex flex-row rounded-lg p-1 mb-3"
              }
            >
              {props.isSelected ? (
                <div className="mr-2 mt-2 bg-darkGreen pt-1 w-6 h-6 rounded-full">
                  <FiCheck className="text-white m-auto w-4 h-4" />
                </div>
              ) : (
                <div className="mr-2 mt-2 bg-white pt-1 w-6 h-6 rounded-full">
                  <FiCheck className="text-black m-auto w-4 h-4" />
                </div>
              )}
              <p className="inline-block mt-auto mb-auto px-auto cursor-pointer text-sm 2xl:text-lg cursor-default">
                {props.isSelected ? "Selected" : "Select"}
              </p>
            </div>
          </div>
    </div>
  );
};
