import { useState } from "react";

const Paginator = (props) => {
  const [timeoutObj, setTimeoutObj] = useState(null);
  const handleClick = (direction, currentPage) => {
    const nextPage = direction === "prev" ? currentPage - 1 : currentPage + 1;
    if (props.handleClick) {
      props.handleClick(direction, currentPage, nextPage);
    }
    if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    if (props.timeout && props.debounceCallback) {
      const obj = setTimeout(() => {
        props.debounceCallback(nextPage);
      }, props.timeout);
      setTimeoutObj(obj);
    }
  };
  const getTotalPages = () => {
    if (props?.totalPages) {
      return props?.totalPages;
    }
    if (props?.totalRecords) { 
      return props?.totalRecords / props?.limit;
    }
    return 1;
  };

  return (
    <div className="cm-paginator-wrapper">
      <div className="page-status-wrap bg-white font-Poppins h-12 hover:opacity-80 md:text-bas rounded-3xl w-48">
        <span>
          {props.page}
          {" of "}
          {getTotalPages()}
          {" pages "}
        </span>
      </div>
      <div className="page-prev-wrap">
        <button
          className="bg bg-white font-Poppins h-12 hover:opacity-80 md:text-bas py-3 rounded-3xl w-12 page-prev"
          onClick={() => handleClick("prev", props.page)}
          disabled={props.page - 1 <= 0}
        >
          {props.prevLabel ? props.prevLabel : "<"}
        </button>
      </div>
      <div className="page-next-wrap">
        <button
          className="bg bg-white font-Poppins h-12 hover:opacity-80 md:text-bas py-3 rounded-3xl w-12 page-next"
          onClick={() => handleClick("next", props.page)}
          disabled={props.page >= getTotalPages()}
        >
          {props.nextLabel ? props.nextLabel : ">"}
        </button>
      </div>
    </div>
  );
};

export default Paginator;
