import React, { useState, useContext } from "react";
import logo from "../assets/logo.svg";
import { NavLink, useHistory } from "react-router-dom";
// import '../css/styles.css'
import gold_snow from "../assets/landing/snow_gold.svg";
import black from "../assets/landing/black.svg";
import days from "../assets/menu/days&dates.svg";
import faqs from "../assets/menu/faqs.svg";
import shariah from "../assets/menu/shariah.svg";
import leadership from "../assets/menu/leadership.svg";
import charity from "../assets/menu/charity_partners.svg";
import learning from "../assets/menu/learning_center.svg";
import Hamburger from 'hamburger-react';
import { Context } from "../utils/store";

export default function Header() {
  let history = useHistory()
  const { state, dispatch } = useContext(Context);
  const [show, setShow] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(true);
  const [subAboutMenu, setSubAboutMenu] = useState(false);
  const [subLearnMenu, setSubLearnMenu] = useState(false);


  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  React.useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
  
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


  const onPress = () => {
    setShow(!show);
  };
  const wrapperRef = React.useRef(null);
  const [color, setColor] = React.useState('transparent')
  React.useEffect(() => {
   if(scrollPosition >= 50)
   {
     setColor('#fff')
   }
   else if (scrollPosition < 50)
   {
     setColor('transparent')
   }
  })
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setShowLearn(false);
          setShowAbout(false);
        }
      } 

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleBetaVisibleClose = () => {
    dispatch({
      type: 'setViewBetaMsg',
      viewBetaMsg: false
    });
  }

  return (     
    <>
    {history.location.pathname.indexOf('/donation-automation')==0 || history.location.pathname.indexOf('/thankyou')==0 ?
    null : 
    <>
    <div className="alert-beta-version"><span>⚡</span> We've launched in beta.<span>🎉</span> If you come across any technical issues, please let us know at salaam@charitymoments.com<button className="beta-cross-btn" onClick={handleBetaVisibleClose}></button></div>
    <div
      style={{maxWidth:'100%'}}
      ref={wrapperRef}
      className={`sticky top-0 header-main ${color == 'transparent' ? 'bg-transparent':'bg-white shadow-round'} overscroll-x-none z-50 w-screen mx-auto px-2 lg:px-4 xl:px-28 2xl:px-36 `}
    >
      <div className="mx-auto px-4 2xl:px-0 sm:px-6 z-10">
        <div className="flex justify-between items-center border-gray-100 py-2 md:py-6 md:justify-start md:space-x-10 ">
          <div className="hidden lg:flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img className="h-12 w-auto sm:h-10 2xl:h-14" src={logo} alt="" />
            </a>
          </div>

          <nav className="hidden lg:flex space-x-10 ">
            <button
              onClick={() => {
                setShowAbout(!showAbout);
                setShowLearn(false);
              }}
              type="button"
              className="group hover:opacity-75  focus:outline-none text-black inline-flex items-center text-base font-medium hover:text-gray-900 "
            >
              <span className="text-base 2xl:text-base font-PoppinsMedium">About</span>

              <svg
                className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <a href="../#howitworks" className="text-base 2xl:text-base font-PoppinsMedium group hover:opacity-75  focus:outline-none text-black inline-flex items-center text-base font-medium hover:text-gray-900 " >
                  How it works
                </a>
            {/* <button
              onClick={() => setShow(!show)}
              type="button"
              className="group hover:opacity-75  focus:outline-none text-black inline-flex items-center text-base font-medium hover:text-gray-900 "
            >
              <span href="#howitworks" className="text-base 2xl:text-base font-PoppinsMedium">How It Works</span>
            </button> */}
            <button
              onClick={() => {
                setShowLearn(!showLearn);
                setShowAbout(false);
              }}
              type="button"
              className="group hover:opacity-75 focus:outline-none text-black inline-flex items-center text-base font-medium hover:text-gray-900 "
            >
              <span className="text-base 2xl:text-base font-PoppinsMedium">Learn</span>

              <svg
                className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="relative -left-full">
              {showLearn == true ? (
                  <div  className="shadow-lg absolute z-10  mt-14 transform  w-screen lg:ml-44  lg:-translate-x-1/2 md:-translate-x-1/2 md:ml-60">
                  <img
                    src={gold_snow}
                    className="absolute left-5 w-20 opacity-25"
                  />
                  <img
                    src={black}
                    className="absolute left-36 w-4 opacity-25"
                  />
                  <img
                    src={black}
                    className="absolute right-36 w-3 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute left-72 top-5 w-6 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute right-64 w-20 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute right-5 w-4 opacity-75"
                  />
                  <div
                    style={color == 'transparent' ? {backgroundColor: "#f6f6f6"} : {backgroundColor:'#fff'} }
                    className="w-full ring-1 ring-transparent ring-opacity-5 overflow-hidden "
                  >
                    <div className="relative grid gap-6  px-5 py-6 grid grid-cols-4 ml-auto mr-auto w-2/4 sm:gap-8 sm:p-8">
                      <a
                        href="/days-and-dates"
                        className="-m-3 p-3 flex items-start rounded-lg  flex-col "
                      >
                        <img src={days} className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl" />
                        <div className="ml-4 justify-center ml-auto mr-auto">
                          <p className="text-sm 2xl:text-xl text-center font-medium font-PoppinsMedium 2xl:font-PoppinsMedium text-black">
                            Days & Dates
                          </p>
                        </div>
                      </a>

                      <a
                        href="/frequently-asked-questions"
                        className="-m-3 p-3 flex flex-col items-start rounded-lg"
                      >
                        <img src={faqs} className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl" />
                        <div className="ml-4 justify-center ml-auto mr-auto">
                          <p className="text-sm 2xl:text-xl font-medium font-PoppinsMedium 2xl:font-PoppinsMedium text-black">
                            FAQs
                          </p>
                        </div>
                      </a>

                      <a
                        href="/charity-partners"
                        className="-m-3 p-3 flex flex-col items-start rounded-lg"
                      >
                        <img src={charity} className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl" />
                        <div className="ml-4 justify-center ml-auto mr-auto">
                          <p className="text-sm text-center 2xl:text-xl font-medium font-PoppinsMedium 2xl:font-PoppinsMedium text-black">
                            Charity Partners
                          </p>
                        </div>
                      </a>

                      <a
                        href="/learning-center"
                        className="-m-3 p-3 flex flex-col items-start rounded-lg"
                      >
                        <img src={learning} className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl" />
                        <div className="ml-4 justify-center ml-auto mr-auto">
                          <p className="text-sm text-center 2xl:text-xl font-medium font-PoppinsMedium 2xl:font-PoppinsMedium text-black">
                            Learning Center
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              {showAbout == true ? (
                <div className="absolute z-10  mt-14 transform  w-screen lg:ml-44 sm:px-0   lg:-translate-x-1/2 md:-translate-x-1/2 md:ml-60 ">
                  <img
                    src={gold_snow}
                    className="absolute left-5 w-20 opacity-25"
                  />
                  <img
                    src={black}
                    className="absolute left-36 w-4 opacity-25"
                  />
                  <img
                    src={black}
                    className="absolute right-36 w-3 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute left-72 top-5 w-6 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute right-64 w-20 opacity-25"
                  />
                  <img
                    src={gold_snow}
                    className="absolute right-5 w-4 opacity-75"
                  />
                  <div
                    style={color == 'transparent' ? {backgroundColor: "#f6f6f6"} : {backgroundColor:'#fff'} }
                    className="w-screen shadow-lg w-screen overflow-x-hidden  "
                  >
                    <div className="relative grid gap-6    px-5 py-6 grid grid-cols-2 ml-auto mr-auto w-1/4 md:w-1/2 2xl:w-1/3 sm:gap-8 sm:p-8 ">
                      <a
                        href="/leadership"
                        className="-m-3 p-3 flex  ml-auto mr-2 2xl:mr-auto  rounded-lg flex-col  "
                      >
                        <img
                          src={leadership}
                          className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl"
                        />
                        <div className="justify-center mx-auto">
                          <p className="text-sm 2xl:text-xl font-medium text-black font-PoppinsMedium 2xl:font-PoppinsMedium">
                            Leadership
                          </p>
                        </div>
                      </a>
                      <a
                        href="/shariah-compliance"
                        className="-m-3 p-3 flex  mr-auto ml-2  2xl:ml-auto rounded-lg flex-col  "
                      >
                        <img
                          src={shariah}
                          className="ml-auto mr-auto mb-2 hover:shadow-full rounded-xl"
                        />
                        <div className="justify-center mx-auto">
                          <p className="text-sm 2xl:text-xl font-medium text-center text-black font-PoppinsMedium 2xl:font-PoppinsMedium">
                          Shariah Compliance
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </nav>


        { /* Mobile MENU: */ }

          <nav className="lg:hidden flex-wrap w-full bg-teal-500 py-2 z-50">
            <div className="grid grid-cols-2 items-center	">
              <a className="justify-self-start" href="/">
                <img className="h-10 md:h-12" src={logo} alt="Logo" />
              </a>
                <div className="justify-self-end	">
                  <Hamburger distance="lg" hideOutline={true} onToggle={()=>setIsMobileMenuHidden(!isMobileMenuHidden)} size={15} rounded color="#d0a342" />
                </div>
            </div>

            <div className={isMobileMenuHidden ? "hidden" : "w-full block"}>
              <div className="text-sm inline-block w-full text-center">
                <a onClick={()=>setSubAboutMenu(!subAboutMenu)} href="#responsive-header" className="inline-block mx-auto mt-4 ">
                  About
                    <svg
                    className="inline-block ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true" >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd" />
                    </svg>
                </a>
                {subAboutMenu ? 
                  <div className="mx-auto">
                  <a href="/leadership" className="block mt-4 text-gray-500">Leadership</a>
                  <a href="/shariah-compliance" className="block mt-4 text-gray-500">Shariah Compliance</a>
                  </div> : null }
                <a href="../#howitworks" className="block mt-4" >
                  How it works
                </a>
                <a onClick={()=>{setSubLearnMenu(!subLearnMenu)}} href="#responsive-header" className="block mt-4">
                  Learn
                  <svg
                  className="inline-block ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true" >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd" />
                  </svg>
                </a>
                {subLearnMenu ? 
                  <div className="mx-auto">
                  <a href="/days-and-dates" className="block mt-4 text-gray-600">Days & Dates</a>
                  <a href="/frequently-asked-questions" className="block mt-4 text-gray-600">FAQs</a>
                  <a href="/charity-partners" className="block mt-4 text-gray-600">Charity Partners</a>
                  <a href="/learning-center" className="block mt-4 text-gray-600">Learning Center</a>
                  </div> : null }
              </div>
              
              <div className="text-center">
                <a
                  href={state.isLoggedIn ? "/profile" : "/login"}
                  className="inline-block whitespace-nowrap text-base font-PoppinsMedium font-medium text-black hover:text-gray-500 mt-5 border border-black rounded pt-2 pb-2 px-5"
                >
                  {state.isLoggedIn ? 'My account' : 'Log In'}
                </a><br/>
                <a
                  href="/donation-automation"
                  style={{ backgroundColor: "#d0a342" }}
                  className="inline-block whitespace-nowrap font-PoppinsMedium items-center justify-center px-4 py-2 mt-5 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:opacity-90"
                >
                  Get Started
                </a>
              </div>
            </div>
          </nav>

          <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
            <a
              href={state.isLoggedIn ? "/profile" : "/login"}
              className="whitespace-nowrap text-base font-PoppinsMedium font-medium text-black hover:text-gray-500 border border-black rounded py-2 px-6"
            >
               {state.isLoggedIn ? 'My account' : 'Log In'}
            </a>
            <a
              href="/donation-automation"
              style={{ backgroundColor: "#d0a342" }}
              className="ml-8 whitespace-nowrap inline-flex font-PoppinsMedium items-center justify-center px-8 py-2 border border-transparent rounded text-base font-medium text-white hover:opacity-90 shadow-camel"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>

      <div className="absolute top-0 inset-x-0 p-2 transition transform z-10  origin-top-right md:hidden">
        <div className="hidden md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"></div>
      </div>
    </div>
    </>
  }
    </>
  );
}
