import React, { useContext, useEffect, useState } from 'react';
import Bell from '../assets/icons/bell_2x.png';
import logo from '../assets/logo.svg';
import { Context } from '../utils/store';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import axios from '../utils/axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

export default function StickyHeader() {
  const { state, dispatch } = useContext(Context);

  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const history = useHistory();

  const dropdownHandler = () => {
    setIsDropdownShown(!isDropdownShown);
  };

  const logoutHandler = async () => {
    await axios({
      method: 'post',
      url: '/logout',
      data: { email: state.userInfo.email },
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        removeCookie('id');
        removeCookie('cookie');
        removeCookie('tokenId');
        removeCookie('refreshToken');
        dispatch({
          type: 'userInfo',
          userInfo: null,
        });
        window.location.href = '/login';
      })
      .catch((err) => console.log(err));
  };
  
  return (
    <>
      <div className={`md:px-10 w-full mb-3 flex flex-nowrap justify-between fixed-header`}>
        <a className="inline-block" href="/">
          <img
            className="md:h-12 h-10 md:ml-8 ml-2 lg:ml-12  mt-4"
            src={logo}
            alt="Logo"
          />
        </a>
        {state.isLoggedIn ? (
          <div className="inline-block float-right mt-3 md:mt-0 md:mr-10 lg:mr-12 mr-2 md:mt-auto lg:mt-auto 2xl:mt-auto">
            {/* <div className="inline-block justify-self-end pt-2 pr-4"><img className="inline-block" src={Bell} alt="Bell" /></div> */}
            <div
              onClick={() => dropdownHandler()}
              className="flex flex-nowrap mt-2 cursor-pointer relative inline-block justify-self-end md:block bg-grey rounded-full pr-4 flex flex-nowrap md:mb-2 overflow-hidden"
            >
              <a href="/profile">
                <img
                  className="h-8 md:h-10 inline-block rounded-full mr-2"
                  src={state.userInfo?.userImage}
                />
                <span className="md:text-sm text-xs font-bold mt-auto mb-auto ">
                  {state?.userInfo?.firstName}
                </span>
                {/* { isDropdownShown ? <FiChevronUp className="ml-2 inline-block"/> : <FiChevronDown className="ml-2 inline-block"/> } */}
              </a>
              {isDropdownShown ? (
                <div className="absolute -bottom-32 transform -translate-y-3 rounded-md right-0 w-full h-auto bg-grey z-30">
                  <span
                    onClick={() => (window.location.href = '/profile')}
                    className="block p-4 hover:bg-darkGrey"
                  >
                    Profile
                  </span>
                  <span
                    onClick={logoutHandler}
                    className="block p-4 hover:bg-darkGrey"
                  >
                    Log out
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
