import { useContext } from "react";
import { Context } from "../utils/store";
import "../css/styles.css";
interface LoaderProps {
  showLoader: boolean;
  showShadow: boolean;
}

const ScheduleLoader = (props: LoaderProps) => {
  return (
    <>
      {props.showLoader ? (
        <div
          className={`${
            props.showShadow ? "shadow-round" : null
          }  rounded-xl p-8 max-w-sm w-full h-70 mx-auto`}
        >
          <div className="animate-pulse flex space-x-4 content-center">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-camel opacity-40 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-camel opacity-40 rounded"></div>
                <div className="h-4 bg-camel opacity-40 rounded w-5/6"></div>
              </div>
              <div className="h-4 bg-camel opacity-40 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-camel opacity-40 rounded"></div>
                <div className="h-4 bg-camel opacity-40 rounded w-5/6"></div>
              </div>
              <div className="h-4 bg-camel opacity-40 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-camel opacity-40 rounded"></div>
                <div className="h-4 bg-camel opacity-40 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ScheduleLoader;
